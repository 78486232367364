<header *ngIf="providerView">
  <li><img class="header--pd-logo" src="../../../../assets/images/parawell-logo.png" /></li>
  <li class="header--app-name"><h2>Patient Dashboard</h2></li>
</header>
<section class="error">
  <div style="text-align: center;">
    <img src="../../../../assets/images/information.svg" />
    <span>
      You have been logged out due to inactivity. <br />
      Please close this window and log in again.
    </span>
    <br />
    <button class="primary-btn" (click)="closewindow()">Close Window</button>
  </div>
</section>

<section class="mobile error">
  <div style="text-align: center;">
    <img src="../../../../assets/images/information.svg" />
    <span>
      You have been logged out due to inactivity. <br />
      Please close the app and log in again.
    </span>
    <br />
  </div>
</section>
