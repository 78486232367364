import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HiePatient } from '../../../models/hie-patient';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';

@Component({
  selector: 'lib-hie-patientmatch',
  templateUrl: './hie-patientmatch.component.html',
  styleUrls: ['./hie-patientmatch.component.scss']
})
export class HiePatientmatchComponent implements OnInit {

  constructor(
    private localService: LocalDataService,
    private dialogRef: MatDialogRef<HiePatientmatchComponent>, 
    private pdService: PatientDashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource: MatTableDataSource<HiePatient> | null = null;
  patientName: string | null = null;
  tableColumns: string[] = ['toInclude', 'name', 'gender', 'dob', 'address'];
  patientsToInclude: string[] = [];
  loadingComplete: boolean = true;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<HiePatient>(this.data.cwPatientResults);
    this.dataSource.paginator = this.paginator;

    this.localService.dashboardSummary.subscribe(response => {
      this.patientName = `${response.patientFirst} ${response.patientLast}`;
    });
  }

  hgInclude(): void {
    this.loadingComplete = false;

    var enrollment = {
      HgPatientId: this.data.hgPatientId,
      CwPatientIds: this.patientsToInclude
    }

    this.pdService.linkHiePatients(enrollment).subscribe(response => {
      this.pdService.getDocuments(enrollment.HgPatientId).subscribe((response: any) => {
        this.loadingComplete = true;
        location.reload();
      });
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectedRow(row: any): void {

  }

  isSelected(row: any): void {

  }

  handleChange(row: any): void {
    if (row.IsChecked) {
      this.patientsToInclude.push(row.id);
    } else {
      var indexToRemove = this.patientsToInclude.findIndex(x => x == row.id);
      this.patientsToInclude.splice(indexToRemove);
    }
  }
}
