import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService } from '../../../services/loading.service';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { StringManipService } from '../../../services/string-manip.service';

@Component({
  selector: 'lib-hg-immunizations',
  templateUrl: './hg-immunizations.component.html',
  styleUrls: ['./hg-immunizations.component.scss']
})
export class HgImmunizationsComponent implements OnInit {
  loading$ = this.loader.loading$;

  constructor(
    private pdService: PatientDashboardService,
    private loader: LoadingService,
    private stringUtil: StringManipService
  ) { }

  @Input('minified') minified: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'date':
            return this.stringUtil.trimAndLower(item.date?.toString());
          case 'name':
            return this.stringUtil.trimAndLower(item.name?.toString());
          case 'doctor':
            return this.stringUtil.trimAndLower(item.doctor?.toString());
          case 'dose':
            return this.stringUtil.trimAndLower(item.dose?.toString());
          default:
            return '';
        }
      };
      this.dataSource.sort = sort;
    }

    this.dataSource.sort = sort;
  }

  tableColumns: string[] = [];
  dataSource: MatTableDataSource<Immunization> = new MatTableDataSource<Immunization>([]);

  ngOnInit(): void {
    if (this.minified)
      this.tableColumns = ['name', 'date'];
    else
      this.tableColumns = ['name', 'date', 'dose', 'doctor'];

    this.pdService.getHieImmunizations().subscribe((data: any) => {
      if (data !== null) {
        this.dataSource = new MatTableDataSource<Immunization>(data);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  applyFilter(event: Event) {
    this.dataSource.filter = (event as unknown as String).trim().toLowerCase();
  }
}

export interface Immunization {
  name: string,
  date: string,
  doctor: string,
  dose: string,
  doseUnit: string
}
