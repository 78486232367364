import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService } from '../../services/loading.service';
import { LocalDataService } from '../../services/local-data.service';
import { PatientDashboardService } from '../../services/patient-dashboard.service';
import { DiagnosesDialogComponent } from '../dialogs/diagnoses-dialog/diagnoses-dialog.component';

@Component({
  selector: 'app-encounters',
  templateUrl: './encounters.component.html',
  styleUrls: ['./encounters.component.scss'],
  host: {
    class: 'flex-direction--column'
  }
})
export class EncountersComponent implements OnInit {
  loading$ = this.loader.loading$;

  constructor(
    private pdService: PatientDashboardService,
    private dialog: MatDialog,
    private localService: LocalDataService,
    private loader: LoadingService
  ) { }

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  tableColumns: string[] = ['dateTime', 'visitType', 'diagnoses', 'organization.name'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  rawData: any;
  currentPage: number = 0;
  itemsPerPage: number = 10;

  ngOnInit(): void {
    this.pdService.getEncounters().subscribe((data: any) => {
      this.rawData = data;

      if (data !== null) {
        this.dataSource = new MatTableDataSource<any>(data);

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'organization.name': return item.organization.name;
            case 'visitType': return item.visitType;
            default: return item[property];
          }
        }
      }
    });
  }
  
  applyFilter(event: Event) {
    this.dataSource.filter = (event as unknown as String).trim().toLowerCase();
  }

  openDiagnosesModal(i: any) {
    const dialogConfig = new MatDialogConfig();
    const trueIndex = i + this.currentPage * this.itemsPerPage;
    dialogConfig.data = this.rawData[trueIndex].diagnoses;

    this.dialog.open(DiagnosesDialogComponent, dialogConfig);
  }

  trackIndex(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
  }
}

export interface Encounter {
  dateTime: string;
  visitType: string;
  provider: Provider;
  organization: Organization;
}

export interface Provider {
  departmentName: string;
}

export interface Organization {
  name: string;
}