<div class="notification-container">
    <div class="flex-container mt-10">
        <div style="width: 20%;">
            <div>
                <label for="name" class="input-label">Medication name</label>
            </div>
            <div class="pr-10">
                <input #medicationNameField type="text" name="name" class="input-field"
                    [(ngModel)]="medication.medicationName" maxlength="500" autofocus />
            </div>
        </div>
        <div style="width: 10%;">
            <div>
                <label for="start_date" class="input-label">Start Date</label>
            </div>
            <div class="pr-10">
                <input type="date" name="start_date" class="input-field" [(ngModel)]="medication.startDate"
                    value="{{ medication.startDate  | date:'yyyy-MM-dd' }}" />
            </div>
        </div>
        <div style="width: 10%;">
            <div>
                <label for="end_date" class="input-label">End Date</label>
            </div>
            <div class="pr-10">
                <input type="date" name="end_date" class="input-field" [(ngModel)]="medication.endDate"
                    value="{{ medication.endDate  | date:'yyyy-MM-dd' }}" />
            </div>
        </div>
        <div style="width: 10%;">
            <div>
                <label for="refilles" class="input-label">Total Refills</label>
            </div>
            <div class="pr-10">
                <input type="number" name="refilles" class="input-field" [(ngModel)]="medication.prescribedRefills" />
            </div>
        </div>
        <div style="width: 50%;">
            <div>
                <label for="indication" class="input-label">Indication</label>
            </div>
            <div class="pr-10">
                <input type="text" name="indication" class="input-field" [(ngModel)]="medication.indications"
                    maxlength="1000" placeholder="Maximum 1000 characters" />
            </div>
        </div>
    </div>
    <div class="flex-container mt-10">
        <div style="width: 50%;">
            <div>
                <label for="direction" class="input-label">Direction</label>
            </div>
            <div class="pr-20">
                <textarea name="direction" autosize [minRows]="1" [(ngModel)]="medication.directions" maxlength="1000"
                    placeholder="Maximum 1000 characters"></textarea>
            </div>
        </div>
        <div style="width: 50%;">
            <div>
                <label for="notes" class="input-label">Notes</label>
            </div>
            <div class="pr-20">
                <textarea name="notes" autosize [minRows]="1" [(ngModel)]="medication.notes" maxlength="1000"
                    placeholder="Maximum 1000 characters"></textarea>
            </div>
        </div>
    </div>
    <div class="mt-10" style="text-align: end;">
        <span><button class="btn-sm  btn-cancel btn-m" type="submit" (click)="clearForm()">Cancel</button></span>
        <span><button class="btn-sm  btn-save" style="margin-right: 5px;" type="submit" (click)="addNewMedication()"
                [disabled]="!medication.medicationName">{{buttonText}}</button></span>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div>
        <div class="mt-10 flex-container-rev" style="margin-right: 7px;">
            <a (click)="openMedicineListPopup()" class="pointer" *ngIf="isShowMedHistoryBtn">
                <div class="flex-container pointer" style="margin-right: 1rem;">
                    <div style="padding-top: 14px;">
                        <img src="../../../../../assets/images/history.svg" alt="logo-dark" style="height: 1.5rem;">
                    </div>
                    <div style="padding-top: 14px;">
                        <span style="color: #E36C27;font-weight: 500;">
                            Add Med From History
                        </span>
                    </div>
                </div>
            </a>
            <a (click)="addNewmedication()">
                <div class="flex-container pointer">
                    <div>
                        <img src="../../../../../assets/images/cross.svg" alt="logo-dark" style="height: 3rem;">
                    </div>
                    <div style="padding-top: 14px;
                    margin-left: -7px;">
                        <span style="color: #E36C27;font-weight: 500;">
                            Add New Med
                        </span>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="table-container">
        <table>
            <tr class="t-head">
                <th style="width: 14%;">
                    <span class="t-header">Medication Name</span>
                </th>
                <th style="width: 8%;">
                    <span class="t-header">Start&nbsp;Date</span><br>
                    <span class="t-header">End&nbsp;Date</span>
                </th>
                <th style="width: 8%;">
                    <span class="t-header">Prescribed Refills</span>
                </th>
                <th style="width: 20%;">
                    <span class="t-header">Directions</span>
                </th>
                <th style="width: 20%;">
                    <span class="t-header">Indication</span>
                </th>
                <th style="width: 20%;">
                    <span class="t-header">Notes</span>
                </th>
                <th style="width: 10%;">
                    <span class="t-header"></span>
                </th>
            </tr>
            <tr *ngFor="let med of medicationList;let index = index">
                <td><span style="word-wrap: break-word;">{{med.medicationName}}</span></td>
                <td>
                    <div>
                        <div>
                            {{med.startDate | date:'yyyy-MM-dd'}}
                        </div>
                        <div>
                            {{med.endDate | date:'yyyy-MM-dd'}}
                        </div>
                    </div>
                </td>
                <td>{{med.prescribedRefills}}</td>
                <td><span style="word-wrap: break-word !important;">{{med.directions}}</span></td>
                <td><span style="word-wrap: break-word !important;">{{med.indications}}</span></td>
                <td><span style="word-wrap: break-word !important;">{{med.notes}}</span></td>
                <td>
                    <div>
                        <span>
                            <span>
                                <a (click)="editMedication(med,index)" class="pointer">
                                    <img src="../../../../../assets/images/edit.svg" alt="logo-dark"
                                        style="height: 1.75rem;">
                                </a>
                            </span>
                            <span style="margin-left: 1rem;">
                                <a (click)="deleteMedication(index)" class="pointer" *ngIf="!med.hideDelete">
                                    <img src="../../../../../assets/images/delete.svg" alt="logo-dark"
                                        style="height: 1.75rem;">
                                </a>
                            </span>
                        </span>
                    </div>
                </td>
            </tr>
            <tr *ngIf="medicationList.length == 0 ">
                <td colspan="7" style="width: 100%;text-align: center;padding-top: 1rem;
                padding-bottom: 1rem;">
                    <span>No Medications</span>
                </td>
            </tr>
        </table>
    </div>
</div>