import { animate, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { DashboardSummary } from '../../../models/dashboard-summary';
import { HttpCancelService } from '../../../services/http-cancel.service';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { SharedService } from '../../../services/sharedservice.service';
import { Constants } from '../../constants';

@Component({
  selector: 'lib-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss'],
  animations: [
    trigger('slideInOutHorizontal', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NavLinksComponent {

  fileCount: any;
  @Output() fileCountEvent = new EventEmitter();
  isEncounterNotes = false;

  constructor(
    private pdService: PatientDashboardService,
    private localService: LocalDataService,
    public sharedService: SharedService,
    public router: Router,
    private httpCancelService: HttpCancelService
  ) {
    
  }

  providerView: boolean = false;
  token: string = '';
  hapiConnectionCount: number = 0;
  hieConnectionCount: number = 0;
  hasActiveHeadsUp: boolean = false;
  hasHeadsUpEnabled: boolean = false;
  hasHealthGorillaEnabled: boolean = false;
  hasHealthGorillaConnection: boolean = false;
  isHealthGorillaSponsor: boolean = false;
  showSubmenu: boolean = true;
  showHieSubmenu: boolean = true;
  disableHeadsUp: boolean = false;
  showConnectedDevices: boolean = false;
  encounterNoteCount = 0;
  isShowEncounterNote = false;
  hasHumanApiEnabled: boolean = false;
  hasEncounterNotesEnabled: boolean = false;
  practiceId: number = 0;
  hasHealthRecordQueryExpired: boolean = true;

  ngOnInit(): void {
    this.localService.dashboardSummary.subscribe((response: DashboardSummary) => {
      this.providerView = response.providerView;
      this.hasActiveHeadsUp = response.hasActiveHeadsUp;
      this.hasHeadsUpEnabled = response.hasHeadsUpEnabled;
      this.hasHealthGorillaEnabled = response.hasHealthGorillaEnabled;
      this.isHealthGorillaSponsor = response.isHealthGorillaSponsor;
      this.hasHealthGorillaConnection = response.hasHealthGorillaConnection;
      this.hapiConnectionCount = response.connectionCount;
      this.hasHumanApiEnabled = response.hasHumanApiEnabled;
      this.token = response.hapiDataToken;
      this.fileCount = response.fileCount;
      this.hasEncounterNotesEnabled = response.hasEncounterNotesEnabled;
      this.hasHealthRecordQueryExpired = response.hasHealthRecordQueryExpired;

      if (this.hapiConnectionCount < 1) this.showSubmenu = false;
      if (!this.hasHealthGorillaConnection || response.hasHealthRecordQueryExpired) this.showHieSubmenu = false;
    });

    if (this.providerView && this.hasEncounterNotesEnabled) {
      this.isShowEncounterNote = this.providerView;
      this.encounterNotesCount();
    }

    this.sharedService.fileCountChange.subscribe((event) => {
      this.countFiles();
    });

    this.sharedService.encounterNoteCountChange.subscribe((event) => {
      this.encounterNotesCount();
    });

    // if the user already has another patient open, lets not show HeadsUp
    // due to an issue where HeadsUp can only display one user at a time
    this.pdService.isHeadsUpAlreadyOpened().subscribe((opened) => {
      this.disableHeadsUp = !!opened;
    });

    this.showConnectedDevices = (this.providerView && this.hasHeadsUpEnabled)
      || (!this.providerView && this.hasActiveHeadsUp);
  }

  encounterNotesCount() {
    this.pdService.encounterNotesCount().subscribe(
      (res: any) => {
        this.encounterNoteCount = res.data.count;
      })
  }

  countFiles() {
    this.practiceId = Number(sessionStorage.getItem(Constants.SESSION_PRACTICE_ID));

    this.pdService.fileCount(this.providerView, this.practiceId).subscribe(
      (data: any) => {
        this.localService.dashboardSummary.subscribe((response: DashboardSummary) => {
          this.fileCount = data.data.fileCount;
        });
        this.fileCountEvent.emit();
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );
  }

  launchHapiConnect() {
    var hapiBtn = document.getElementById('hapi-connect-btn');
    if (hapiBtn == null) return console.error('Cannot locate Hapi Button');
    hapiBtn.click();
  }

  toggleSubmenu() {
    if (this.hapiConnectionCount < 1) return;
    this.showSubmenu = !this.showSubmenu;
  }

  toggleHieSubmenu() {
    if (!this.hasHealthGorillaConnection || this.hasHealthRecordQueryExpired) return;
    this.showHieSubmenu = !this.showHieSubmenu;
  }

  sideNavClick(menu: string) {
    this.sharedService.selectedSubMenu = menu;
    this.localService.menuClicked(menu);
  }

}
