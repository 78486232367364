import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from 'projects/components/src/lib/services/loading.service';
import { PatientDashboardService } from 'projects/components/src/lib/services/patient-dashboard.service';

@Component({
  selector: 'lib-labs',
  templateUrl: './labs.component.html',
  styleUrls: ['./labs.component.scss']
})
export class LabsComponent implements OnInit, AfterViewInit {

  constructor(
    public loader: LoadingService,
    private pdService: PatientDashboardService
  ) { }

  loading$ = this.loader.loading$;
  @ViewChild('hgSlideOut', { static: false }) hgSlideOut: any;
  labsList: any[] = [];
  labResults: any[] = [];

  ngAfterViewInit(): void {
    this.getLabsList();
  }

  ngOnInit(): void {
      
  }

  getLabsList() {
    this.pdService.getHieLabListing().subscribe((response: any) => {
      this.labsList = response;

      // get the first lab in the list to load in the background
      this.pdService.getHieLabSummary(response[0].observationIds).subscribe((response: any) => { 
        this.loadLabContent(response);
      });
    });
  }

  loadLabContent(data: any) {
    this.hgSlideOut.onClickFlyout();
    this.labResults = data;
  }
}
