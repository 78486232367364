<ul class="nav-links-container" [@slideInOutHorizontal]>

  <!-- PATIENT PROFILE -->

  <li [routerLink]="[{ outlets: { content: ['patient-profile']}}]" routerLinkActive="active">
    <span>Patient Profile</span>
  </li>

  <!-- BEGIN HEALTH RECORDS -->

  <!-- <ng-container *ngIf="hasHumanApiEnabled">
    <li (click)="toggleSubmenu()" [class.active]="router.isActive('/dashboard/(content:medical-records)', false)"
      [routerLink]="hapiConnectionCount < 1 ? [{ outlets: { content: ['medical-records']}}] : []"
      [class.medrecords-active]="router.isActive('/dashboard/(content:encounters)',false)
    || router.isActive('/dashboard/(content:immunizations)',false)
    || router.isActive('/dashboard/(content:medications)',false)
    || router.isActive('/dashboard/(content:diagnoses)',false)
    || router.isActive('/dashboard/(content:allergies)',false)">
      <span>Portal Records</span>
      <span class="menu-state" *ngIf="hapiConnectionCount > 0" [class.menu-state--minified]="!showSubmenu">
      </span>
      <span class="menu-notifier" [class.green]="hapiConnectionCount != null && hapiConnectionCount > 0"
        [class.gray]="hapiConnectionCount == null || hapiConnectionCount < 1">
        {{hapiConnectionCount == null ? 0 : hapiConnectionCount}}
      </span>
    </li>
    <ul class="submenu" *ngIf="showSubmenu">
      <li [routerLink]="[{ outlets: { content: ['encounters']}}]" routerLinkActive="active">Encounters</li>
      <li [routerLink]="[{ outlets: { content: ['immunizations']}}]" routerLinkActive="active">Immunizations</li>
      <li [routerLink]="[{ outlets: { content: ['medications']}}]" routerLinkActive="active">Medications</li>
      <li [routerLink]="[{ outlets: { content: ['diagnoses']}}]" routerLinkActive="active">Diagnoses</li>
      <li [routerLink]="[{ outlets: { content: ['allergies']}}]" routerLinkActive="active">Allergies</li>
      <div class="connection-info">
        <span>{{hapiConnectionCount == null ? 0 : hapiConnectionCount}}
          Health Record Sources Connected</span><br />
        <button type="button" *ngIf="!providerView" class="primary-btn" (click)="launchHapiConnect()">
          Connect Additional<br /> Health Records
        </button>
      </div>
    </ul>
  </ng-container> -->

  <!-- END HEALTH RECORDS -->
  <!-- BEGIN HIE RECORDS -->
  <ng-container *ngIf="providerView && hasHealthGorillaEnabled">
    <li (click)="toggleHieSubmenu()" [class.active]="router.isActive('/dashboard/(content:hie-records)', false)"
      [routerLink]="!hasHealthGorillaConnection || hasHealthRecordQueryExpired 
                      ? [{ outlets: { content: ['hie-records']}}] : []"
      [class.medrecords-active]="router.isActive('/dashboard/(content:hg-encounters)',false)
    || router.isActive('/dashboard/(content:hg-immunizations)',false)
    || router.isActive('/dashboard/(content:hg-medications)',false)
    || router.isActive('/dashboard/(content:hg-diagnoses)',false)
    || router.isActive('/dashboard/(content:hg-allergies)',false)
    || router.isActive('/dashboard/(content:hg-documents)',false)
    || router.isActive('/dashboard/(content:hg-full-ccda)',false)">
      <span>Health Records</span>
      <span class="menu-state" *ngIf="hasHealthGorillaConnection && !hasHealthRecordQueryExpired" [class.menu-state--minified]="!showHieSubmenu">
      </span>
      <span class="menu-notifier" [class.green]="hasHealthGorillaConnection" [class.gray]="!hasHealthGorillaConnection">
        &nbsp;
      </span>
    </li>
    <ul class="submenu" *ngIf="showHieSubmenu && !sharedService.isEncounterNoteAdd">
      <li [routerLink]="[{ outlets: { content: ['hg-encounters']}}]" routerLinkActive="active">Encounters</li>
      <li [routerLink]="[{ outlets: { content: ['hg-immunizations']}}]" routerLinkActive="active">Immunizations</li>
      <li [routerLink]="[{ outlets: { content: ['hg-medications']}}]" routerLinkActive="active">Medications</li>
      <li [routerLink]="[{ outlets: { content: ['hg-diagnoses']}}]" routerLinkActive="active">Diagnoses</li>
      <li [routerLink]="[{ outlets: { content: ['hg-allergies']}}]" routerLinkActive="active">Allergies</li>
      <li [routerLink]="[{ outlets: { content: ['hg-labs']}}]" routerLinkActive="active">Labs</li>
      <li [routerLink]="[{ outlets: { content: ['hg-documents']}}]" routerLinkActive="active">Documents</li>
      <li [routerLink]="[{ outlets: { content: ['hg-vitalsigns']}}]" routerLinkActive="active">Vital Signs</li>
      <!-- <li [routerLink]="[{ outlets: { content: ['hg-full-ccda']}}]" routerLinkActive="active">Full C-CDA</li> -->
    </ul>

    <ul class="submenu" *ngIf="showHieSubmenu && sharedService.isEncounterNoteAdd">
      <li (click)="sideNavClick('Encounters')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Encounters'">Encounters</li>
      <li (click)="sideNavClick('Immunizations')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Immunizations'">Immunizations</li>
      <li (click)="sideNavClick('Medications')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Medications'">Medications</li>
      <li (click)="sideNavClick('Diagnoses')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Diagnoses'">Diagnoses</li>
      <li (click)="sideNavClick('Allergies')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Allergies'">Allergies</li>
      <li (click)="sideNavClick('Labs')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Labs'">Labs</li>
      <li (click)="sideNavClick('Documents')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Documents'">Documents</li>
      <li (click)="sideNavClick('Vitals')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Vitals'">Vitals</li>
      <!-- <li (click)="sideNavClick('Full-ccda')" routerLinkActive="active"
        [class.active]="sharedService.selectedSubMenu == 'Full-ccda'">Full C-CDA</li> -->
    </ul>
  </ng-container>
  <!-- END HIE RECORDS -->

  <li [routerLink]="[{ outlets: { content: ['file-upload']}}]" routerLinkActive="active">
    <span>Uploaded Files</span>
    <span class="menu-notifier" [class.green]="fileCount != null && fileCount > 0"
      [class.gray]="fileCount == null || fileCount < 1">
      {{fileCount == null ? 0 : fileCount}}</span>
  </li>
  <li *ngIf="isShowEncounterNote" [routerLink]="[{ outlets: { content: ['encounter-notes']}}]"
    routerLinkActive="active">
    <span>Encounter Notes</span>
    <span class="menu-notifier" [class.green]="encounterNoteCount != null && encounterNoteCount > 0"
      [class.gray]="encounterNoteCount == null || encounterNoteCount < 1">
      {{encounterNoteCount == null ? 0 : encounterNoteCount}}</span>
  </li>
</ul>