import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
@Component({
  selector: 'himn-patient-dashboard-flyout',
  templateUrl: './flyout.component.html',
  styleUrls: ['./flyout.component.scss']
})
export class FlyoutComponent implements OnInit, OnDestroy {
  @Input() title: string = 'Filters';
  @Input() isHideTitle: boolean = false;
  @Input() isHideCloseButton: boolean = false;
  @Input() width: string = 'sm';
  @Output() closedEvent = new EventEmitter();
  public isShow: boolean = false;
  private element: any;
  public isEdit: boolean = false;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement; ElementRef
  }

  ngOnInit(): void {

    document.body.appendChild(this.element);
    
    // close modal on background click
    // this.element.addEventListener('click', (el: { target: { className: string; }; }) => {
    //   if (el.target.className === 'modal-background ng-star-inserted') {
    //     this.closedEvent.emit();
    //     this.close();
    //   }
    // });
  }

  // remove self from modal when component is destroyed
  ngOnDestroy(): void {
    this.element.remove();
  }

  // open modal
  open(id?: any): void {
    this.element.style.display = 'block';
    this.isShow = true;
    if (id > 0) {
      this.isEdit = true;
    }
    else {
      this.isEdit = false;
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    document.body.classList.remove('flyout-open');
  }

  // close modal
  close(): void {
    this.isShow = false;
    document.body.classList.add('flyout-open');
  }

}
