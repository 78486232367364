import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService } from '../../services/loading.service';
import { LocalDataService } from '../../services/local-data.service';
import { PatientDashboardService } from '../../services/patient-dashboard.service';

@Component({
  selector: 'app-medications',
  templateUrl: './medications.component.html',
  styleUrls: ['./medications.component.scss'],
  host: {
    class: 'flex-direction--column'
  }
})
export class MedicationsComponent implements OnInit {
  loading$ = this.loader.loading$;

  constructor(
    private pdService: PatientDashboardService,
    private localService: LocalDataService,
    private loader: LoadingService
  ) { }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  tableColumns : string[] = ['name', 'startDate', 'endDate', 'refillsTotal', 'instructions' ];
  dataSource: MatTableDataSource<Medication> = new MatTableDataSource<Medication>([]);

  ngOnInit(): void {
    this.pdService.getMedications().subscribe((data: any) => {
      if (data !== null) {
        this.dataSource = new MatTableDataSource<Medication>(data);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  applyFilter(event: Event) {
    this.dataSource.filter = (event as unknown as String).trim().toLowerCase();
  }
}

export interface Medication {

}