<himn-spinner *ngIf="loading$ | async"></himn-spinner>

<ng-container *ngIf="!(loading$ | async)">

  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input (keyup)="applyFilter($any($event.target).value)" matInput placeholder="Filter">
  </mat-form-field>

  <mat-table matSort [dataSource]="dataSource">
    
    <ng-container matColumnDef="dateTime">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let encounter">{{encounter.dateTime | date}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="visitType">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell *matCellDef="let encounter">{{encounter.visitType}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="diagnoses">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Diagnoses</mat-header-cell>

      <mat-cell *matCellDef="let encounter; let i = index;">
        <ng-container *ngIf="encounter.diagnoses.length > 0">
          <span (click)="openDiagnosesModal(i)"
            style="cursor: pointer; text-decoration: underline; color: blue"
          >
            Diagnoses
          </span>
        </ng-container>
        <ng-container *ngIf="encounter.diagnoses.length < 1">
          <span>--</span>
        </ng-container>
      </mat-cell>

    </ng-container>

    <ng-container matColumnDef="organization.name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Organization</mat-header-cell>
      <mat-cell *matCellDef="let encounter">{{encounter.organization.name}}</mat-cell>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="999" style="vertical-align: middle;">No data to display</td>
    </tr>

    <mat-header-row *matHeaderRowDef="tableColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

  </mat-table>
</ng-container>

<div [hidden]="loading$ | async">
  <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" 
    aria-label="Select page" (page)="trackIndex($event)">
  </mat-paginator>
</div>
