<div class="textarea" style="margin-right: .25rem;">
  <div class="flex-container header-area">
      <div style="margin: 1rem !important;">
          <span>
              <span class="form-header">Labs</span>
          </span>
      </div>
  </div>
  <div class="sub-container">
      <div style="padding: 1rem !important; overflow: auto;height: 78vh !important">
          <lib-labs></lib-labs>
      </div>
  </div>

</div>