<div class="textarea" style="margin-right: .25rem;">
    <div class="flex-container header-area">
        <div style="margin: 1rem !important;">
            <span>
                <span class="form-header">Full C-CDA</span>
            </span>
        </div>
    </div>
    <div class="sub-container">
        <div style="padding: 1rem !important; overflow: auto;height: 78vh !important">
            <lib-documents [isFullCcda]="true"></lib-documents>
        </div>
    </div>
</div>