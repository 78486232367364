import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public fileCountChange = new Subject();
  public encounterNoteCountChange = new Subject();
  public isEncounterNoteAdd: boolean = false;
  public selectedSubMenu = '';
  constructor() { }
  ngOnInit(): void { }
}