import { DatePipe, formatDate } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from 'angular-notifier';
import { LoadingService } from '../../../services/loading.service';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { StringManipService } from '../../../services/string-manip.service';

@Component({
  selector: 'lib-hg-encounters',
  templateUrl: './hg-encounters.component.html',
  styleUrls: ['./hg-encounters.component.scss']
})
export class HgEncountersComponent implements OnInit, AfterViewInit {
  loading$ = this.loader.loading$;

  constructor(
    private pdService: PatientDashboardService,
    private loader: LoadingService,
    private stringUtil: StringManipService,
    private notifier: NotifierService,
    private datePipe: DatePipe,
    private localService: LocalDataService
  ) { }

  @Input('minified') minified: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'dateTime':
            return this.stringUtil.trimAndLower(item.dateTime?.toString());
          case 'encounterType':
            return this.stringUtil.trimAndLower(item.encounterType?.toString());
          case 'encounter':
            return this.stringUtil.trimAndLower(item.encounter?.toString());
          case 'performer':
            return this.stringUtil.trimAndLower(item.performer?.toString());
          case 'startDate':
            return this.stringUtil.trimAndLower(item.startDate?.toString());
          case 'location':
            return this.stringUtil.trimAndLower(item.location?.toString());
          default:
            return '';
        }
      };
      this.dataSource.sort = sort;
    }

    this.dataSource.sort = sort;
  }

  tableColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  currentPage: number = 0;
  itemsPerPage: number = 10;
  hasQueryExpired: boolean = true;
  public encounterTypes: any[] = [];
  public modalityTypes: any[] = [];
  public providerList: any[] = [];
  public selectedProvider: any = null;
  public selectedModalityType: any = null;
  public selectedEncounterType: any = null;
  public selectedStatusType: any;
  public containerWidth: any;

  ngOnInit(): void {
    if (this.minified)
      this.tableColumns = ['encounter', 'encounterType', 'startDate'];
    else
      this.tableColumns = ['encounter', 'encounterType', 'startDate', 'performer', 'location'];

    var summary: any;
    this.localService.dashboardSummary.subscribe(response => {
      summary = JSON.stringify(response);
      //this.hasQueryExpired = response.hasHealthRecordQueryExpired;
    });
    this.hasQueryExpired = JSON.parse(summary).hasHealthRecordQueryExpired;
    this.pdService.getHieEncounters().subscribe((data: any) => {
      if (data !== null) {
        this.dataSource = new MatTableDataSource<any>(data);
        this.dataSource.paginator = this.paginator;
      }
    });

    this.pdService.logHieData().subscribe();
    this.pdService.logHieLabData().subscribe();
  }


  ngAfterViewInit(): void {
    this.containerWidth = document.getElementById('main-container')?.offsetWidth;
    console.log(this.containerWidth, 'width')
  }

  applyFilter(event: Event) {
    this.dataSource.filter = (event as unknown as String).trim().toLowerCase();
  }

  trackIndex(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
  }
}

export interface Encounter {
  dateTime: string;
  encounterType: string;
  encounter: string;
  performer: string;
  performerNpi: string;
  startDate: string;
  stopDate: string;
  location: string;
}