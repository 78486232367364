import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'add-encounter-note-medication',
  templateUrl: './add-encounter-note-medication.component.html',
  styleUrls: ['./add-encounter-note-medication.component.scss']
})
export class AddEncounterNoteMedicationComponent implements OnInit, OnChanges {

  @Output() closePopup = new EventEmitter();
  @Output() openMedicineListPopupEvent = new EventEmitter();
  @Output() addNewMedicationEvent = new EventEmitter();
  @ViewChild("medicationNameField") medicationNameField: any;
  @Input() medicationList: any[] = [];
  @Input() isShowMedHistoryBtn: any;

  buttonText = "Include"

  public medication: any = {
    encounterNoteMedicationId: 0,
    medicationName: "",
    startDate: "",
    endDate: "",
    prescribedRefills: 0,
    directions: "",
    indications: "",
    notes: "",
    Source: "",
    tempId: 0,
    hideDelete: false
  };
  //public medicationList: any[] = [];
  public medicationCount: number = 0;
  public editIndex: number = -1;

  constructor() { }

  ngOnInit(): void {
    //this.medicationNameField.nativeElement.focus();
    this.medication.startDate = formatDate(Date.now().toString(), 'yyyy-MM-dd', 'en');
    this.medication.endDate = formatDate(Date.now().toString(), 'yyyy-MM-dd', 'en');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.medicationList.forEach(med => {
      this.medicationCount = this.medicationCount + 1;
      med.tempId = this.medicationCount;
      med.hideDelete = false
      med.startDate = formatDate(med.startDate, 'yyyy-MM-dd', 'en');
      med.endDate = formatDate(med.endDate, 'yyyy-MM-dd', 'en');
    });
  }


  closeMedicinePopup() {
    this.closePopup.emit();
  }

  addNewmedication() {
    this.focusOnFirstName()
  }

  openMedicineListPopup() {
    this.openMedicineListPopupEvent.emit();
  }


  focusOnFirstName() {
    this.clearForm();
    this.medicationNameField.nativeElement.focus();
  }

  clearForm() {
    this.buttonText = "Include"
    this.medication = {
      encounterNoteMedicationId: 0,
      medicationName: "",
      startDate: "",
      endDate: "",
      prescribedRefills: 0,
      directions: "",
      indications: "",
      notes: "",
      Source: "",
      tempId: 0,
      hideDelete: false
    }
    this.medicationList.forEach(medication => { medication.hideDelete = false });
    this.editIndex = -1;
    this.medication.startDate = formatDate(Date.now().toString(), 'yyyy-MM-dd', 'en');
    this.medication.endDate = formatDate(Date.now().toString(), 'yyyy-MM-dd', 'en');
  }

  editMedication(data: any, index: any) {
    this.buttonText = "Update";
    this.medication = Object.assign({}, data);
    this.medicationList.forEach(medication => { medication.hideDelete = false })
    this.medicationList[index].hideDelete = true;
    this.editIndex = index;
    // this.medication = {
    //   encounterNoteMedicationId: 0,
    //   medicationName: data.medicationName,
    //   startDate: data.startDate,
    //   endDate: data.endDate,
    //   prescribedRefills: data.prescribedRefills,
    //   directions: data.directions,
    //   indications: data.indications,
    //   notes: data.notes,
    //   Source: data.Source,
    //   tempId: data.tempId
    // };
  }



  deleteMedication(index: any) {
    var c = confirm('Are you sure you want to delete this medication ?')
    if (c) {
      this.medicationList.splice(index, 1);
      this.addNewMedicationEvent.emit(this.medicationList);
    }
  }

  addNewMedication() {
    if (this.medication.tempId == 0) {
      this.medicationCount = this.medicationCount + 1;
      this.medication.tempId = this.medicationCount;
      this.medicationList.push(this.medication);
      this.clearForm();
    }
    else if (this.medication.tempId > 0) {
      this.medicationList[this.editIndex] = this.medication;
      this.medicationList[this.editIndex].hideDelete = false;
      // this.medicationList.forEach(med => {
      //   if (med.tempId = this.medication.tempId) {
      //     try {
      //       console.log(med, "data hiiiiiiooooooo")
      //       console.log(this.medication, "data medication")
      //       med = Object.assign({}, this.medication)
      //     }
      //     catch (exception) {
      //       console.log(exception)
      //     }
      //   }
      //   med.hideDelete = false;
      // });
      this.clearForm();
    }
    this.addNewMedicationEvent.emit(this.medicationList);
  }

}
