<himn-spinner *ngIf="loading$ | async"></himn-spinner>

<ng-container *ngIf="!(loading$ | async)">

  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input (keyup)="applyFilter($any($event.target).value)" matInput placeholder="Filter">
  </mat-form-field>

  <mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Medication</mat-header-cell>
      <mat-cell *matCellDef="let med">{{med.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
      <mat-cell *matCellDef="let med">{{med.startDate | date}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
      <mat-cell *matCellDef="let med">{{(med.endDate | date) || '--'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="instructions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Directions</mat-header-cell>
      <mat-cell *matCellDef="let med">{{med.instructions}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="refillsTotal">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Total Refills</mat-header-cell>
      <mat-cell *matCellDef="let med">{{med.refillsTotal || '--'}}</mat-cell>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="999" style="vertical-align: middle;">No data to display</td>
    </tr>

    <mat-header-row *matHeaderRowDef="tableColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

  </mat-table>
</ng-container>

<div [hidden]="loading$ | async">
  <mat-paginator [length]="100" [pageSize]="10" 
    [pageSizeOptions]="[5, 10, 25, 50]" aria-label="Select page">
  </mat-paginator>
</div>
