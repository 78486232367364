<ng-container (window:resize)="onResize($event)">
    <form [formGroup]="noteForm" (ngSubmit)="saveEncounterNotes()" [hidden]="(loading$ | async)">
        <div class="flex-container">
            <div class="flex-container-checkbox" style="cursor: pointer;">
                <div style="padding-top: .5rem;">
                    <a (click)="BackToListPage()">
                        <img src="../../../../../assets/images/iconsUndo.svg" alt="" style="height: 1.4rem;">
                    </a>
                </div>
                <div style="margin-left: .25rem;padding-top: .7rem;">
                    <span><a (click)="BackToListPage()" class="back-label">Back to Encounter Note
                            Listing</a></span>
                </div>
            </div>
            <div>

                <a *ngIf="isFinalized && showPdfIcon">
                    <img (click)="exportPdf()" matTooltip="Download Pdf" class="pointer pdfIcon"
                        src="../../../../assets/images/pdf-svgrepo-com.svg">
                </a>

                <span *ngIf="!this.isFinalized">
                    <span><button class="btn-sm  btn-save" type="submit"
                            [disabled]="!enableSubmitButtom">Save</button></span>
                    <span><button *ngIf="userRoles.includes('Provider')" class="btn-sm  btn-save  btn-m" type="button"
                            (click)="finalizeEncounterNote()">Finalize</button></span>
                </span>
            </div>
        </div>
        <div class="mt-05" style="background-color: white">
            <div style="height: 78vh;width: 100%;background-color: white;z-index: 1;">
                <div class="flex-container" style="height: 100%;">
                    <div class="textarea" [style.width.px]="widthOne" *ngIf="displayAreaOne"
                        style="margin-right: .25rem;">
                        <div class="flex-container header-area">
                            <div style="margin: 1rem;">
                                <span>
                                    <span class="form-header">{{showComponent}}</span>
                                </span>
                            </div>
                            <div style="padding: .75rem;">
                                <a (click)="onClickAreaOneExpand()" style="cursor: pointer;">
                                    <img src="../../../../../assets/images/minimize.svg" alt="logo-dark"
                                        style="height: 1.5rem;" *ngIf="!expandAreaOne">
                                    <img src="../../../../../assets/images/maximize.svg" alt="logo-dark"
                                        style="height: 1.5rem;" *ngIf="expandAreaOne">
                                </a>
                            </div>
                        </div>
                        <div
                            style="height: 100%;border: 1px solid #7f7f7f;height: calc(100% - 3rem);overflow: auto; position: relative;">
                            <div style="padding: 1rem; overflow: auto;">
                                <lib-hg-encounters [minified]="true" *ngIf="showComponent == 'Encounters'">
                                </lib-hg-encounters>
                                <lib-hg-allergies *ngIf="showComponent == 'Allergies'"></lib-hg-allergies>
                                <lib-hg-immunizations [minified]="true" *ngIf="showComponent == 'Immunizations'">
                                </lib-hg-immunizations>
                                <lib-hg-medications [minified]="true" *ngIf="showComponent == 'Medications'">
                                </lib-hg-medications>
                                <lib-hg-diagnoses *ngIf="showComponent == 'Diagnoses'"></lib-hg-diagnoses>
                                <lib-labs *ngIf="showComponent == 'Labs'"></lib-labs>
                                <lib-documents *ngIf="showComponent == 'Documents'"></lib-documents>
                                <lib-documents *ngIf="showComponent == 'Full-ccda'"></lib-documents>
                                <lib-hg-vital-signs *ngIf="showComponent == 'Vitals'"></lib-hg-vital-signs>
                            </div>

                        </div>
                    </div>

                    <div class="vl" style="cursor: ew-resize;" *ngIf="displayAreaOne">
                        <img src="../../../../../assets/images/icons-pause.svg" alt="" class="image-not-drag"
                            style="height: 1.8rem;margin-left: -.95rem; margin-top: 38vh;opacity: .5;" disabled>
                    </div>
                    <!-- <div *ngIf="!displayAreaOne" style.width.px="10"></div> -->
                    <div class="textarea" [style.width.px]="widthTwo">
                        <div class="flex-container header-area" [ngClass]="{'area-margin-left':displayAreaOne}">
                            <div style="margin: 1rem;">
                                <span>
                                    <span class="form-header">Encounter Notes</span>
                                </span>
                            </div>
                            <div class="flex-container-end">
                                <div style="margin: 1rem;" *ngIf="widthTwo >= 400">
                                    <span>
                                        <span><button class="small-bt" type="button" (click)="quickNavigation(subject)">
                                                S
                                            </button></span>
                                        <span><button class="small-bt" type="button"
                                                (click)="quickNavigation(objective)">
                                                O
                                            </button></span>
                                        <span><button class="small-bt" type="button"
                                                (click)="quickNavigation(assessment)">
                                                A
                                            </button></span>
                                        <span><button class="small-bt" type="button"
                                                (click)="quickNavigation(assessment)">
                                                P
                                            </button></span>
                                    </span>
                                </div>
                                <!-- <div style="padding: .75rem;">
                                    <a (click)="onClickAreaTwoExpand()">
                                        <img src="../../../../../assets/images/minimize.svg" alt="logo-dark"
                                            style="height: 1.5rem;" *ngIf="!expandAreaTwo">
                                        <img src="../../../../../assets/images/maximize.svg" alt="logo-dark"
                                            style="height: 1.5rem;" *ngIf="expandAreaTwo">
                                    </a>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-area" [ngClass]="{'area-margin-left':displayAreaOne}">
                            <div style="background-color: #F4F6FC;margin: .25rem;">
                                <div class="flex-container-select">
                                    <div class="m-05" style="margin-right: 0;"
                                        [style.margin-left.rem]="widthTwo >= 500?0.5:1"
                                        [style.width.px]="widthTwo >= 500?((widthTwo/2)-38):widthTwo">
                                        <div class="flex-container-start">
                                            <div [style.width.rem]="widthTwo >= 500?(4.5):(10)" class="flex-container">
                                                <span class="filter-label">Visit Date
                                                </span>
                                                <span *ngIf="widthTwo >= 500">
                                                    &nbsp;:&nbsp;
                                                </span>
                                            </div>
                                            <div>
                                                <input type="date" formControlName="visitDate" [(ngModel)]="visitDate"
                                                    value="{{ visitDate  | date:'yyyy-MM-dd' }}"
                                                    [readonly]="this.isFinalized"
                                                    [style.width.px]="widthTwo >= 500?((((widthTwo/2)))-136):widthTwo-65" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-05" style="margin-right: 0;"
                                        [style.margin-left.rem]="widthTwo >= 500?0.25:1"
                                        [style.width.px]="widthTwo >= 500?((widthTwo/2)-18):widthTwo">
                                        <div class="flex-container-input">
                                            <div [style.width.rem]="widthTwo >= 500?(6.5):(10)" class="flex-container">
                                                <span class="filter-label">Time Spent</span>
                                                <span *ngIf="widthTwo >= 500">
                                                    :
                                                </span>

                                            </div>
                                            <div>
                                                <input type="text" mask="separator.1" maxlength="10"
                                                    thousandSeparator="" class="filter-input" placeholder="In Minutes"
                                                    formControlName="timeSpent" [(ngModel)]="timeSpend"
                                                    [readonly]="this.isFinalized"
                                                    [style.width.px]="widthTwo >= 500?((((widthTwo/2)))-136):widthTwo-67">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="flex-container-select">
                                    <div class="m-05" style="margin-right: 0;"
                                        [style.margin-left.rem]="widthTwo >= 500?0.5:1"
                                        [style.width.px]="widthTwo >= 500?((widthTwo/2)-38):widthTwo">
                                        <div class="flex-container-start">
                                            <div [style.width.rem]="widthTwo >= 500?(4.5):(10)" class="flex-container">
                                                <span class="filter-label">Modality</span>
                                                <span *ngIf="widthTwo >= 500">:&nbsp;</span>
                                            </div>

                                            <div>
                                                <select class="filter-select" formControlName="visitTypeId"
                                                    [(ngModel)]="selectedModalityType" [disabled]="isFinalized"
                                                    [style.width.px]="widthTwo >= 500?((((widthTwo/2)))-130):widthTwo-60">
                                                    <option value=null disabled selected
                                                        style="background-color: white;">
                                                        -select-</option>
                                                    <option *ngFor="let type of modalityTypes" [disabled]="isFinalized"
                                                        [ngValue]="type" style="background-color: white;">
                                                        {{type.typeName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-05" style="margin-right: 0;"
                                        [style.margin-left.rem]="widthTwo >= 500?0.25:1"
                                        [style.width.px]="widthTwo >= 500?((widthTwo/2)-18):widthTwo">
                                        <div class="flex-container-input">
                                            <div [style.width.rem]="widthTwo >= 500?(6.5):(10)" class="flex-container">
                                                <span class="filter-label">Encounter Type</span>
                                                <span *ngIf="widthTwo >= 500">:</span>
                                            </div>
                                            <div>
                                                <select class="filter-select" formControlName="encounterTypeId"
                                                    [(ngModel)]="selectedEncounterType" [disabled]="isFinalized"
                                                    [style.width.px]="widthTwo >= 500?((((widthTwo/2)))-130):widthTwo-60">
                                                    <option value=null disabled selected
                                                        style="background-color: white;">
                                                        -select-</option>
                                                    <option [disabled]="isFinalized" *ngFor="let type of encounterTypes"
                                                        [ngValue]="type" style="background-color: white;">
                                                        {{type.typeName}}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div #subject class="m-1 ml-05" id="subject" formGroupName="encounterNoteSubjective">
                                <div>
                                    <span class="sub-heading">Subjective</span>
                                </div>
                                <mat-divider class="mt-05"></mat-divider>
                                <div class="mt-05 mb-05">
                                    <div class="flex-container-input">
                                        <div class="label-width">
                                            <span>
                                                <span class="input-label">Chief Complient (CC) : </span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="chiefComplaint"
                                                [readonly]="this.isFinalized"
                                                [(ngModel)]="encounterNoteSubjective.chiefComplaint"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                    <div class="flex-container-input">
                                        <div class=" label-width">
                                            <span>
                                                <span class="input-label">History of Present Illness (HPI) : </span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="hpi"
                                                [readonly]="this.isFinalized" [(ngModel)]="encounterNoteSubjective.hpi"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                    <div class="flex-container-input">
                                        <div class="label-width">
                                            <span>
                                                <span class="input-label">Past Medical History (PMH) : </span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="pmh"
                                                [readonly]="this.isFinalized" [(ngModel)]="encounterNoteSubjective.pmh"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                    <div class="flex-container-input">
                                        <div class="label-width">
                                            <span>
                                                <span class="input-label">Social History (SH) : </span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="socialHistory"
                                                [readonly]="this.isFinalized"
                                                [(ngModel)]="encounterNoteSubjective.socialHistory"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                    <div class="flex-container-input">
                                        <div class=" label-width">
                                            <span>
                                                <span class="input-label">Family History (FH) : </span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="familyHistory"
                                                [readonly]="this.isFinalized"
                                                [(ngModel)]="encounterNoteSubjective.familyHistory"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                    <div class="flex-container-input">
                                        <div class="label-width">
                                            <span>
                                                <span class="input-label">Review of Systems (ROS) : </span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="ros"
                                                [(ngModel)]="encounterNoteSubjective.ros" [readonly]="this.isFinalized"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="m-1 ml-05" #objective id="objective" formGroupName="encounterNoteObjective">
                                <div>
                                    <span class="sub-heading">Objective</span>
                                </div>
                                <mat-divider style="margin-top: .5rem;"></mat-divider>
                                <div class="mt-05">
                                    <div class="flex-container-input">
                                        <div class=" label-width">
                                            <span>
                                                <span class="input-label">Medications :</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="mt-05 mb-05 medication-box">
                                        <table style="font-size: 0.8rem;">
                                            <tr style="background-color: #F4F6FC;">
                                                <th>Name and Strength</th>
                                                <th>Start Date</th>
                                                <th>Directions</th>
                                                <th style="float: right;"><a *ngIf="!isFinalized"
                                                        (click)="openMedicineAddPopup()" style="cursor: pointer;">
                                                        <img src="../../../../../assets/images/noun-fullscreen.svg"
                                                            alt="logo-dark" style="height: 2rem;">
                                                    </a></th>
                                            </tr>

                                            <tr *ngFor="let med of medications">
                                                <td style="width: 35%;">{{med.medicationName}}</td>
                                                <td style="width: 20%;">{{med.startDate | date:'MM-dd-yyyy'}}</td>
                                                <td style="width: 40%;">{{med.directions}}</td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        <div *ngIf="medications.length == 0">
                                            <div style="padding-top: 1.5rem;padding-bottom: 2.5rem;">
                                                <span [style.margin-left.px]="((widthTwo-180)/2)-28"><button
                                                        class="btn-sm  btn-save " type="button"
                                                        [disabled]='disableAddBtn' (click)="openMedicineListPopup()"
                                                        *ngIf="!isFinalized">Add
                                                        Medications</button></span>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="flex-container-input">
                                        <div class=" label-width">
                                            <span>
                                                <span class="input-label">Allergies :</span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="allergies"
                                                [(ngModel)]="encounterNoteObjective.allergies"
                                                [readonly]="this.isFinalized"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                    <div class="flex-container-input  mb-05">
                                        <div class=" label-width">
                                            <span>
                                                <span class="input-label">Vital Signs :</span>
                                            </span>
                                        </div>
                                        <div class="flex-container-checkbox"
                                            [style.width.px]="widthTwo >= 500?(widthTwo-255):widthTwo-55">
                                            <div class="flex-container-checkbox"
                                                [style.width.px]="((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9">
                                                <div>
                                                    <span class="vital-label" style="margin-right: 7px;">BP</span>
                                                </div>
                                                <div>
                                                    <input type="text" maxlength="10" formControlName="systolic"
                                                        [(ngModel)]="encounterNoteObjective.systolic"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2"
                                                        mask="separator.1" maxlength="10" thousandSeparator="">
                                                    <div style="padding-top: 2px; width: 2px;">
                                                        <span style="font-size: 10px;margin-left: 2px;">mmHg</span>
                                                    </div>
                                                </div>
                                                <div><span
                                                        style="font-size: 1rem;margin-left: 2px;margin-right: 2px;">/</span>
                                                </div>
                                                <div>
                                                    <input type="text" formControlName="diastolic"
                                                        [(ngModel)]="encounterNoteObjective.diastolic"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2"
                                                        mask="separator.1" maxlength="10" thousandSeparator="">
                                                </div>
                                            </div>
                                            <div class="flex-container-end"
                                                [style.width.px]="((widthTwo >= 500?(widthTwo-255):widthTwo-55)*2)/9">
                                                <div>
                                                    <span class="vital-label mr-25">RR</span>
                                                </div>
                                                <div>
                                                    <input type="text" mask="separator.1" maxlength="10"
                                                        thousandSeparator="" formControlName="rr"
                                                        [(ngModel)]="encounterNoteObjective.rr"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2"
                                                        thousandSeparator="">
                                                    <div style="padding-top: 2px; width: 2px;">
                                                        <span style="font-size: 10px;margin-left: 2px;">BPM</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-container-end"
                                                [style.width.px]="((widthTwo >= 500?(widthTwo-255):widthTwo-55)*2)/9">
                                                <div>
                                                    <span class="vital-label mr-25">HR</span>
                                                </div>
                                                <div>
                                                    <input type="text" mask="separator.1" maxlength="10"
                                                        thousandSeparator="" formControlName="hr"
                                                        [(ngModel)]="encounterNoteObjective.hr"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2"
                                                        thousandSeparator="">
                                                    <div style="padding-top: 2px; width: 2px;">
                                                        <span style="font-size: 10px;margin-left: 2px;">BPM</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-container-end"
                                                [style.width.px]="((widthTwo >= 500?(widthTwo-255):widthTwo-55)*2)/9">
                                                <div>
                                                    <span class="vital-label mr-25">Temp</span>
                                                </div>
                                                <div>
                                                    <input type="text" mask="separator.1" maxlength="10"
                                                        thousandSeparator="" formControlName="temp"
                                                        [(ngModel)]="encounterNoteObjective.temp"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2">
                                                    <div style="padding-top: 2px; width: 2px;">
                                                        <span style="font-size: 10px;margin-left: 2px;">ºF</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-container-input  mb-05">
                                        <div class=" label-width">
                                            <span>
                                                <span class="input-label"></span>
                                            </span>
                                        </div>
                                        <div class="flex-container-checkbox"
                                            [style.width.px]="widthTwo >= 500?(widthTwo-255):widthTwo-55">
                                            <div class="flex-container-checkbox"
                                                [style.width.px]="((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9">
                                                <div>
                                                    <span class="vital-label mr-25">WT</span>
                                                </div>
                                                <div>
                                                    <input type="text" formControlName="wt"
                                                        [(ngModel)]="encounterNoteObjective.wt"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2"
                                                        mask="separator.1" maxlength="10" thousandSeparator=""
                                                        separatorLimit="100">
                                                    <div style="padding-top: 2px; width: 2px;">
                                                        <span style="font-size: 10px;margin-left: 2px;">lb</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-container-end"
                                                [style.width.px]="((widthTwo >= 500?(widthTwo-255):widthTwo-55)*2)/9">
                                                <div>
                                                    <span class="vital-label mr-25">HT</span>
                                                </div>
                                                <div>
                                                    <input type="text" formControlName="ht"
                                                        [(ngModel)]="encounterNoteObjective.ht"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2"
                                                        mask="separator.1" maxlength="10" thousandSeparator="">
                                                    <div style="padding-top: 2px;width: 10px;">
                                                        <span style="font-size: 10px;margin-left: 2px;">in.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-container-end"
                                                [style.width.px]="((widthTwo >= 500?(widthTwo-255):widthTwo-55)*2)/9">
                                                <div>
                                                    <span class="vital-label mr-25">BMI</span>
                                                </div>
                                                <div>
                                                    <input type="text" formControlName="bmi"
                                                        [(ngModel)]="encounterNoteObjective.bmi"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2"
                                                        mask="separator.1" maxlength="10" thousandSeparator="">
                                                    <div style="padding-top: 2px; width: 2px;">
                                                        <span style="font-size: 10px;margin-left: 2px;">
                                                            kg/m<sup>2</sup></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-container-end"
                                                [style.width.px]="((widthTwo >= 500?(widthTwo-255):widthTwo-55)*2)/9">
                                                <div>
                                                    <span class="vital-label mr-25">SpO<sub>2</sub></span>
                                                </div>
                                                <div>
                                                    <input type="text" formControlName="o2"
                                                        [(ngModel)]="encounterNoteObjective.o2"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="((((widthTwo >= 500?(widthTwo-255):widthTwo-55)*3)/9)-64)/2"
                                                        mask="separator.1" maxlength="10" thousandSeparator="">
                                                    <div style="padding-top: 2px; width: 2px;">
                                                        <span style="font-size: 10px;margin-left: 2px;"> %</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex-container-input">
                                        <div class="label-width">
                                            <span>
                                                <span class="input-label">Laboratory Values :</span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="labValues"
                                                [readonly]="this.isFinalized"
                                                [(ngModel)]="encounterNoteObjective.labValues"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                    <div class="flex-container-input">
                                        <div class="label-width">
                                            <span>
                                                <span class="input-label">Immunization :</span>
                                            </span>
                                        </div>
                                        <div>
                                            <textarea autosize [minRows]="1" formControlName="immunization"
                                                [readonly]="this.isFinalized"
                                                [(ngModel)]="encounterNoteObjective.immunization"
                                                [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div #assessment formArrayName="encounterNoteProblems">
                                <div *ngFor="let plan of getProblems.controls;let i = index;" class="m-05 ml-05 p-05"
                                    style="background-color: #F4F6FC">
                                    <div [formGroupName]="i">
                                        <div class="flex-container">
                                            <span class="sub-heading">Problem
                                                <span style="font-style: italic;font-size: 1rem;">(Assessment &
                                                    Plan)</span>
                                            </span>
                                            <span>
                                                <button *ngIf="!isFinalized" type="button" class="close-btn"
                                                    (click)="CloseSection(i)">
                                                    x
                                                </button>
                                            </span>
                                        </div>

                                        <div class="mt-05 mb-05">
                                            <mat-divider class="mt-05 mb-05"></mat-divider>
                                            <div class="flex-container-input">
                                                <div class=" label-width">
                                                    <span>
                                                        <span class="input-label">Problem :</span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <textarea autosize [minRows]="1" formControlName="problem"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                                </div>
                                            </div>
                                            <div class="flex-container-input">
                                                <div class="label-width">
                                                    <span>
                                                        <span class="input-label">Assessment :</span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <textarea autosize [minRows]="1" formControlName="assessment"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                                </div>
                                            </div>
                                            <div class="flex-container-input">
                                                <div class="label-width">
                                                    <span>
                                                        <span class="input-label">Treatment Plan :</span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <textarea autosize [minRows]="1" formControlName="treatmentPlan"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                                </div>
                                            </div>
                                            <div class="flex-container-input">
                                                <div class="label-width">
                                                    <span>
                                                        <span class="input-label">Education and Counseling :</span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <textarea autosize [minRows]="1"
                                                        formControlName="educationCounseling"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                                </div>
                                            </div>
                                            <div class="flex-container-input">
                                                <div class="label-width">
                                                    <span>
                                                        <span class="input-label">Monitoring and Followup : </span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <textarea autosize [minRows]="1"
                                                        formControlName="monitoringFollowUp"
                                                        [readonly]="this.isFinalized"
                                                        [style.width.px]="widthTwo >= 500?(widthTwo-260):widthTwo-60"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="m-05 p-05" style="background-color: #F4F6FC" *ngIf="!isFinalized">
                                <div class="flex-container-input">
                                    <div>
                                        <span>
                                            <span></span>
                                        </span>
                                    </div>
                                    <div class="flex-container-end">
                                        <div>
                                            <img src="../../../../../assets/images/add-file.svg" alt="logo-dark"
                                                style="height: 1.5rem;">
                                        </div>
                                        <div style="padding-top: 4px;">
                                            <a (click)="addProblems()"
                                                style="font-size: 12px;color:#D64F15;cursor: pointer;">Add
                                                Additional
                                                Problem</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>
</ng-container>
<modal #encounterNoteMedication [isHideTitle]=false [title]="medicationListPopupHeading"
    [width]="medicationListPopupWidth">
    <encounter-note-medication (closePopup)="closeMedicineListPopup()" *ngIf="isOpenMedPopup"
        (includeMedicinePopupEvent)="includeMedicinePopupEvent($event)" [medicationList]="medicationList"
        [hasHealthGorillaFeatures]="hasHealthGorillaFeatures"></encounter-note-medication>
</modal>

<modal #addEncounterNoteMedication [isHideTitle]=false [title]="addMedicationPopupHeading"
    [width]="addMedicationPopupWidth">
    <add-encounter-note-medication (closePopup)="closeMedicineAddPopup($event)"
        (addNewMedicationEvent)="addNewMedicationPopupEvent($event)" [medicationList]="medications"
        (openMedicineListPopupEvent)="openMedicationListPopupbyEvent()" [isShowMedHistoryBtn]="isShowMedHistoryBtn">
    </add-encounter-note-medication>
</modal>

<modal #confirmPopup [isHideTitle]=false [title]="'Confirm'" [width]="650">
    <div>
        <div class="p-h">
            <span class="confirm-text">You currently have unsaved changes...</span>
        </div>
        <div class="flex-container-input p-b">
            <div>
                <div>
                    <button class="btn-sm  btn-confirm"
                        (click)="deleteGenericJson(autoSaveJson.genericJsonId);this.backToList.emit()">Don't
                        Save</button>
                </div>

            </div>
            <div class="flex-container-end">

                <div>
                    <button class="btn-sm  btn-confirm  btn-m" (click)="confirmPopup.close()">Cancel</button>
                </div>
                <div>
                    <button class="btn-sm  btn-confirm  btn-m"
                        (click)="saveEncounterNotes();confirmPopup.close()">Save</button>
                </div>
            </div>
        </div>
    </div>
</modal>
<himn-spinner [hidden]="!(loading$ | async)"></himn-spinner>