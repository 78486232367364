import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HieRequest, HieRequestAddress } from 'projects/components/src/lib/models/hie-request';
import { LocalDataService } from 'projects/components/src/lib/services/local-data.service';
import { PatientDashboardModule, PatientDashboardService } from 'projects/components/src/public-api';
import { catchError, Observable, throwError } from 'rxjs';
//@ts-ignore
import * as StateData from '../../../../assets/states_titlecase.json';
import { HiePatientmatchComponent } from '../../../dialogs/hie-patientmatch/hie-patientmatch.component';

@Component({
  selector: 'himn-pd-hie-request',
  templateUrl: './hie-request.component.html',
  styleUrls: ['./hie-request.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class HieRequestComponent implements OnInit {

  @Output() flyoutclose = new EventEmitter();
  @Output() listrefresh = new EventEmitter();
  @ViewChild('flyout') flyout: any;

  constructor(
    public datePipe: DatePipe,
    public pdService: PatientDashboardService,
    public localService: LocalDataService,
    public dialog: MatDialog
  ) { }

  newHieRequest: HieRequest = new HieRequest();
  today: string | null = this.datePipe.transform(new Date, 'yyyy-MM-dd');
  invalidForm: boolean = true;
  stateList: any[] = [];
  cellConfirmMatches: boolean = true;
  showErrorMessage: boolean = false;
  errorMessage: string = '';
  buttonText: string = 'Request';
  patientMatches: any;
  loadingComplete: boolean = true;

  ngOnInit(): void {
    this.populateStateDropdown();
    this.prepopulateRequestFlyout();
  }

  onSubmit(form: any) {
    this.errorMessage = '';

    this.sanitizeInput(form);
    this.checkRequired(form);
    this.checkValidity(form);

    if (this.errorMessage != null && this.errorMessage != '') {
      this.showErrorMessage = true;
      return;
    }
    else
      this.showErrorMessage = false;

    this.loadingComplete = false;

    this.pdService.hieRequest(form).subscribe({
      next: (response: any) => {
        this.patientMatches = response;
        if (response.cwPatientResults === undefined)
          this.pdService.getDocuments(response.hgPatientId).subscribe({
            complete: () => {
              location.reload();
            }})
        else
          this.openPatientMatchModal();
      },
      error: (response: HttpErrorResponse) => {
        this.errorMessage = response.error;
        this.showErrorMessage = true;
        this.loadingComplete = true;
      }
    })
  }

  prepopulateRequestFlyout() {
    this.pdService.getPatientDemographics().subscribe((response: any) => {
      this.newHieRequest.cell = response.cellPhone;
      this.newHieRequest.cellConfirm = response.cellPhone;
      this.newHieRequest.firstName = response.firstname;
      this.newHieRequest.middleInitial = response.middlename;
      this.newHieRequest.lastName = response.lastname;
      this.newHieRequest.dob = response.dob;
      this.newHieRequest.gender = response.gender;

      // we will always enforce the existence of 
      // at least one address for the request
      this.newHieRequest.addresses.push(new HieRequestAddress);

      this.newHieRequest.addresses[0].street = response.address1;
      this.newHieRequest.addresses[0].extension = response.address2;
      this.newHieRequest.addresses[0].city = response.city;
      this.newHieRequest.addresses[0].state = response.state;
      this.newHieRequest.addresses[0].zip = response.zip;
      this.newHieRequest.addresses[0].homePhone = response.homePhone;

      this.pdService.getPatientAddressHistory().subscribe((response: any) => {
        if (response.length > 0) {
          response.forEach((address: HieRequestAddress) => {
            this.newHieRequest.addresses.push(address);
          });
        }
        
        this.sanitizeInput(this.newHieRequest);
      });
    });
  }

  populateStateDropdown() {
    Object.values(StateData).map((s: any) => {
      if(s.hasOwnProperty('name') && s.hasOwnProperty('abbreviation')) this.stateList.push(s);
    });
  }

  sanitizeInput(input: any) {
    // trim all whitespace and null if nothing but whitespace
    for (let key in input) {
      if (typeof input[key] === 'string')
        input[key] = input[key].trim() !== '' ? input[key].trim() : null;
      else if (typeof input[key] === 'object')
        this.sanitizeInput(input[key])
    }
  }

  checkRequired(request: HieRequest) {
    if (request.firstName == null)
      this.errorMessage += '**First Name is required<br />';

    if (request.lastName == null)
      this.errorMessage += '**Last Name is required<br />';

    if (request.dob == null)
      this.errorMessage += '**Date of Birth is required<br />';

    if (request.gender == null)
      this.errorMessage += '**Gender is required<br />';

    request.addresses.forEach((address, i) => {
      let instance = i + 1;

      if (address.street == null)
        this.errorMessage += `**Address ${instance} is required<br />`;

      if (address.city == null)
        this.errorMessage += `**City for Address ${instance} is required<br />`;
      
      if (address.state == null)
        this.errorMessage += `**State for Address ${instance} is required<br />`;

      if (address.zip == null)
        this.errorMessage += `**Zip for Address ${instance} is required<br />`;
    });
  }

  checkValidity(request: HieRequest) {
    if (request.cell !== request.cellConfirm)
      this.errorMessage += '**Cell Phone does not match<br />';

    if (request.cell && request.cell.length !== 10)
      this.errorMessage += '**Invalid Cell Phone Number<br />';

    request.addresses.forEach((address, i) => {
      let instance = i + 1;

      if (address.zip && address.zip.length !== 5 || (!/^[0-9]*$/.test(address.zip!)))
        this.errorMessage += `**Invalid Address ${instance} Zip<br />`;

      if (address.homePhone && address.homePhone.length !== 10)
        this.errorMessage += `**Invalid Address ${instance} Home Phone Number<br />`;
    });
  }

  cellConfirmCompare() {
    if (this.newHieRequest.cell !== this.newHieRequest.cellConfirm)
      this.cellConfirmMatches = false;
    else
      this.cellConfirmMatches = true;
  }

  calcTabIndex(start: number, index: number) {
    // when we generate a new address section
    // keep the tabbing in a sane order
    return start * (index + 1);
  }

  scroll(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView({behavior: 'smooth'});
    }, 300)
  }

  addPreviousAddress() {
    this.newHieRequest.addresses.push(new HieRequestAddress);
  }

  removeAdditionalAddress(index: number) {
    this.newHieRequest.addresses.splice(index, 1);
  }

  closeFlyout(): void {
    this.clearForm();
    this.flyoutclose.emit();
    this.errorMessage = '';
  }

  clearForm(): void {
    this.buttonText = "Request";
    this.showErrorMessage = false;
  }

  openPatientMatchModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = this.patientMatches;

    this.dialog.open(HiePatientmatchComponent, dialogConfig);
    this.loadingComplete = true;
    this.closeFlyout();
  }
}
