<himn-spinner *ngIf="!loadingComplete"></himn-spinner>
<notifier-container></notifier-container>
<div style="height: 100%" [hidden]="!loadingComplete">
  <router-outlet></router-outlet>
</div>

<!-- Hold a single instance of the Hapi Connection button -->
<!-- All buttons trying to launch the window should trigger this click -->
<div class="connection-info hapi__token-container" style="display: none;">
  <button id="hapi-connect-btn" type="button" [attr.data-hapi-token]="token"></button>   
</div>

<!-- Keep a hidden iframe to logout of HeadsUp when needed -->
<div style="display: none;">
  <iframe [src]="logoutUrl | safeurl"></iframe>
</div>
