import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Input, ViewEncapsulation, NgZone, Inject } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { PatientDashboardService } from './services/patient-dashboard.service';
import { LocalDataService } from './services/local-data.service';
//@ts-ignore
import * as HumanConnect from 'humanapi-connect-client';
import { Constants } from './shared/constants';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { HttpCancelService } from './services/http-cancel.service';

@Component({
  selector: 'himn-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PatientDashboardComponent {
  constructor(
    private pdService: PatientDashboardService,
    private router: Router,
    private zone: NgZone,
    private localService: LocalDataService,
    private idle: Idle,
    private keepalive: Keepalive,
    private httpCancelService: HttpCancelService,
    @Inject('environment') environment: any
  ) {
    this.environment = environment;

    // how long until the user is considered idle
    idle.setIdle(840);

    // how long the delay is from idle time to being idled out
    idle.setTimeout(5);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.pdService.logout(this.requestorId).subscribe();
      this.router.navigate(['logout']);
    });

    //TODO: popup a 'continue' session modalc
    //idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');

    //TODO: countdown on the 'continue' session modal
    //idle.onTimeoutWarning.subscribe((countdown) => this.idleState = `You will time out in ${countdown} seconds!`);

    keepalive.interval(15);

    this.reset();
  }

  @Input('provider-view') providerView: boolean = false;
  @Input('auth-token') authToken: string | null = null;
  @Input('user-id') userId: string | null = null;
  @Input('access-id') accessId: string | null = null;
  @Input('requestor-id') requestorId: string | null = null;
  @Input('initial-route') initialRoute: string = 'file-upload';
  @Input('practice-id') practiceId: string = '';
  patientFirst = '';
  patientLast = '';
  patientDob = '';
  patientGender = '';
  cellPhone = '';
  email = '';
  showAuthenticationError: boolean = false;
  loadingComplete: boolean = false;
  hasHeadsUpActive: boolean = false;
  hasHeadsUpEnabled: boolean = false;
  hasHealthGorillaEnabled: boolean = false;
  hasHealthGorillaConnection: boolean = false;
  hasHumanApiEnabled: boolean = false;
  isHealthGorillaSponsor: boolean = false;
  token: string = '';
  hapiConnectionCount: number = 0;
  hapiDataToken: string = '';
  fileCount: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = undefined;
  environment: string = '';
  logoutUrl: string = '';
  encounterNoteCount = 0;
  isAcceptTC = false;
  hasEncounterNotesEnabled: boolean = false;
  practiceName = '';
  address1 = '';
  city = '';
  zip = '';
  state = '';
  autoSaveConfig: any;
  hasHealthRecordQueryExpired = true;

  ngOnInit(): void {
    document.body.addEventListener('updoxLogout', (evt: any) => {
      this.logoutUrl = evt.detail;
      //@ts-ignore
      document.body.removeEventListener('updoxLogout', null);
    });

    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.httpCancelService.cancelPendingRequests()
      }
    })
  }

  // we need to wait for the application to 'change'
  // aka recieve the token and userid from the host app
  ngOnChanges(): void {
    this.localService.updateAuthInfo({
      authToken: this.authToken,
      userId: this.userId,
      requestorId: this.requestorId,
      accessId: this.accessId,
      practiceId: this.practiceId
    });

    if (!!this.authToken && !!this.userId && !!this.accessId && !!this.requestorId) {
      sessionStorage.setItem(Constants.SESSION_AUTH_TOKEN, this.authToken);
      sessionStorage.setItem(Constants.SESSION_USER_ID, this.userId);
      sessionStorage.setItem(Constants.SESSION_AUTH_ACCESS, this.accessId);
      sessionStorage.setItem(Constants.SESSION_REQUESTOR_ID, this.requestorId);
      sessionStorage.setItem(Constants.SESSION_PRACTICE_ID, this.practiceId);
    }

    this.getPatientData();
    this.countFiles();
    // this.encounterNotesCount();
    this.getPracticeInfo();
  }

  // after everything is done, lets update our global values
  ngDoCheck() {
    this.localService.updateDashboardSummary({
      providerView: this.providerView,
      hasActiveHeadsUp: this.hasHeadsUpActive,
      hasHeadsUpEnabled: this.hasHeadsUpEnabled,
      hasHealthGorillaConnection: this.hasHealthGorillaConnection,
      hasHealthGorillaEnabled: this.hasHealthGorillaEnabled,
      isHealthGorillaSponsor: this.isHealthGorillaSponsor,
      patientFirst: this.patientFirst,
      patientLast: this.patientLast,
      connectionCount: this.hapiConnectionCount,
      hapiDataToken: this.token,
      fileCount: this.fileCount,
      patientDob: this.patientDob,
      patientGender: this.patientGender,
      initialRoute: this.initialRoute,
      //  encounterNoteCount: this.encounterNoteCount
      isAcceptTC: this.isAcceptTC,
      hasHumanApiEnabled: this.hasHumanApiEnabled,
      hasEncounterNotesEnabled: this.hasEncounterNotesEnabled,
      practiceName: this.practiceName,
      address1: this.address1,
      city: this.city,
      state: this.state,
      zip: this.zip,
      autosaveConfig: this.autoSaveConfig,
      cellPhone: this.cellPhone,
      email: this.email,
      hasHealthRecordQueryExpired: this.hasHealthRecordQueryExpired
    })
  }

  countFiles() {
    this.pdService.fileCount(this.providerView, Number(this.practiceId)).subscribe(
      (data: any) => {
        this.fileCount = data.data.fileCount;
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      }
    );
  }

  // encounterNotesCount() {
  //   this.pdService.encounterNotesCount().subscribe(
  //     (res: any) => {
  //       this.encounterNoteCount = res.data.count;
  //     },
  //     (err: HttpErrorResponse) => {
  //       console.error(err);
  //     }
  //   );
  // }

  getPracticeInfo() {
    this.pdService.getPracticeInfo(this.practiceId).subscribe((result: any) => {
      this.practiceName = result.practiceName;
    })
  }

  getPatientData() {
    this.pdService.getPatient().subscribe(
      (data: any) => {
        if (data !== null) {

          this.patientFirst = data.user.firstname;
          this.patientLast = data.user.lastname;
          this.patientGender = data.user.gender;
          this.patientDob = data.user.dob;
          this.email = data.user.email;
          this.cellPhone = data.user.cellPhone;
          this.address1 = data.user.address1;
          this.city = data.user.city;
          this.state = data.user.state;
          this.zip = data.user.zip;
          this.hasHeadsUpActive = data.hasHeadsUpActive;
          this.hasHeadsUpEnabled = data.hasHeadsUpEnabled;
          this.hasHealthGorillaConnection = data.hasHealthGorillaConnection;
          this.hasHealthGorillaEnabled = data.hasHealthGorillaEnabled;
          this.isHealthGorillaSponsor = data.isHealthGorillaSponsor;
          this.hasHumanApiEnabled = data.hasHumanApiEnabled;
          this.isAcceptTC = data.user.acceptTC;
          this.hasEncounterNotesEnabled = data.hasEncounterNotesEnabled;
          this.autoSaveConfig = data.autosaveConfig;
          this.hasHealthRecordQueryExpired = data.hasHealthRecordQueryExpired;
          if (this.hasHumanApiEnabled)
            this.connectHumanApi();
          else
            this.renderDashboard();
        }
      },
      (error: HttpErrorResponse) => {
        this.loadingComplete = true;
        this.showAuthenticationError = true;

        //TODO: capture an unauthorized error and try to reauthenticate

        console.error(error);
      }
    );
  }

  connectHumanApi() {
    this.pdService.getHumanConnection().subscribe(
      (data: any) => {
        this.token = data.token;
        this.hapiConnectionCount = data.numberOfConnections;

        setTimeout(() => {
          this.bindHumanConnect();
        }, 0);

        this.renderDashboard();
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      }
    );
  }

  bindHumanConnect() {
    // this is the recommended fix from Human API that prevents
    // the load event from being attached too soon
    var event = document.createEvent('Event');
    event.initEvent('load', true, true);
    window.dispatchEvent(event);

    HumanConnect.on('close', (response: any) => {
      if (response.sessionResults.connectedSources.length > 0 ||
        response.sessionResults.disconnectedSources.length > 0) {
        var amountOfConnections = response.currentConnections.length;
        this.pdService.humanApiSourcesUpdate(amountOfConnections).subscribe((data: any) => {
          this.zone.run(() => {
            this.hapiConnectionCount = data;
            location.reload();
          });
        });
      }
    });
  }

  private renderDashboard() {
    this.loadingComplete = true;
    this.router.navigate(['dashboard']);
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  onBeforeUnload() {
    this.pdService.closeHeadsUp().subscribe();
  }
}
