<div class="container" [ngClass]="{'editreq':isEdit}">
    <form>
        <div class="row label-row" style="padding-top:1px; margin-top: 10px;">
            <label class="label-cls" for="file">Filename</label>
            <span *ngIf="!isEdit" class=required>- Required  (Max upload file size 5 MB)</span>
            <span *ngIf="isEdit"> : {{addFile.fileName}}</span>
        </div>  

        <div *ngIf="!isEdit" class="row ">
            <input name="file" type="text" [(ngModel)]="addFile.fileName" class="form-control fileStyle" id="file"
                placeholder="No file Chosen">
            <button [disabled]="buttonClick" type="button" class="btn-upload" (click)="fileInput.click()">
                <span class="label-cls ">Choose File</span>
                <input #fileInput id="fileInput" type="file" onclick="this.value = null"  (change)="fileOnChange(fileInput.files,$event)"
                    style="display:none;" tabindex="1" />
            </button>
        </div>

        <div class="row label-row">
            <label class="label-cls" for="displayName">Display Name</label>
            <span class=required>- Required</span>
        </div>

        <div class="row">
            <input maxlength="100" name="displayName" id="displayName" #displayName="ngModel"
                [(ngModel)]="addFile.displayName" type="text" class="md-textarea form-control" style="width: 94%;"
                tabindex="2" mdbInput>
        </div>

        <div class="row label-row">
            <label class="label-cls" for="description">Description</label>
            <span class=required> (Max 500)</span>
        </div>
        <div class="row">
            <textarea maxlength="500" name="description" id="description" #description="ngModel"
                [(ngModel)]="addFile.description" type="text" class="md-textarea form-control"
                style="width: 94%; height: 6.0em !important;    padding-top: 7px!important;" tabindex="3"
                mdbInput></textarea>
        </div>

        <div class="row label-row" style="  padding-top: 7px;">
            <label class="label-cls tag " for="patientFileTags">Tags</label>
        </div>
        <div style="padding-left: 10px;">
            <ng-select class="label-cls select-option" [items]=" this.fileTags" [multiple]="true" [addTag]="addFileTag"
                bindLabel="name" [loading]="loading" [(ngModel)]="addFile.patientFileTags" name="patientFileTags"
                id="patientFileTags" #patientFileTags="ngModel">

                <ng-template ng-tag-tmp let-search="searchTerm">
                    <b class="label-cls">Add new Tag</b>: {{search}}
                </ng-template>
            </ng-select>
        </div>

        <div class="row label-row form-check">
            <input type="checkbox" for="isAvailableToPatient" tabindex="5" class="form-check-input label-cls"
                name="isAvailableToPatient" id="isAvailableToPatient" #isAvailableToPatient="ngModel"
                [(ngModel)]="addFile.isAvailableToPatient">
            <label class="form-check-label label-cls " for="isAvailableToPatient">
                Make the file available to Patient
            </label>
        </div>
        <div class="row btn">
            <button [disabled]="buttonClick" class="btn-sm btn-cancel " (click)="closeFlyout()"
                type="submit">Cancel</button>
            <button [disabled]="buttonClick" class="btn-sm  btn-save " type="submit"
                (click)="saveFile()">{{buttonText}}</button>
            <div style="display:inline-block; padding-left: 15px; vertical-align: middle;">
                <mat-spinner style="display:inline-block;" *ngIf="!loadingComplete" [diameter]="25"></mat-spinner>
            </div>

        </div>

        <div style="padding-top: 12px!important;">
            <div class="alert msg-alert " [ngClass]="{'alert-danger' : !status, 'alert-success' : status}"
                *ngIf="showMessage">
                <div [innerHTML]="message"></div>
            </div>
            <div id="alertMessage"></div>
        </div>

    </form>
</div>