import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  environment: any;
  baseUrl: string = '';

  protected http: HttpClient;

  constructor(
    protected injector: Injector,
    @Inject('environment') environment: any
  ) {
    this.environment = environment;
    this.baseUrl = this.environment.apiUrl;

    try {
      this.http = this.injector.get(HttpClient);
    } catch (error) {
      throw 'Please import HttpClientModule to the app.';
    }
  }

  protected get(url: string) {
    return this.http.get(this.baseUrl + url);
  }

  protected getWithoutLoader(url: string) {
    return this.http.get(this.baseUrl + url, { params: new HttpParams().set("isHideLoader", "true") })
  }

  protected post(url: string, data: any) {
    return this.http.post(this.baseUrl + url, data);
  }

  protected postWithoutLoader(url: string, data: any) {
    return this.http.post(this.baseUrl + url, data, { params: new HttpParams().set("isHideLoader", "true") });
  }

  protected put(url: string, data: any, params?: any) {
    return this.http.put(this.baseUrl + url, data, params);
  }

  protected putWithoutLoader(url: string, data: any, params?: any) {
    //params += new HttpParams().set("isHideLoader", "true");
    return this.http.put(this.baseUrl + url, data, { params: new HttpParams().set("isHideLoader", "true") });
  }

  protected delete(url: string) {
    return this.http.delete(this.baseUrl + url);
  }

  protected deleteWithoutLoader(url: string) {
    return this.http.delete(this.baseUrl + url, { params: new HttpParams().set("isHideLoader", "true") });
  }
}
