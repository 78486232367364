import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output } from '@angular/core';

//@ts-ignore
import * as HumanConnect from 'humanapi-connect-client';
import { LocalDataService } from '../../services/local-data.service';
import { DashboardSummary } from '../../models/dashboard-summary';
import { Router } from '@angular/router';

@Component({
  selector: 'pd-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    trigger('slideInOutHorizontal', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})

export class NavComponent {
  fileCount: any;
  @Output() fileCountEvent = new EventEmitter();
  constructor(
    private localService: LocalDataService,
    public router: Router
  ) { }

  @Output() mobileMenuIsExpanded: EventEmitter<boolean> = new EventEmitter<boolean>();;
  providerView: boolean = false;
  showMinimenuDropdown: boolean = false;
  showSubmenu: boolean = true;
  token: string = '';
  hapiConnectionCount?: number;
  hasActiveHeadsUp: boolean = false;

  ngOnInit() {
    this.localService.dashboardSummary.subscribe((response: DashboardSummary) => {
      this.hasActiveHeadsUp = response.hasActiveHeadsUp;
      this.providerView = response.providerView;
      this.token = response.hapiDataToken;
      this.hapiConnectionCount = response.connectionCount;
    });
  }

  toggleMinimenuDropdown() {
    this.showMinimenuDropdown = !this.showMinimenuDropdown;
    this.mobileMenuIsExpanded.emit(this.showMinimenuDropdown);
  }

  toggleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }
}
