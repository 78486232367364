<himn-spinner *ngIf="loading$ | async"></himn-spinner>
<div *ngIf="!(loading$ | async)" id="container">
   <div class="row">
      <div id="demographics">
         <fieldset class="fieldset feild-set-frame">
            <legend class="w-auto legend-frame">Patient Demographics</legend>
            <table style="width: 100%;">
               <tr>
                  <td class="col-width labels">Name</td>
                  <td>{{dashboardSummary.patientFirst}} {{dashboardSummary.patientLast}}</td>
               </tr>
               <tr>
                  <td class="col-width labels">Date of Birth</td>
                  <td>{{dashboardSummary.patientDob | date:'MM/dd/yyyy'}}</td>
               </tr>
               <tr>
                  <td class="col-width labels">Gender</td>
                  <td>{{dashboardSummary.patientGender}}</td>
               </tr>
               <tr>
                  <td class="col-width labels">Email</td>
                  <td>{{dashboardSummary.email}}</td>
               </tr>
               <tr>
                  <td class="col-width labels">Phone</td>
                  <td>{{dashboardSummary.cellPhone | mask: '000-000-0000'}}</td>
               </tr>
               <tr>
                  <td class="col-width labels" style="vertical-align:top;">Address</td>
                  <td>{{dashboardSummary.address1}}
                     <br>
                     {{dashboardSummary.city}} {{dashboardSummary.state}} {{dashboardSummary.zip}}
                  </td>
               </tr>
            </table>
         </fieldset>
      </div>
      <div id="recentActivity">
         <fieldset class="fieldset feild-set-frame">
            <legend class="w-auto legend-frame">Recent Activity</legend>
            <table>
               <tr *ngFor="let obj of recentActivityList">
                  <td>{{obj.displayLabel}} </td>
                  <td>- {{obj.recentChangeOn | date:'MM/dd/yyyy'}}</td>
               </tr>
               <tr *ngIf="healthRecordImportOn">
                  <td>Health Record import</td>
                  <td>- {{healthRecordImportOn | date:'MM/dd/yyyy'}}</td>
               </tr>
               <tr *ngIf="recentActivityList.length == 0 && !healthRecordImportOn">
                  <label>No recent activity found.</label>
               </tr>
            </table>
         </fieldset>
      </div>
   </div>
</div>