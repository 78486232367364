import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class NoInterceptorService {

  private http: HttpClient;
  environment: any;
  baseUrl: string = '';
  accessId: string | null = null;

  constructor( 
    handler: HttpBackend, 
    @Inject('environment') environment: any) { 
     this.http = new HttpClient(handler);
     this.environment = environment;
     this.baseUrl = this.environment.apiUrl;
  }

  public checkBlacklist() {
    this.accessId = sessionStorage.getItem(Constants.SESSION_AUTH_ACCESS);

    let headers = new HttpHeaders({
      'accessId': this.accessId!
    });
    let options = { headers: headers };

    const url = 'CheckBlacklist';
    return this.http.get(this.baseUrl + url, options);
  }
}
