import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, takeUntil } from "rxjs";
import { HttpCancelService } from "../services/http-cancel.service";

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
  constructor(private httpCancelService: HttpCancelService) { }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // filter out requests that we want to run in the background regardless of page changes
    if (!req.url.includes('hie/logHieData') && !req.url.includes('hie/logHieLabData')
          && !req.url.includes('encounter/count') && !req.url.includes('IsHeadsUpAlreadyOpened'))
      return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))

    return next.handle(req);
  }
}
