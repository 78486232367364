import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService } from '../../../services/loading.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { StringManipService } from '../../../services/string-manip.service';

@Component({
  selector: 'lib-hg-vital-signs',
  templateUrl: './hg-vital-signs.component.html',
  styleUrls: ['./hg-vital-signs.component.scss']
})
export class HgVitalSignsComponent implements OnInit {

  loading$ = this.loader.loading$;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'date':
            return this.stringUtil.trimAndLower(item.effectiveDate?.toString());
          case 'bodyWeight':
            return this.stringUtil.trimAndLower(item.bodyWeight?.toString());
          case 'bodyHeight':
            return this.stringUtil.trimAndLower(item.bodyHeight?.toString());
          case 'temp':
            return this.stringUtil.trimAndLower(item.temp?.toString());
          case 'hr':
            return this.stringUtil.trimAndLower(item.hr?.toString());
          case 'bmi':
            return this.stringUtil.trimAndLower(item.bmi?.toString());
          case 'spO':
            return this.stringUtil.trimAndLower(item.spO?.toString());
          case 'bp':
            return this.stringUtil.trimAndLower(item.systolic?.toString());
          default:
            return '';
        }
      };
      this.dataSource.sort = sort;
    }

    this.dataSource.sort = sort;
  }
  tableColumns: string[] = ['date', 'bodyWeight', 'bodyHeight', 'bp', 'temp', 'hr', 'bmi', 'spO'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  currentPage: number = 0;
  itemsPerPage: number = 10;

  constructor(private pdService: PatientDashboardService,
    private loader: LoadingService,
    private stringUtil: StringManipService) { }

  ngOnInit(): void {
    this.loadVitalSigns();
  }

  loadVitalSigns() {
    this.pdService.getVitalSigns().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource<Vitals>(data);
      this.dataSource.paginator = this.paginator;
    });
  }


  applyFilter(event: Event) {
    this.dataSource.filter = (event as unknown as String).trim().toLowerCase();
  }

  trackIndex(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
  }



}


export interface Vitals {
  bmi: string,
  bodyHeight: string,
  bodyWeight: string,
  hr: number,
  diastolic: number,
  systolic: number,
  temp: string,
  spO: string,
  effectiveDate: string
}
