import { NgModule } from '@angular/core';
import { PatientDashboardComponent } from './patient-dashboard.component';
import { PatientDashboardRoutingModule } from './patient-dashboard-routing.module';
import { MedicationsComponent } from './components/medications/medications.component';
import { DiagnosesComponent } from './components/diagnoses/diagnoses.component';
import { ImmunizationsComponent } from './components/immunizations/immunizations.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserModule } from '@angular/platform-browser';
import { NavComponent } from './shared/nav/nav.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AllergiesComponent } from './components/allergies/allergies.component';
import { ConnectedDevicesComponent } from './components/connected-devices/connected-devices.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { EncountersComponent } from './components/encounters/encounters.component';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { DiagnosesDialogComponent } from './components/dialogs/diagnoses-dialog/diagnoses-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { FlyoutComponent } from './shared/flyout/flyout.component';
import { AddFileUploadComponent } from './components/file-upload/add-file-upload/add-file-upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SafeUrlPipe } from './shared/pipes/safeurl.pipe';
import { NavLinksComponent } from './shared/nav/nav-links/nav-links.component';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { MedicalRecordsComponent } from './components/medical-records/medical-records.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { EncounterNotesComponent } from './components/encounter-notes/encounter-notes.component';
import { AddEncounterNotesComponent } from './components/encounter-notes/add-encounter-notes/add-encounter-notes.component';
import { AutosizeModule } from 'ngx-autosize';
import { EncounterNoteMedicationComponent } from './components/encounter-notes/encounter-note-medication/encounter-note-medication.component';
import { ModelComponent } from './shared/model/model.component';
import { AddEncounterNoteMedicationComponent } from './components/encounter-notes/add-encounter-note-medication/add-encounter-note-medication.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { HgEncountersComponent } from './components/health-gorilla/hg-encounters/hg-encounters.component';
import { HgImmunizationsComponent } from './components/health-gorilla/hg-immunizations/hg-immunizations.component';
import { HgMedicationsComponent } from './components/health-gorilla/hg-medications/hg-medications.component';
import { HgDiagnosesComponent } from './components/health-gorilla/hg-diagnoses/hg-diagnoses.component';
import { HgAllergiesComponent } from './components/health-gorilla/hg-allergies/hg-allergies.component';
import { HieRecordsComponent } from './components/health-gorilla/hie-records/hie-records.component';
import { HieRequestComponent } from './components/health-gorilla/hie-records/hie-request/hie-request.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HiePatientmatchComponent } from './components/dialogs/hie-patientmatch/hie-patientmatch.component';

import { CdkColumnDef } from '@angular/cdk/table';
import { GlobalErrorComponent } from './shared/global-error/global-error.component';
import { HttpCancelInterceptor } from './interceptors/http-cancel.interceptor';
import { HgDocumentsComponent } from './components/health-gorilla/hg-documents/hg-documents.component';
import { DocumentsComponent } from './components/health-gorilla/hg-documents/documents/documents.component';
import { HgFullccdaComponent } from './components/health-gorilla/hg-fullccda/hg-fullccda.component';
import { HgSlideOutComponent } from './components/health-gorilla/hg-documents/hg-slide-out/hg-slide-out.component';
import { DocSlideMenuComponent } from './components/health-gorilla/hg-documents/doc-slide-menu/doc-slide-menu.component';
import { HgLabsComponent } from './components/health-gorilla/hg-labs/hg-labs.component';
import { LabsComponent } from './components/health-gorilla/hg-labs/labs/labs.component';
import { LabsSlideMenuComponent } from './components/health-gorilla/hg-labs/labs-slide-menu/labs-slide-menu.component';
import { HgVitalSignsComponent } from './components/health-gorilla/hg-vital-signs/hg-vital-signs.component';
import { PatientProfileComponent } from './components/patient-profile/patient-profile.component';

export const options: Partial<IConfig> | null | (() => Partial<IConfig>) = null;

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 1000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@NgModule({
  declarations: [
    PatientDashboardComponent,
    ConnectedDevicesComponent,
    EncountersComponent,
    ImmunizationsComponent,
    MedicationsComponent,
    DiagnosesComponent,
    AllergiesComponent,
    FileUploadComponent,
    NavComponent,
    SpinnerComponent,
    DiagnosesDialogComponent,
    FlyoutComponent,
    AddFileUploadComponent,
    SafeUrlPipe,
    NavLinksComponent,
    MedicalRecordsComponent,
    DashboardComponent,
    LogoutComponent,
    EncounterNotesComponent,
    AddEncounterNotesComponent,
    EncounterNoteMedicationComponent,
    ModelComponent,
    AddEncounterNoteMedicationComponent,
    HgEncountersComponent,
    HgImmunizationsComponent,
    HgMedicationsComponent,
    HgDiagnosesComponent,
    HgAllergiesComponent,
    HieRecordsComponent,
    HieRequestComponent,
    HiePatientmatchComponent,
    GlobalErrorComponent,
    HgDocumentsComponent,
    DocumentsComponent,
    HgFullccdaComponent,
    HgSlideOutComponent,
    DocSlideMenuComponent,
    HgLabsComponent,
    LabsComponent,
    LabsSlideMenuComponent,
    HgVitalSignsComponent,
    PatientProfileComponent
  ],
  imports: [
    PatientDashboardRoutingModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    BrowserModule,
    CommonModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatTooltipModule,
    MatGridListModule,
    MatDividerModule,
    MatCheckboxModule,
    AutosizeModule,
    ReactiveFormsModule,
    NotifierModule.withConfig(customNotifierOptions),
    MatProgressSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  exports: [
    PatientDashboardComponent,
    LogoutComponent
  ],
  entryComponents: [DiagnosesDialogComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpCancelInterceptor,
    multi: true
  }, DatePipe, CdkColumnDef]
})
export class PatientDashboardModule { }
