import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from 'projects/components/src/lib/services/loading.service';
import { PatientDashboardService } from 'projects/components/src/lib/services/patient-dashboard.service';

@Component({
  selector: 'lib-labs-slide-menu',
  templateUrl: './labs-slide-menu.component.html',
  styleUrls: ['./labs-slide-menu.component.scss']
})
export class LabsSlideMenuComponent implements OnInit {

  constructor(
    public loader: LoadingService,
    private pdService: PatientDashboardService
  ) { }

  @Output() loadLabContent = new EventEmitter();
  @Input() labsList: any[] = [];
  isFlyoutVisible = false;
  loading$ = this.loader.loading$;
  selectedLab = 0;

  ngOnInit(): void {
    
  }

  loadLab(observations: Array<string>, selectedIndex: number) {
    this.selectedLab = selectedIndex;

    this.pdService.getHieLabSummary(observations).subscribe((response: any) => { 
      this.loadLabContent.emit(response);
    }); 
  }
}
