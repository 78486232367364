<himn-spinner *ngIf="loading$ | async"></himn-spinner>

<ng-container *ngIf="!(loading$ | async)">

  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input (keyup)="applyFilter($any($event.target).value)" matInput placeholder="Filter">
  </mat-form-field>

  <mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let vitals">{{vitals.effectiveDate | date:'MM-dd-yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bodyWeight">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Weight</mat-header-cell>
      <mat-cell *matCellDef="let vitals">{{vitals.bodyWeight || '--'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bodyHeight">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Height</mat-header-cell>
      <mat-cell *matCellDef="let vitals">{{vitals.bodyHeight || '--'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bp">
      <mat-header-cell *matHeaderCellDef mat-sort-header>BP(mmHG)</mat-header-cell>
      <mat-cell *matCellDef="let vitals">
        <span *ngIf="vitals.systolic">{{vitals.systolic}}</span>
        <span *ngIf="vitals.diastolic">/{{vitals.diastolic}}</span>
        <span *ngIf="!vitals.systolic && !vitals.diastolic">--</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="temp">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Temp</mat-header-cell>
      <mat-cell *matCellDef="let vitals">{{vitals.temp || '--'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="hr">
      <mat-header-cell *matHeaderCellDef mat-sort-header>HR</mat-header-cell>
      <mat-cell *matCellDef="let vitals">{{vitals.hr || '--'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bmi">
        <mat-header-cell *matHeaderCellDef mat-sort-header>BMI(Kg/m<sup>2</sup>)</mat-header-cell>
        <mat-cell *matCellDef="let vitals">{{vitals.bmi || '--'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="spO">
        <mat-header-cell *matHeaderCellDef mat-sort-header>SpO<sub>2</sub>(%) </mat-header-cell>
        <mat-cell *matCellDef="let vitals">{{vitals.spO || '--'}}</mat-cell>
      </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="999" style="vertical-align: middle;">No data to display</td>
    </tr>

    <mat-header-row *matHeaderRowDef="tableColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

  </mat-table>
</ng-container>

<div [hidden]="loading$ | async">
  <mat-paginator [length]="100" [pageSize]="10" 
    [pageSizeOptions]="[5, 10, 25, 50]" aria-label="Select page">
  </mat-paginator>
</div>