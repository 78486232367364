import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import { DashboardSummary } from '../../models/dashboard-summary';
import { LoadingService } from '../../services/loading.service';
import { LocalDataService } from '../../services/local-data.service';
import { PatientDashboardService } from '../../services/patient-dashboard.service';
import { SharedService } from '../../services/sharedservice.service';


@Component({
  selector: 'lib-encounter-notes',
  templateUrl: './encounter-notes.component.html',
  styleUrls: ['./encounter-notes.component.scss',
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})

export class EncounterNotesComponent implements OnInit {
  @ViewChild(MatSort)
  sort!: MatSort;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild('addEncounterNote', { static: false }) addEncounterNotes: any;
  @ViewChild('confirmPopup', { static: false }) confirmPopup: any;


  loading$ = this.loader.loading$;
  tableColumns: string[] = ['visitDate', 'encounterType', 'providerName', 'statusType', 'jsonIcon', 'editIcon', 'pdfIcon'];
  dataSource = new MatTableDataSource();
  public displayAddNotes: boolean = false;
  public selectedEnconterNoteId: number = 0;
  public loadingComplete = false;
  public currentPage = 0;
  public itemsPerPage = 10;
  public userRoles: string[] = [];
  public encounterTypes: any[] = [];
  public modalityTypes: any[] = []
  public statusTypes: any[] = [];
  public patientName: string = '';
  public visitDate: any
  public timeSpend: any
  public selectedModalityType: any
  public selectedEncounterType: any

  public showPdfIcon: any
  public finalizedBy: any
  public providerNpi: any
  public encounterNoteSubjective: any
  public encounterNoteObjective: any
  public encounterNoteProblems: any[] = []
  public medications: any[] = []
  public selectedNote: any;
  public openUnsavedDraft = false;
  public selectedUnsavedJson: any;

  constructor(private pdService: PatientDashboardService,
    private sharedService: SharedService,
    private localService: LocalDataService,
    private loader: LoadingService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.sharedService.isEncounterNoteAdd = false;
    this.localService.authInfo.subscribe(response => {
      this.getUserRoles(response);
    });
    this.loadListData();
    //this.getEncounterTypes();
  }

  getUserRoles(auth: any) {
    this.pdService.getUserRoles(auth.requestorId).subscribe((roles: any) => {
      this.userRoles = roles.data.userRoles;
    });
  }


  loadListData() {
    this.pdService.getEncounterNoteList().subscribe((res: any) => {
      console.log(res)
      var noteList: any[] = res.data.noteList;
      var unsavedDrafts: any[] = res.data.unsavedDrafts;
      var encounterTypes: any[] = res.data.types.encounterTypes;
      var statusTypes: any[] = res.data.types.statusTypes;
      unsavedDrafts.forEach(gnote => {
        var note = JSON.parse(gnote.json);
        var json = {
          encounterNoteId: 0,
          encounterType: note.encounterTypeId != null ? encounterTypes.filter(m => { return m.typeMasterId == note.encounterTypeId })[0].typeName : null,
          encounterTypeId: 0,
          genericJsonId: note.genericJsonId,
          isUnsavedDraft: true,
          patientUserId: gnote.patientUserId,
          providerName: gnote.providerName,
          providerUserId: gnote.providerUserId,
          statusType: note.statusTypeId != null ? statusTypes.filter(m => { return m.typeMasterId == note.statusTypeId })[0].typeName : null,
          visitDate: note.visitDate,
          note: gnote
        }
        console.log(json, 'json draft')
        noteList.push(json);
      })
      this.dataSource = new MatTableDataSource(noteList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  CreateEncounterNotes() {
    this.selectedEnconterNoteId = 0;
    this.selectedUnsavedJson = null;
    this.displayAddNotes = !this.displayAddNotes;
    if (!this.displayAddNotes) {
      this.loadListData();
    }
  }

  editEncounterNote(note: any) {
    if (note.genericJsonId == 0) {
      this.selectedEnconterNoteId = note.encounterNoteId;
      this.displayAddNotes = !this.displayAddNotes;
    }
    else {
      this.selectedNote = note;
      this.confirmPopup.open();
    }
  }
  trackIndex(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
  }

  getEncounterNotePdf(encounterNoteId: any) {
    this.pdService.getEncounterNoteDetails(encounterNoteId, false).subscribe((note: any) => {
      //console.log(note, "note")
      var encounterNote = note.data.encounterNoteDTO;
      this.visitDate = formatDate(encounterNote.visitDate, 'yyyy-MM-dd', 'en');;
      this.timeSpend = (Number)(encounterNote.timeSpent);
      this.selectedModalityType = encounterNote.visitTypeId != null ? this.modalityTypes.filter(m => { return m.typeMasterId == encounterNote.visitTypeId })[0] : null;
      this.selectedEncounterType = encounterNote.encounterTypeId != null ? this.encounterTypes.filter(m => { return m.typeMasterId == encounterNote.encounterTypeId })[0] : null;
      this.finalizedBy = note.data.encounterNoteDTO.updatedByName != null ? note.data.encounterNoteDTO.updatedByName : '';
      this.providerNpi = note.data.encounterNoteDTO.npi ? note.data.encounterNoteDTO.npi : '';
      this.encounterNoteSubjective = encounterNote.encounterNoteSubjective;
      this.encounterNoteObjective = encounterNote.encounterNoteObjective;
      this.encounterNoteProblems = encounterNote.encounterNoteProblems;
      this.medications = encounterNote.encounterNoteMedications;
      this.exportPdf();
    });
  }

  getEncounterTypes() {
    this.pdService.getEncounterTypes().subscribe((result: any) => {
      this.encounterTypes = result.data.encounterTypes;
      this.modalityTypes = result.data.modalityTypes;
      this.statusTypes = result.data.statusTypes;
    });
  }

  exportPdf() {
    var today = new Date();
    var newdate = " " + this.datepipe.transform(today, 'MM/dd/yyyy hh:mm a');
    var fileName = 'EC_' + this.datepipe.transform(new Date(), 'MM-dd-yyyy hh:mm:ss');
    var img = new Image();
    var doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [595.28, 841.89]
    })
    const div = this.getHtml();
    doc.html(div, {
      callback: function (pdf) {
        let totalPages = pdf.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor('#5d707b');
          pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 30);
        }
        img.src = '../../../../assets/images/parawell-logo-color.png';
        pdf.setFontSize(8);
        pdf.setTextColor('#5d707b');
        pdf.text(newdate, 50, pdf.internal.pageSize.getHeight() - 30);
        pdf.setFontSize(15);
        pdf.setFont('', 'italic');
        pdf.text('Powered By:', pdf.internal.pageSize.getWidth() - 190, pdf.internal.pageSize.getHeight() - 30);
        pdf.addImage(img, 'png', pdf.internal.pageSize.getWidth() - 130, pdf.internal.pageSize.getHeight() - 50, 120, 30);//left,top,width,height

        pdf.save(fileName);
      },
      margin: [40, 30, 40, 60],
      autoPaging: "text",
    });
  }

  getHtml() {
    var dashboardSummary = new DashboardSummary();
    this.localService.dashboardSummary.subscribe((response: DashboardSummary) => {
      dashboardSummary = response;
      this.patientName = response.patientFirst + ' ' + response.patientLast;
      dashboardSummary.practiceName
    })
    var selectedModalityType = this.selectedModalityType != null ? this.selectedModalityType.typeName : '';
    var selectedEncounterType = this.selectedEncounterType != null ? this.selectedEncounterType.typeName : '';
    var tab_text = `<div id="PdfDiv">
    <div style="width:500px;">
      <div style="padding-bottom:10px">
        <label style="color: #5d707b;font-size:1.5em;">`+ dashboardSummary.practiceName + `</label>
      </div>
      <div>
        <div style="display: flex;">
          <div style="flex: 1; background-color: #f0ecec; width: 50%;">
            <table style="font-size: 8px;background-color:#f0ecec;padding:5px;">
              <tr style="font-size:12px";>
                <td style="width:2%;line-height: 8px;vertical-align:top;font-weight: 600;"><span>Patient: </span></td>
                <td style="width:20%;line-height: 8px;"><label style="font-weight: 600;">` + dashboardSummary.patientFirst + ' ' + dashboardSummary.patientLast + `</label></td>
              </tr>
              <tr>
                <td style="width:2%;line-height: 8px;vertical-align:top;"><span>DOB: </span></td>
                <td style="width:20%;line-height: 8px;"><label>` + this.datepipe.transform(dashboardSummary.patientDob, 'MM/dd/yyyy') + `</label></td>      
              </tr>
              <tr>
                <td style="width:2%;line-height: 8px;vertical-align:top;"><span>Address: </span></td>
                <td style="width:20%;line-height: 8px;"><label>` + dashboardSummary.address1 + `<br>` + dashboardSummary.city + ` ` + dashboardSummary.state + ` ` + dashboardSummary.zip + `</label></td>     
              </tr>      
            </table>
          </div>
          <div style=" flex: 1; background-color: #f0ecec; margin-left: 10px;width: 50%;">
            <table style="font-size: 8px;background-color:#f0ecec;padding:5px">
              <tr style="font-size:12px";>
                <td style="width:2%;line-height: 8px;vertical-align:top;font-weight: 600;"><span>Provider: </span></td>
                <td style="width:20%;line-height: 8px;"><label style="font-weight: 600;">` + this.finalizedBy + `</label></td>
              </tr>
              <tr>
                <td style="width:2%;line-height: 8px;vertical-align:top;"><span>NPI: </span></td>
                <td style="width:20%;line-height: 8px;"><label>` + this.providerNpi + `</label></td>      
              </tr>     
            </table>
          </div>
        </div>

        <table style="font-size: 8px;padding:5px">
          <tr>
            <td style="width:6%;line-height: normal;"><span>Visit Date:</span></td>
            <td style="width:22%;line-height: normal;padding-left:1px"><label>` + this.datepipe.transform(this.visitDate, 'MM-dd-yyyy') + `</label></td>
            <td style="width:6%;line-height: normal;padding-left:10px"><label>Time Spent:</label></td>
            <td style="width:20%;line-height: normal;padding-left:1px"><label>` + this.timeSpend + ` Minutes</label></td>
          </tr>
          <tr>
            <td style="width:6%;line-height: normal;"><label>Modality:</label></td>
            <td style="width:22%;line-height: normal;padding-left:1px"><label>` + selectedModalityType + `</label></td>
            <td style="width:6%;line-height: normal;padding-left:10px"><label>Encounter Type:</label></td>
            <td style="width:20%;line-height: normal;padding-left:1px"><label>` + selectedEncounterType + `</label></td>
          </tr>              
        </table>
      </div>
        
      <div style="border-bottom: 0.5px solid grey"><span style="font-size:10px;">Subjective</span></div>
      
      <div formGroupName="encounterNoteSubjective">
        <table style="width:100%;font-size:8px">
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><span>Chief Complaint (CC) :</span></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.chiefComplaint + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><label>History of Present Illness (HPI) :</label></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.hpi + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><span>Past Medical History (PMH) : </span></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.pmh + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><label>Social History (SH) : </label></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.socialHistory + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><label>Family History (FH) :</label></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.familyHistory + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><label>Review of Systems (ROS) : </label></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.ros + `</label></td>
          </tr>          
        </table>
      </div>
    
      <div style="border-bottom: 0.5px solid grey"><span style="font-size:10px;">Objective</span></div>
    
      <div>
        <span style="font-size: 8px;padding-left:3px">Medications :</span>
      </div>
  
      <div>
        <table style="font-size: 8px;">
          <tr>
            <th style="width:10%;text-align:left;line-height: normal">Name and Strength</th>
            <th style="width:10%;text-align:left;line-height: normal">Start Date</th>
            <th style="width:20%;text-align:left;line-height: normal">Directions</th>        
          </tr>`

    if (this.medications.length > 0) {
      for (let i = 0; i < this.medications.length; i++) {
        tab_text = tab_text + `<tr>
              <td style="width:10%;line-height: normal">` + this.medications[i].medicationName + `</td>
              <td style="width:10%;line-height: normal">` + this.medications[i].startDate + `</td>
              <td style="width:20%;line-height: normal">`+ this.medications[i].directions + `</td>
              </tr>`
      }
    }
    else {
      tab_text = tab_text + `<tr>
            <td style="width:10%"> No records found</td></tr>`
    }

    tab_text = tab_text +
      `</table>
        </div>
        <br />
        <div>
          <table style="width:100%;font-size:8px;">
            <tr>
              <td style="width: 20%;line-height: normal;vertical-align:top;"><label>Allergies :</label></td>
              <td style="line-height: normal;"><label>` + this.encounterNoteObjective.allergies + `</label></td>
            </tr>
          </table>
        </div>
  
        <div>
          <table style="width:100%;font-size:8px;">
            <tr>
              <td style="width: 20%"><label>Vital Signs :</label></td>
              <td><label>BP : `+ this.encounterNoteObjective.systolic + `/` + this.encounterNoteObjective.diastolic + ` mmHg</label></td>
              <td><label>RR : `+ this.encounterNoteObjective.rr + ` BPM</label></td>
              <td><label>HR  : `+ this.encounterNoteObjective.hr + ` BPM</label></td>
              <td><label>Temp : `+ this.encounterNoteObjective.temp + ` ºF</label></td>
            </tr>
            <tr>
              <td></td>
              <td><label>WT : `+ this.encounterNoteObjective.wt + ` lb</label></td>
              <td><label>HT : `+ this.encounterNoteObjective.ht + ` in</label></td>
              <td><label>BMI : `+ this.encounterNoteObjective.bmi + ` kg/m<sup>2</sup></label></td>  
              <td><label>SpO<sub>2</sub> : `+ this.encounterNoteObjective.o2 + ` %</label></td>
            </tr>           
          </table>
        </div>
  
      <div>
        <table style="width:100%;font-size:8px;">
          <tr>
            <td style="width: 20%;line-height: normal;vertical-align:top;"><span>Laboratory Values :</span></td>
            <td style="line-height: normal;"><label>` + this.encounterNoteObjective.labValues + `</label></td>
          </tr>
          <tr>
            <td style="width: 20%;line-height: normal;vertical-align:top;"><span>Immunization :</span></td>
            <td style="line-height: normal;"><label>` + this.encounterNoteObjective.immunization + `</label></td>
          </tr>  
        </table>
      </div>`

    for (let i = 0; i < this.encounterNoteProblems.length; i++) {
      tab_text = tab_text +
        `<div style="border-bottom: 0.5px solid grey">
              <span style="font-size:10px;">
                Problem
                <span style="font-style: italic;">(Assessment & Plan)</span>
              </span>
            </div>
          <table style="width: 100%;font-size:8px;">
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Problem :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].problem + `</label></td>
          </tr>
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Assessment :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].assessment + `</label></td>
          </tr>
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Treatment Plan :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].treatmentPlan + `</label></td>
          </tr>
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Education and Counseling :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].educationCounseling + `</label></td>
          </tr>
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Monitoring and Followup :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].monitoringFollowUp + `</label></td>
          </tr>
        </table>`
    }
    tab_text = tab_text + `</div></div>`
    return tab_text;
  }

  deleteGenericJson(genericId: number) {
    this.pdService.deleteAutoSaveEncounterNote(genericId
    )
      .subscribe((res: any) => {
        console.log(res)
      })
  }

  encounterNoteDraftHandler(action: boolean) {
    this.confirmPopup.close();
    if (action) {
      this.loadUnsavedDrafts(this.selectedNote);
    }
    else {
      this.deleteGenericJson(this.selectedNote.genericJsonId);
      this.selectedEnconterNoteId = this.selectedNote.encounterNoteId;
      this.displayAddNotes = !this.displayAddNotes;
    }
  }

  loadUnsavedDrafts(encounterNote: any) {
    if (encounterNote.encounterNoteId != 0) {
      this.openUnsavedDraft = true;
      this.selectedEnconterNoteId = encounterNote.encounterNoteId;
      this.displayAddNotes = !this.displayAddNotes;
    }
    else {
      this.selectedEnconterNoteId = 0;
      this.selectedUnsavedJson = encounterNote.note;
      this.displayAddNotes = !this.displayAddNotes;
      if (!this.displayAddNotes) {
        this.loadListData();
      }
    }
  }

}
