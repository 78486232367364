import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringManipService {
  

  constructor() {}

  public trimAndLower(sortString: string): string {
    let cleanString = '';
    if (sortString) {
      cleanString = sortString.trim();
    }
    return cleanString.toLowerCase();
  }
}