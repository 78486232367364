<div class="modal-container">
    <div class="modal-body modal-width" style="background-color: #ffffff;" [style.width.px]="width">
        <!-- <div>
            <div *ngIf="!isHideTitle" style="display: inline-block;">
                <h4 class="model-title">{{title}}</h4>
            </div>
            <div class="modal-close" *ngIf="!isHideCloseButton">
                <a (click)="close()">
                    <img src="../../../assets/images/close.png" alt="x" />
                </a>
            </div>
        </div> -->
        <div *ngIf="!isHideTitle" style="background-color: #A03B0D;height: 3rem">
            <div style="padding: 1rem;" class="flex-container">
                <span>
                    <span class="model-title">{{title}}</span>
                </span>
                <a (click)="close()" style="cursor: pointer;">
                    <img class="header--pd-logo" src="../../../../assets/images/close.svg" height="20px" />
                </a>
            </div>
        </div>
        <div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
<div class="modal-background"></div>