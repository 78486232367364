import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';

export class MedicationPickerList {
  endDate: string = '';
  instructions: string = "";
  name: string = '';
  startDate: string = '';
  isSelected: boolean = false;
}

@Component({
  selector: 'encounter-note-medication',
  templateUrl: './encounter-note-medication.component.html',
  styleUrls: ['./encounter-note-medication.component.scss']
})
export class EncounterNoteMedicationComponent implements OnInit {

  @Output() closePopup = new EventEmitter();
  @Output() includeMedicinePopupEvent = new EventEmitter<any>();
  @Input() hasHealthGorillaFeatures: boolean = false;
  @Input() medicationList: MedicationPickerList[] = [];

  //public medicationList: MedicationPickerList[] = [];
  public customMedications: any[] = [];
  public hgMedications: any[] = [];
  public listResultLabel = 'No data to display.';


  constructor(private pdService: PatientDashboardService,
    private localService: LocalDataService) { }

  ngOnInit(): void {
    
  }


  closeMedicinePopup() {
    this.closePopup.emit();
  }

  includeMedicineList() {
    var filteredList: any[] = [];
    this.medicationList.filter(x => {
      if (x.isSelected == true) {
        filteredList.push({ medicationName: x.name, startDate: x.startDate, endDate: x.endDate, directions: x.instructions });
      }
    });
    this.includeMedicinePopupEvent.emit(filteredList);
    this.medicationList.forEach(element => {
      element.isSelected = false;
    });
  }

}
