import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService } from '../../../services/loading.service';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { StringManipService } from '../../../services/string-manip.service';

@Component({
  selector: 'lib-hg-diagnoses',
  templateUrl: './hg-diagnoses.component.html',
  styleUrls: ['./hg-diagnoses.component.scss']
})
export class HgDiagnosesComponent implements OnInit {
  loading$ = this.loader.loading$;

  constructor(
    private pdService: PatientDashboardService,
    private stringUtil: StringManipService,
    private loader: LoadingService
  ) { }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'description':
            return this.stringUtil.trimAndLower(item.description?.toString());
          case 'recordedDate':
            return this.stringUtil.trimAndLower(item.recordedDate?.toString());
          case 'icdCode':
            return this.stringUtil.trimAndLower(item.icdCode?.toString());
          default:
            return '';
        }
      };
      this.dataSource.sort = sort;
    }

    this.dataSource.sort = sort;
  }

  tableColumns : string[] = ['description', 'recordedDate', 'icdCode'];
  dataSource: MatTableDataSource<Diagnosis> = new MatTableDataSource<Diagnosis>([]);

  ngOnInit(): void {
    this.pdService.getHieDiagnoses().subscribe((data: any) => {
      if (data !== null) {
        this.dataSource = new MatTableDataSource<Diagnosis>(data);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  applyFilter(event: Event) {
    this.dataSource.filter = (event as unknown as String).trim().toLowerCase();
  }
}

export interface Diagnosis {
  description: string,
  recordedDate: string,
  icdCode: string
}