import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'lib-hg-labs',
  templateUrl: './hg-labs.component.html',
  styleUrls: ['./hg-labs.component.scss']
})
export class HgLabsComponent implements OnInit {

  constructor(public loader: LoadingService) { }

  loading$ = this.loader.loading$;

  ngOnInit(): void {
  }

}
