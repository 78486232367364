<nav class="minified-menu" *ngIf="!router.isActive('/dashboard/(content:connected-devices)', false)">
  <label for="menutrigger" class="minimenu-burger"
    [ngClass]="[showMinimenuDropdown === true ? 'opened' : 'closed']"  
    (click)="toggleMinimenuDropdown()">
      <div class="spinner diagonal part-1"></div>
      <div class="spinner horizontal"></div>
      <div class="spinner diagonal part-2"></div> 
  </label>
  <menu class="minimenu-dropdown" *ngIf="showMinimenuDropdown" [@slideInOutHorizontal]>
    <li style="height: 50px; pointer-events: none; list-style: none;"></li>
    <lib-nav-links (click)="toggleMinimenuDropdown()"></lib-nav-links>
    <footer class="himn-powered">
      <span>Powered by</span>
      <br />
      <img src="../../../../assets/images/himn-logo.png" />
    </footer>
  </menu>
</nav>

<nav class="expanded-menu">
  <menu [@slideInOutHorizontal] style="max-height: 100%; overflow: auto;">
    <lib-nav-links></lib-nav-links>
  </menu>
  <footer class="himn-powered">
    <span>Powered by</span>
    <br />
    <img src="../../../../assets/images/himn-logo.png" />
  </footer>
</nav>
