import { Component, OnInit } from '@angular/core';
import { SharedService } from 'projects/components/src/lib/services/sharedservice.service';

@Component({
  selector: 'lib-slide-out',
  templateUrl: './hg-slide-out.component.html',
  styleUrls: ['./hg-slide-out.component.scss']
})
export class HgSlideOutComponent implements OnInit {

  isFlyoutVisible = false;
  isBackButtonVisible = false;
  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
  }

  onClickFlyout() {
    if (this.isFlyoutVisible) {
      this.isFlyoutVisible = false;
      this.isBackButtonVisible = false;
    }
    else {
      this.isFlyoutVisible = true;
      setTimeout(() => { this.isBackButtonVisible = true }, 1000);
    }
  }

}
