import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'modal',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModelComponent implements OnInit, OnDestroy {

  @Input('title') title: string = '';
  @Input('isHideTitle') isHideTitle: boolean = false;
  //@Input('isHideCloseButton') isHideCloseButton: boolean = false;
  //@Input('isFlyoutOpen') isFlyoutOpen: boolean = false;
  @Input('width') width: number = 300;
  private element: any;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    document.body.appendChild(this.element);

    // close modal on background click
    // this.element.addEventListener('click', el => {
    //     if (el.target.className === 'ra-modal') {
    //         this.close();
    //     }
    // });
  }

  // remove self from modal when component is destroyed
  ngOnDestroy(): void {
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('ra-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('ra-modal-open');
  }

}
