import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApplicationRef, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { SharedService } from '../../../services/sharedservice.service';
import { Constants } from '../../../shared/constants';

export class CreateFileUpload {
  file = File;
  displayName: string = '';
  description: string = '';
  patientFileTags: string = '';
  isAvailableToPatient: boolean = false;
  fileName: string = '';
  fileType: string = '';
  tags: string = '';
  fileSize: string = '';
}

@Component({
  selector: 'himn-patient-dashboard-file-upload',
  templateUrl: './add-file-upload.component.html',
  styleUrls: ['./add-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})


export class AddFileUploadComponent implements OnInit {

  @Output() flyoutclose = new EventEmitter();
  @Output() listrefresh = new EventEmitter();
  @ViewChild('flyout') flyout: any;

  public fileTags: any[] = [];
  public loading = false;
  public addFile: CreateFileUpload = new CreateFileUpload();
  public buttonText: string = 'Save';
  public status = false;
  public message: string = '';
  public showMessage: boolean = false;
  public isEdit: boolean = false;
  public patientFileId: any;
  public buttonClick: boolean = false;
  public loadingComplete: boolean = true;
  private practiceId: number = 0;

  constructor(
    private pdService: PatientDashboardService,
    private appRef: ApplicationRef,
    private dialog: MatDialog,
    private http: HttpClient,
    private sharedService: SharedService,
    private notifier: NotifierService,
    private localService: LocalDataService
  ) { }

  ngOnInit(): void {
    this.getTagList();
  }

  getTagList() {
    this.pdService.getFilesTags().subscribe((response: any) => {
      this.fileTags = response.data.tagMasters;
    });
  }

  getFileDetails(patientFileList: any) {
    this.addFile = new CreateFileUpload();
    this.showMessage = false;
    this.buttonText = 'Update';
    this.isEdit = true;
    this.addFile = patientFileList;
    this.addFile.patientFileTags = patientFileList.tags;
  }

  addFileTag(name: any) {
    return new Promise((resolve) => {
      this.loading = true;
      setTimeout(() => {
        resolve({
          name: name,
          tagMasterId: 0,
        });
        this.loading = false;
      }, 1000);
    })
  }

  validate() {
    let isValid = true;
    this.message = '';
    if ((!this.addFile.fileName) && !this.isEdit) {
      this.message += '**File Name is required<br/>';
      isValid = false;
    }
    if (!this.addFile.displayName || this.addFile.displayName === '') {
      this.message += '**Display Name is required<br/>';
      isValid = false;
    }
    if (this.addFile.fileSize > '5242880') {
      this.message += '**File upload error : This file could not be saved beacuse it exceeds 5 MB, the maximum allowed size for uploads<br/>';
      isValid = false;
    }
    return isValid;
  }

  saveFile() {
    this.practiceId = Number(sessionStorage.getItem(Constants.SESSION_PRACTICE_ID));

    var addFileFormData: any = new FormData();
    addFileFormData.append('file', this.addFile.file);
    addFileFormData.append('fileType', this.addFile.fileType);
    addFileFormData.append('displayName', this.addFile.displayName);
    addFileFormData.append('description', this.addFile.description);
    addFileFormData.append('patientFileTags', JSON.stringify(this.addFile.patientFileTags));
    addFileFormData.append('isAvailableToPatient', this.addFile.isAvailableToPatient);
    addFileFormData.append('practiceId', this.practiceId);
    if (this.validate()) {
      this.showMessage = false;
      this.buttonClick = true;
      if (!this.isEdit) {
        this.loadingComplete = false;
        this.pdService.uploadFile(addFileFormData).subscribe((data: any) => {
          if (data) {
            if (data.success) {
              this.showMessage = true;
              this.message = 'Patient Saved successfully!';
              this.status = true;
              this.closeFlyout();
              this.notifier.notify('success', 'Saved successfully!');
              this.listrefresh.emit();
              this.sharedService.fileCountChange.next(true);
            }
            else {
              this.buttonClick = false;
              this.showMessage = true;
              this.status = false;
              this.message = data.error.message;
              this.loadingComplete = true;
            }
          }
        }, (err: HttpErrorResponse) => {
          this.buttonClick = false;
          this.showMessage = true;
          this.status = false;
          if (err.error.message === '' || err.error.message == undefined) {
            this.message = "**File upload error : Sorry something went wrong please try again later"
          }
          else {
            this.message = err.error.message;
          }
          setTimeout(() => {
            document.getElementById('alertMessage')?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
          }, 0);
          this.loadingComplete = true;
        });

      }
      else {
        this.loadingComplete = false;
        this.addFile.tags = this.addFile.patientFileTags;
        this.pdService.editUploadFile(this.addFile).subscribe((data: any) => {
          if (data) {
            if (data.success) {
              this.showMessage = true;
              this.status = true;
              this.message = 'Patient File  updated successfully!';
              this.closeFlyout();
              this.notifier.notify('success', 'Updated successfully!');
              this.listrefresh.emit();
            } else {
              this.buttonClick = false;
              this.showMessage = true;
              this.status = false;
              this.message = data.error.message;
              this.loadingComplete = true;
            }
          }
        },
          (err: HttpErrorResponse) => {
            this.showMessage = true;
            this.status = false;
            this.message = err.error.message;
            setTimeout(() => {
              document.getElementById('alertMessage')?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            }, 0);
            this.loadingComplete = true;
          });
      }
      this.sharedService.fileCountChange.next(true);
      this.getTagList();
    }
    else {
      this.status = false;
      this.showMessage = true;
      setTimeout(() => {
        document.getElementById('alertMessage')?.scrollIntoView({ block: 'end', inline: 'nearest' })
      }, 0);
      this.loadingComplete = true;
    }
  }

  fileOnChange(files: any, e: any): void {
    if (files && files[0]) {
      this.addFile.fileSize = files[0].size;
      this.addFile.fileName = files[0].name;
      this.addFile.file = files[0];
      this.addFile.fileType = files[0].type;
    }
  }

  closeFlyout(): void {
    this.loadingComplete = true;
    this.clearForm();
    this.flyoutclose.emit();
    this.message = '';
    //this.listrefresh.emit();
  }

  clearForm(): void {
    this.isEdit = false;
    this.buttonText = "Save";
    this.buttonClick = false;
    this.addFile = new CreateFileUpload();
    this.showMessage = false;
  }

}


