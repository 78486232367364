import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { MedicationsComponent } from './components/medications/medications.component';
import { DiagnosesComponent } from './components/diagnoses/diagnoses.component';
import { ImmunizationsComponent } from './components/immunizations/immunizations.component';
import { AllergiesComponent } from './components/allergies/allergies.component';
import { ConnectedDevicesComponent } from './components/connected-devices/connected-devices.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { EncountersComponent } from './components/encounters/encounters.component';
import { RouterTestingModule } from '@angular/router/testing';
import { MedicalRecordsComponent } from './components/medical-records/medical-records.component';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { DashboardSummary } from './models/dashboard-summary';
import { EncounterNotesComponent } from './components/encounter-notes/encounter-notes.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { HgEncountersComponent } from './components/health-gorilla/hg-encounters/hg-encounters.component';
import { HgImmunizationsComponent } from './components/health-gorilla/hg-immunizations/hg-immunizations.component';
import { HgMedicationsComponent } from './components/health-gorilla/hg-medications/hg-medications.component';
import { HgDiagnosesComponent } from './components/health-gorilla/hg-diagnoses/hg-diagnoses.component';
import { HgAllergiesComponent } from './components/health-gorilla/hg-allergies/hg-allergies.component';
import { HieRecordsComponent } from './components/health-gorilla/hie-records/hie-records.component';
import { GlobalErrorComponent } from './shared/global-error/global-error.component';
import { HgDocumentsComponent } from './components/health-gorilla/hg-documents/hg-documents.component';
import { DocumentsComponent } from './components/health-gorilla/hg-documents/documents/documents.component';
import { HgFullccdaComponent } from './components/health-gorilla/hg-fullccda/hg-fullccda.component';
import { HgLabsComponent } from './components/health-gorilla/hg-labs/hg-labs.component';
import { HgVitalSignsComponent } from './components/health-gorilla/hg-vital-signs/hg-vital-signs.component';
import { PatientProfileComponent } from './components/patient-profile/patient-profile.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'dashboard', component: DashboardComponent,
    children: [
      { path: '', redirectTo: '/patient-profile', pathMatch: 'full' },
      { path: 'encounters', component: EncountersComponent, outlet: 'content' },
      { path: 'immunizations', component: ImmunizationsComponent, outlet: 'content' },
      { path: 'medications', component: MedicationsComponent, outlet: 'content' },
      { path: 'diagnoses', component: DiagnosesComponent, outlet: 'content' },
      { path: 'allergies', component: AllergiesComponent, outlet: 'content' },
      { path: 'file-upload', component: FileUploadComponent, outlet: 'content' },
      { path: 'medical-records', component: MedicalRecordsComponent, outlet: 'content' },
      { path: 'encounter-notes', component: EncounterNotesComponent, outlet: 'content' },
      { path: 'patient-profile', component: PatientProfileComponent, outlet: 'content' },
      { path: 'hg-encounters', component: HgEncountersComponent, outlet: 'content' },
      { path: 'hg-immunizations', component: HgImmunizationsComponent, outlet: 'content' },
      { path: 'hg-medications', component: HgMedicationsComponent, outlet: 'content' },
      { path: 'hg-diagnoses', component: HgDiagnosesComponent, outlet: 'content' },
      { path: 'hg-allergies', component: HgAllergiesComponent, outlet: 'content' },
      { path: 'hg-documents', component: HgDocumentsComponent, outlet: 'content' },
      { path: 'hg-full-ccda', component: HgFullccdaComponent, outlet: 'content' },
      { path: 'hg-vitalsigns', component: HgVitalSignsComponent, outlet: 'content' },
      { path: 'hie-records', component: HieRecordsComponent, outlet: 'content' },
      { path: 'hg-labs', component: HgLabsComponent, outlet: 'content' },
      { path: '**', component: PatientProfileComponent, outlet: 'content' }
    ]
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'global-error', component: GlobalErrorComponent },
  { path: '**', component: DashboardComponent }
];

@NgModule({
  imports: [RouterTestingModule.withRoutes(routes)],
  exports: [RouterTestingModule]
})
export class PatientDashboardRoutingModule { }
