<div>
    <a class="floating-btn" [style.top.px]="sharedService.isEncounterNoteAdd?190:150" (click)="onClickFlyout()"
        style="left: 240px;" *ngIf="!isFlyoutVisible">
        <img src="../../../../../assets/images/forward.png" alt="alt" class="floating-img">
    </a>
    <div class="sidenav " [ngClass]="{'selected-nav':isFlyoutVisible}">
        <a class="back-to-btn" (click)="onClickFlyout()" *ngIf="isFlyoutVisible && isBackButtonVisible">
            <img src="../../../../../assets/images/back.svg" alt="alt" class="floating-img">
        </a>
        <div class="flex-container-start">
            <div class="side-nav-main" [ngClass]="{'side-nav-main-border':isFlyoutVisible}">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>