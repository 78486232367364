<himn-spinner *ngIf="loading$ | async"></himn-spinner>

<ng-container *ngIf="!(loading$ | async)">

  <mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
  </mat-form-field>
  <span *ngIf="isFileUpload" style="vertical-align: top!important;">
    <img style="vertical-align: top!important;" (click)=" addFile()" matTooltip="Add File" class="upload-icon  pointer "
      src="../../../../assets/images/add-file.svg">
  </span>

  <mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let fileUploadElement" >
        <div class="wrap-text" matTooltip={{fileUploadElement.created|date}}>
          {{fileUploadElement.created| date}}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Display Name</mat-header-cell>
      <mat-cell *matCellDef="let fileUploadElement">
        <div class="wrap-text"  matTooltip={{fileUploadElement.displayName}}>
          {{fileUploadElement.displayName}}
        </div>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
      <mat-cell *matCellDef="let fileUploadElement" >
        <div class="wrap-text" matTooltip={{fileUploadElement.description}}>
          {{fileUploadElement.description}}
        </div>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="tags">
      <mat-header-cell *matHeaderCellDef>Tags</mat-header-cell>
      <mat-cell *matCellDef="let fileUploadElement">
        <div class="wrap-text">
          <span *ngFor="let el of fileUploadElement.tags, let isLast=last" matTooltip={{fileUploadElement.tagString}}>
            {{el.name}}{{isLast ? '' : ', '}}
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container  matColumnDef="icon">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let fileUploadElement" >
        <div class="icon-padding" *ngIf="this.providerView">
        <span  class="isFileShared-Icon"
        [class.blue]="fileUploadElement.isAvailableToPatient" matTooltip="File shared to patient">
     </span>
    </div>
     <div class="icon-padding" >
     <img *ngIf="!fileUploadElement.downloading" class="icon-width  pointer" (click)="fileDownload(fileUploadElement)" matTooltip="Download" src="../../../../assets/images/download.svg">
      <mat-spinner   matTooltip="Downloading" *ngIf="fileUploadElement.downloading"[diameter]="20"></mat-spinner>
  </div>
     <div class="icon-padding" *ngIf="this.providerView">
      <img class="icon-width  pointer" (click)="editFileUpload(fileUploadElement)" matTooltip="Edit" src="../../../../assets/images/edit.svg">
   </div>
     <div class="icon-padding" *ngIf="this.providerView">
      <img class="icon-width  pointer" (click)="deleteFileUpload(fileUploadElement)" matTooltip="Delete" src="../../../../assets/images/delete.svg">
     </div>
      </mat-cell>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="999" style="vertical-align: middle; border-bottom-width: 0px;">No data to display
      </td>
    </tr>

    <mat-header-row *matHeaderRowDef="tableColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

  </mat-table>
</ng-container>

<div [hidden]="loading$ | async">
  <mat-paginator [length]="100" [pageSize]="5" (page)="trackIndex($event)"
    [pageSizeOptions]="[5, 10, 25, 50]" aria-label="Select page">
  </mat-paginator>
</div>

<himn-patient-dashboard-flyout #flyout [title]="popupHeading" [width]="'sm'" (closedEvent)="closeflyout()">
  <himn-patient-dashboard-file-upload #fileUploadPopup (flyoutclose)="closeflyout()" (listrefresh)="listrefresh()">
  </himn-patient-dashboard-file-upload>
</himn-patient-dashboard-flyout>
