import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, Input } from '@angular/core';
import { catchError, throwError, timeout } from 'rxjs';
import { CreateFileUpload } from '../components/file-upload/add-file-upload/add-file-upload.component';
import { HieRequest } from '../models/hie-request';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PatientDashboardService extends BaseService {
  constructor(injector: Injector, @Inject('environment') environment: any) {
    super(injector, environment);
  }

  public getPatient() {
    const url = 'patient/getPatient';
    return this.get(url);
  }

  public logHieData() {
    const url = "hie/logHieData";
    return this.getWithoutLoader(url).pipe(timeout(300000));
  }

  public logHieLabData() {
    const url = "hie/logHieLabData";
    return this.getWithoutLoader(url).pipe(timeout(300000));
  }

  public getHieLabListing() {
    const url = "hie/labListing";
    return this.get(url);
  }

  public getHieLabSummary(labIds: Array<string>) {
    const url = "hie/labSummary";
    return this.post(url, labIds);
  }

  public getHieLabObservations(observationIds: Array<string>) {
    const url = "hie/labDetails";
    return this.post(url, observationIds);
  }

  public getAllergies() {
    const url = 'humanApi/getPatientAllergies';
    return this.get(url);
  }

  public getHieAllergies() {
    const url = 'hie/allergies';
    return this.get(url);
  }

  public getMedications() {
    const url = 'humanApi/getPatientMedications';
    return this.get(url);
  }

  public getHieMedications() {
    const url = 'hie/medications';
    return this.get(url);
  }

  public getImmunizations() {
    const url = 'humanApi/getPatientImmunizations';
    return this.get(url);
  }

  public getHieImmunizations() {
    const url = 'hie/immunizations';
    return this.get(url);
  }

  public getEncounters() {
    const url = 'humanApi/getPatientEncounters';
    return this.get(url);
  }

  public getHieEncounters() {
    const url = 'hie/encounters';
    return this.get(url);
  }

  public getProblems() {
    const url = 'humanApi/getPatientProblems';
    return this.get(url);
  }

  public getHieDiagnoses() {
    const url = 'hie/diagnoses';
    return this.get(url);
  }

  public getHumanConnection() {
    const url = 'humanApi/startConnection';
    return this.get(url);
  }

  public getHeadsupSso() {
    //TODO: patient web calls it like this too.
    // does this need to be a POST? might be obsolete
    let userDetails: any = {};
    userDetails.UserId = 0;
    const url = 'user/headsUpSsoUrl';
    return this.post(url, userDetails);
  }

  public activateHeadsUp() {
    const url = 'provider/headsUpUserStatusChange?status=Active';
    return this.get(url);
  }

  public getFilesTags() {
    const url = `patient/file/tags`;
    return this.getWithoutLoader(url);
  }

  public getfileList(providerView: any, practiceId: number) {
    const url = `patient/file/list/?isProvider=${providerView}&practiceId=${practiceId}`;
    return this.get(url);
  }

  public fileDownload(patientFileId: any) {
    const url = 'patient/file/download/?patientFileId=' + patientFileId;
    return this.getWithoutLoader(url);
  }

  public uploadFile(addFileFormData: FormData) {
    const url = 'patient/file/upload';
    return this.postWithoutLoader(url, addFileFormData);
  }

  public fileCount(providerView: any, practiceId: number) {
    const url = `patient/file/count/?isProvider=${providerView}&practiceId=${practiceId}`
    return this.getWithoutLoader(url);
  }

  public isHeadsUpAlreadyOpened() {
    const url = 'IsHeadsUpAlreadyOpened';
    return this.get(url);
  }

  public closeHeadsUp() {
    const url = 'CloseHeadsUp';
    return this.get(url);
  }

  public editUploadFile(editFile: CreateFileUpload) {
    const url = 'patient/file/update/info';
    return this.put(url, editFile);
  }

  public fileDelete(patientFileId: any) {
    const url = 'patient/file/delete/?patientFileId=' + patientFileId;
    return this.delete(url);
  }

  public humanApiSourcesUpdate(userConnectionsCount: number) {
    const url = 'humanapi/SourcesUpdate';

    var data = {
      numberOfConnections: userConnectionsCount
    }

    return this.put(url, {}, { params: data });
  }

  public logout(requestorId: string | null) {
    let data = { requestorid: requestorId };
    const url = 'DashboardLogout';
    return this.post(url, data)
  }


  public saveEncounterNote(encounterNote: any) {
    const url = 'patient/notes/encounter/create';
    return this.post(url, encounterNote);
  }

  public getEncounterTypes() {
    const url = 'patient/notes/encounter/types';
    return this.get(url);
  }

  public getEncounterNoteList() {
    const url = 'patient/notes/encounter/list';
    return this.get(url);
  }

  public encounterNotesCount() {
    const url = 'patient/notes/encounter/count';
    return this.get(url);
  }

  public getRequestorDetails() {
    const url = 'provider/requestor-details';
    return this.get(url);
  }

  public getEncounterNoteDetails(encounterNoteId: number, loadGenericJson: boolean) {
    const url = 'patient/notes/encounter/details?encounterNoteId=' + encounterNoteId + '&loadGenericJson=' + loadGenericJson;
    return this.get(url);
  }

  public updateEncounterNote(encounterNote: any) {
    const url = 'patient/notes/encounter/update';
    return this.put(url, encounterNote);
  }
  public getPatientDemographics() {
    let url = 'patient/demographics';
    return this.get(url);
  }

  public getPatientAddressHistory() {
    let url = 'patient/addresshistory';
    return this.get(url);
  }

  public hieRequest(request: HieRequest) {
    const url = 'hie/request';
    return this.post(url, request);
  }

  public linkHiePatients(enrollmentData: any) {
    const url = 'hie/link';
    return this.post(url, enrollmentData);
  }

  public getDocuments(hgPatientId: string) {
    const url = `hie/getDocuments?hgPatientId=${hgPatientId}`;
    return this.get(url);
  }

  public getCustomMedications() {
    const url = 'patient/custom-medications';
    return this.getWithoutLoader(url);
  }

  public getUserRoles(publicUserId: string) {
    const url = 'user/role?publicUserId=' + publicUserId;
    return this.get(url);
  }

  public getHieXmlDocument(xmlUrl: string) {
    const url = 'Hie/downloadXml?url=' + xmlUrl;
    return this.get(url);
  }

  public getHieDocuments() {
    const url = 'Hie/documents';
    return this.get(url);
  }
  public getPracticeInfo(practiceId: any) {
    const url = 'practice/' + practiceId;
    return this.get(url);
  }

  public getVitalSigns() {
    const url = 'hie/vitalsigns';
    return this.get(url);
  }

  public autoSaveEncounterNotes(obj: any) {
    const url = 'patient/generic-json/create';
    return this.postWithoutLoader(url, obj);
  }

  public updateAutoSaveEncounterNotes(obj: any) {
    const url = 'patient/generic-json/update';
    return this.putWithoutLoader(url, obj);
  }

  public deleteAutoSaveEncounterNote(id: number) {
    const url = 'patient/generic-json/delete?genericJsonId=' + id;
    return this.deleteWithoutLoader(url);
  }

  public getRecentActivity() {
    const url = 'patient/encounter-note/recent-activity';
    return this.get(url);
  }
}
