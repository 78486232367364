<himn-spinner *ngIf="loading$ | async"></himn-spinner>

<ng-container *ngIf="!(loading$ | async)">

<main *ngIf="!hasHealthGorillaConnection" id="connection-guide">
  <section class="tips">
    <h3>Requesting Patient Health Records</h3> 
    <p>
      By clicking the Request Patient Records button below, you agree that you have a valid medical reason 
      to search for your patients records based on guidelines set forth via HIPAA
    </p>
  </section>

  <button type="button" class="primary-btn hapi-btn"
    (click)="requestHieRecords()">
    Request Patient Records
  </button>
</main>

<main *ngIf="hasHealthGorillaConnection && hasHealthRecordQueryExpired">
    <div class="encounter-header"
      [ngClass]=" containerWidth >='800' ?'encounter-header-right':'encounter-header-center'">
      <span>Reason for query - *must be for treatment purposes</span>
    </div>
    <mat-divider class="m-05"></mat-divider>
    <div class="field-frame">
      <form>
        <div>
          <div class="row label-row">
            <label class="label-cls" for="description">Indication</label>
          </div>
          <div class="row">
            <select class="filter-select" [(ngModel)]="selectedEncounterType" name="encounterType" id="encounterType"
              #encounterType="ngModel"
              [ngClass]=" containerWidth >='800' ?'filter-select-width-half':'filter-select-width-full'">
              <option value disabled selected>
                -select-</option>
              <option *ngFor="let type of encounterTypes" [value]="type.typeMasterId">
                {{type.typeName}}</option>
            </select>
          </div>
        </div>
        <div>
          <div class="row label-row">
            <label class="label-cls" for="description">Type</label>
          </div>
          <div class="row">
            <select class="filter-select" [(ngModel)]="selectedModalityType" name="modalityType" id="modalityType"
              #modalityType="ngModel"
              [ngClass]=" containerWidth >='800' ?'filter-select-width-half':'filter-select-width-full'">
              <option value disabled selected>
                -select-</option>
              <option *ngFor="let type of modalityTypes" [value]="type.typeMasterId">
                {{type.typeName}}</option>
            </select>
          </div>
        </div>
        <div>
          <div class="row label-row">
            <label class="label-cls" for="description">Provider</label>
          </div>
          <div class="row">
            <select class="filter-select" [(ngModel)]="selectedProvider" name="provider" id="provider"
              #provider="ngModel"
              [ngClass]=" containerWidth >='800' ?'filter-select-width-half':'filter-select-width-full'">
              <option value disabled selected style="background-color: white;">
                -select-</option>
              <option *ngFor="let type of providerList" [value]="type.dbUserId" style="background-color: white;">
                {{type.firstName}} {{type.lastName}}</option>
            </select>
          </div>
        </div>
        <div style="text-align: right;" [ngClass]="{'btn-margin':containerWidth >='800'}">
          <span>
            <button class="btn-sm  btn-save" type="submit" (click)="saveEncounterQuery()">Continue</button>
          </span>
        </div>
      </form>
    </div>
</main>

</ng-container>

<himn-patient-dashboard-flyout #flyout [title]="popupHeading" [width]="'sm'" (closedEvent)="closeflyout()">
  <himn-pd-hie-request #hieRequestPopup (flyoutclose)="closeflyout()" (listrefresh)="listrefresh()">
  </himn-pd-hie-request>
</himn-patient-dashboard-flyout>
