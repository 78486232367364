<himn-spinner *ngIf="loading$ | async"></himn-spinner>

<ng-container *ngIf="!(loading$ | async)">

  <div *ngIf="!hasQueryExpired">
    <mat-form-field class="filter">
      <mat-label>Filter</mat-label>
      <input (keyup)="applyFilter($any($event.target).value)" matInput placeholder="Filter">
    </mat-form-field>

    <mat-table matSort [dataSource]="dataSource">

      <ng-container matColumnDef="encounterType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
        <mat-cell *matCellDef="let encounter">{{encounter.encounterType || '--'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="encounter">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Indication</mat-header-cell>
        <mat-cell *matCellDef="let encounter">
          <ul style="list-style-type:none; padding: 0px;">
            {{encounter.encounter.length === 0 ? '--' : null}}
            <li *ngFor="let indication of encounter.encounter">
              {{indication || '--'}}
            </li>
          </ul>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="performer">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Doctor</mat-header-cell>
        <mat-cell *matCellDef="let encounter">
          {{encounter.performer || '--'}}
          <br />
          {{encounter.performerNpi || '--'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
        <mat-cell *matCellDef="let encounter">
          <span>
            {{(encounter.startDate | date) || '--'}}
            <span *ngIf="encounter.startDate?.substring(0,9) != encounter.stopDate?.substring(0,9) 
            && encounter.stopDate != null">
              - <br />
              {{(encounter.stopDate | date) || '--'}}
            </span>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
        <mat-cell *matCellDef="let encounter">{{encounter.location || '--'}}</mat-cell>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="999" style="vertical-align: middle;">No data to display</td>
      </tr>

      <mat-header-row *matHeaderRowDef="tableColumns; sticky: true;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

    </mat-table>
    <div [hidden]="loading$ | async">
      <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>

  <!-- <div *ngIf="hasQueryExpired" id="main-container">
    <div class="encounter-header"
      [ngClass]=" containerWidth >='800' ?'encounter-header-right':'encounter-header-center'">
      <span>Reason for query - *must be for treatment purposes</span>
    </div>
    <mat-divider class="m-05"></mat-divider>
    <div class="field-frame">
      <form>
        <div>
          <div class="row label-row">
            <label class="label-cls" for="description">Indication</label>
          </div>
          <div class="row">
            <select class="filter-select" [(ngModel)]="selectedEncounterType" name="encounterType" id="encounterType"
              #encounterType="ngModel"
              [ngClass]=" containerWidth >='800' ?'filter-select-width-half':'filter-select-width-full'">
              <option value disabled selected>
                -select-</option>
              <option *ngFor="let type of encounterTypes" [value]="type.typeMasterId">
                {{type.typeName}}</option>
            </select>
          </div>
        </div>
        <div>
          <div class="row label-row">
            <label class="label-cls" for="description">Type</label>
          </div>
          <div class="row">
            <select class="filter-select" [(ngModel)]="selectedModalityType" name="modalityType" id="modalityType"
              #modalityType="ngModel"
              [ngClass]=" containerWidth >='800' ?'filter-select-width-half':'filter-select-width-full'">
              <option value disabled selected>
                -select-</option>
              <option *ngFor="let type of modalityTypes" [value]="type.typeMasterId">
                {{type.typeName}}</option>
            </select>
          </div>
        </div>
        <div>
          <div class="row label-row">
            <label class="label-cls" for="description">Provider</label>
          </div>
          <div class="row">
            <select class="filter-select" [(ngModel)]="selectedProvider" name="provider" id="provider"
              #provider="ngModel"
              [ngClass]=" containerWidth >='800' ?'filter-select-width-half':'filter-select-width-full'">
              <option value disabled selected style="background-color: white;">
                -select-</option>
              <option *ngFor="let type of providerList" [value]="type.dbUserId" style="background-color: white;">
                {{type.firstName}} {{type.lastName}}</option>
            </select>
          </div>
        </div>
        <div style="text-align: right;" [ngClass]="{'btn-margin':containerWidth >='800'}">
          <span>
            <button class="btn-sm  btn-save" type="submit" (click)="saveEncounterQuery()">Continue</button>
          </span>
        </div>
      </form>

    </div>
  </div> -->
  
</ng-container>