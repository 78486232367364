export const environment = {
  production: true,
  apiUrl: 'https://api.himn.health/',
  accesskeys: {
    patientDashboard: {
      clientId: 'xxx',
      secret: 'xxx'
    }
  }
};
