export class EncounterNote {
    encounterNoteId: number = 0;
    providerUserId: number = 0;
    patientUserId: number = 0;
    encounterTypeId: number = 0;
    visitDate: Date = new Date();
    timeSpent: number = 0;
    visitTypeId: number = 0;
    statusTypeId: number = 0;
}

export class EncounterNoteObjective {
    encounterNoteObjectiveId: number = 0;
    encounterNoteId: number = 0;
    allergies: string = "";
    labValues: string = "";
    immunization: string = "";
    systolic: string = "";
    diastolic: string = "";
    rr: string = "";
    hr: string = "";
    temp: string = "";
    ht: string = "";
    wt: string = "";
    bmi: string = "";
    o2: string = "";
}

export class EncounterNoteProblem {
    encounterNoteProblemId: number = 0;
    encounterNoteId: number = 0;
    problem: string = "";
    assessment: string = "";
    treatmentPlan: string = "";
    educationCounseling: string = "";
    monitoringFollowUp: string = "";
}

export class EncounterNoteSubjective {
    encounterNoteSubjectiveId: number = 0;
    encounterNoteId: number = 0;
    chiefComplaint: string = "";
    hpi: string = "";
    pmh: string = "";
    socialHistory: string = "";
    familyHistory: string = "";
    ros: string = "";
}

export class EncounterNoteVitalSign {
    encounterNoteVitalSignId: number = 0;
    encounterNoteId: number = 0;
    vitalSignId: number = 0;
}

export class EncounterNoteSaveRequest {
    encounterNoteId: number = 0;
    patientUserId: number = 0;
    encounterTypeId: number = 0;
    visitDate: any = "";
    timeSpent: number = 0;
    visitTypeId: number = 0;
    statusTypeId: number = 0;
    encounterNoteSubjective: EncounterNoteSubjective = new EncounterNoteSubjective();
    encounterNoteObjective: EncounterNoteObjective = new EncounterNoteObjective();
    encounterNoteProblems: EncounterNoteProblem[] = [];
    encounterNoteVitalSigns: EncounterNoteVitalSign[] = [];
}