export class DashboardSummary {
    connectionCount: number = 0;
    hasActiveHeadsUp: boolean = false;
    hasHeadsUpEnabled: boolean = false;
    hasHealthGorillaEnabled: boolean = false;
    isHealthGorillaSponsor: boolean = false;
    hasHealthGorillaConnection: boolean = false;
    providerView: boolean = false;
    patientFirst: string = '';
    patientLast: string = '';
    patientDob: string = '';
    patientGender: string = '';
    hapiDataToken: string = '';
    fileCount: number = 0;
    initialRoute: string = '';
    isAcceptTC: boolean = false;
    hasHumanApiEnabled: boolean = false;
    hasEncounterNotesEnabled: boolean = false;
    practiceName: string = '';
    address1: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    autosaveConfig: any;
    cellPhone = '';
    email = '';
    hasHealthRecordQueryExpired: boolean = true;
}