import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService } from '../../../services/loading.service';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { StringManipService } from '../../../services/string-manip.service';

@Component({
  selector: 'lib-hg-medications',
  templateUrl: './hg-medications.component.html',
  styleUrls: ['./hg-medications.component.scss']
})
export class HgMedicationsComponent implements OnInit {
  loading$ = this.loader.loading$;

  constructor(
    private pdService: PatientDashboardService,
    private stringUtil: StringManipService,
    private loader: LoadingService  
  ) { }

  @Input('minified') minified: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'name':
            return this.stringUtil.trimAndLower(item.name?.toString());
          case 'startDate':
            return this.stringUtil.trimAndLower(item.startDate)?.toString();
          case 'endDate':
            return this.stringUtil.trimAndLower(item.endDate?.toString());
          case 'note':
            return this.stringUtil.trimAndLower(item.note?.toString());
          case 'quantity':
            return this.stringUtil.trimAndLower(item.quantity?.toString());
          case 'indications':
            return this.stringUtil.trimAndLower(item.indications?.toString());
          case 'status':
            return this.stringUtil.trimAndLower(item.status?.toString());
          default:
            return '';
        }
      };
      this.dataSource.sort = sort;
    }

    this.dataSource.sort = sort;
  }

  tableColumns: string[] = [];
  dataSource: MatTableDataSource<FhirMedication> = new MatTableDataSource<FhirMedication>([]);

  ngOnInit(): void { 
    if (this.minified)
      this.tableColumns = ['name', 'startDate', 'endDate', 'note' ];
    else
      this.tableColumns = ['name', 'quantity', 'status', 'startDate', 'endDate', 'indications', 'note' ];

    this.pdService.getHieMedications().subscribe((data: any) => {
      if (data !== null) {
        this.dataSource = new MatTableDataSource<FhirMedication>(data);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  applyFilter(event: Event) {
    this.dataSource.filter = (event as unknown as String).trim().toLowerCase();
  }
}

export interface FhirMedication {
  name: string,
  startDate: string,
  endDate: string,
  quantity: string,
  status: string,
  indications: string,
  note: string
}
