import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-hg-fullccda',
  templateUrl: './hg-fullccda.component.html',
  styleUrls: ['./hg-fullccda.component.scss']
})
export class HgFullccdaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
