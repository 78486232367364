<div *ngFor="let lab of labsList; let first = first; let i = index" style="width: 350px;" [ngClass]="{'select-item':selectedLab == i}">
  <hr *ngIf="first" />
  <a (click)="loadLab(lab.observationIds, i)">
      <div class="sub-list-item">
        <div>
          <img *ngIf="lab.abnormality" class="abnormality" matTooltip="Abnormality" src="../../../../../assets/images/warning.svg">  
          <span class="text-name">{{lab.listingName}}</span>
        </div>
        <div class="flex-container-inbetween">
            <div>
              <span class="text-type">{{lab.source}}</span>
          </div>
          <div>
            <span class="text-date">{{lab.dateOfReport != null ? (lab.dateOfReport | date) : '--'}}</span>
          </div>
        </div>
      </div>
  </a>
  <hr>
</div>