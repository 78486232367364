import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthorizationInfo } from '../models/authorization-info';
import { DashboardSummary } from '../models/dashboard-summary';

@Injectable({providedIn: 'root'})
export class LocalDataService {

  private dsSource = new BehaviorSubject<DashboardSummary>(new DashboardSummary);
  dashboardSummary = this.dsSource.asObservable();

  private authorizationInfoSource = new BehaviorSubject<AuthorizationInfo>(new AuthorizationInfo);
  authInfo = this.authorizationInfoSource.asObservable();

  private menuClickedEvent = new Subject<string>();
  clickedMenu = this.menuClickedEvent.asObservable();

  // private requestor = new BehaviorSubject<any>(null);
  // requestorDetails = this.requestor.asObservable();

  constructor() { }

  updateDashboardSummary(dashboardSummary: DashboardSummary) {
    this.dsSource.next(dashboardSummary)
  }

  updateAuthInfo(authInfo: AuthorizationInfo) {
    this.authorizationInfoSource.next(authInfo);
  }

  menuClicked(clickedMenu: string) {
    this.menuClickedEvent.next(clickedMenu);
  }

  // requestorInfo(requestorDetails: any) {
  //   this.requestor.next(requestorDetails);
  // }
}
