import { Component, Input, OnInit } from '@angular/core';
import { DashboardSummary } from '../../models/dashboard-summary';
import { LocalDataService } from '../../services/local-data.service';

@Component({
  selector: 'lib-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private localService: LocalDataService
  ) { }

  @Input('provider-view') providerView: boolean = false;

  ngOnInit(): void {
    this.localService.dashboardSummary.subscribe((response: DashboardSummary) => {
      this.providerView = response.providerView;
    });
  }

  closewindow(): void {
    window.close();
  }
}
