import { Component, OnInit } from '@angular/core';
import { DashboardSummary } from '../../models/dashboard-summary';
import { LoadingService } from '../../services/loading.service';
import { LocalDataService } from '../../services/local-data.service';
import { PatientDashboardService } from '../../services/patient-dashboard.service';

export class RecentActivity {
  encounterNoteFinalized = '';
  encounterNoteCreated = '';
  HealthRecordImport = '';
}

@Component({
  selector: 'lib-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit {

  loading$ = this.loader.loading$;
  dashboardSummary = new DashboardSummary();
  recentActivity = new RecentActivity();
  recentActivityList: any[] = [];
  healthRecordImportOn = '';
  remainingDays = 0;

  constructor(private localService: LocalDataService,
    public pdService: PatientDashboardService,
    private loader: LoadingService) { }

  ngOnInit(): void {
    this.localService.dashboardSummary.subscribe((response: DashboardSummary) => {
      this.dashboardSummary = response;
      var incPatientGender = this.dashboardSummary.patientGender.toLocaleLowerCase();
      if (incPatientGender == 'm' || incPatientGender == 'male') {
        this.dashboardSummary.patientGender = 'Male';
      } else if (incPatientGender == 'f' || incPatientGender == 'female') {
        this.dashboardSummary.patientGender = 'Female';
      } else {
        this.dashboardSummary.patientGender = 'Other';
      }
    });

    this.pdService.getRecentActivity().subscribe((response: any) => {
      this.recentActivityList = response.data.noteList;
      this.healthRecordImportOn = response.data.healthRecordImportOn;
    });
  }



}
