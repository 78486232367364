<main id="connection-guide" *ngIf="showNoConnectionGuide">
  <h1>Portal Records</h1>

  <section class="tips">
    <h3>What are Portal Records?</h3> 
    <p>
      Your healthcare providers document your medical visits, results, and history in your health records. This application allows you to
      securely connect your health records from your different providers all in one place.
    </p>
    <p>
      Before connecting, please have your username and password for each of your patient portal accounts. Once you have this information,
      click the link below to get connected.
    </p>
  </section>

  <button type="button" class="primary-btn hapi-btn"
    (click)="launchHapiConnect()">
    Get Connected
  </button>
</main>
