import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LoadingService } from 'projects/components/src/lib/services/loading.service';
import { SharedService } from 'projects/components/src/lib/services/sharedservice.service';
import { PatientDashboardService } from 'projects/components/src/public-api';


@Component({
  selector: 'lib-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements AfterViewInit {
  @ViewChild('xmlContainer', { static: false }) xmlContainer: any;
  @ViewChild('hgSlideOut', { static: false }) hgSlideOut: any;
  @Input() isFullCcda = false;
  documentList: any[] = []
  public xslStylesheet: any;
  public xsltProcessor = new XSLTProcessor();
  public myDOM: any;
  public xmlDoc: any;
  public xsltUrl: any = "../../../../../assets/xml/CDA.xsl";
  public summaryData: any;
  loading$ = this.loader.loading$;

  constructor(public sharedService: SharedService,
    private pdService: PatientDashboardService,
    private loader: LoadingService) { }
  ngAfterViewInit(): void {
    this.getDocuments()
  }

  xmlToHtmlConverter(xmlData: any, xslUrl: string) {
    let myXMLHTTPRequest = new XMLHttpRequest();
    myXMLHTTPRequest.open("GET", xslUrl, false);
    myXMLHTTPRequest.send();
    this.xslStylesheet = (new DOMParser()).parseFromString(myXMLHTTPRequest.responseText, 'text/xml');
    this.xsltProcessor.importStylesheet(this.xslStylesheet);
    this.xmlDoc = xmlData;
    var value = this.xsltProcessor.transformToFragment(this.xmlDoc, document);
    this.myDOM = value;
    this.xmlContainer.nativeElement.innerHTML = '';
    this.xmlContainer.nativeElement.appendChild(this.myDOM);
  }

  loadXmlContent(data: any) {
    this.hgSlideOut.onClickFlyout();
    this.xmlToHtmlConverter(data, this.xsltUrl);
  }

  public loadXmlFile(url: string) {
    if (url == 'summary') {
      this.loadXmlContent(this.summaryData);
    }
    else {
      this.pdService.getHieXmlDocument(url).subscribe((response: any) => {
        var data = (new DOMParser()).parseFromString(response.ccdaXml, 'text/xml');
        this.loadXmlContent(data);
      });
    }
  }

  public getDocuments() {
    this.pdService.getHieDocuments().subscribe((response: any) => {
      this.documentList = response.documentList;
      this.summaryData = (new DOMParser()).parseFromString(response.xmlContent.ccdaXml, 'text/xml');
      this.loadXmlContent(this.summaryData);
    });
  }
}
