<div class="flyout-container">
    <div class="flyout-body dismiss "
        [ngClass]="{'selected' : isShow,'dismiss': !isShow,'bgcolor':isEdit,'small-flyout': width === 'sm','large-flyout': width === 'lg'}">
        <div class="flyout-title-container">
            <div *ngIf="!isHideTitle" class="model-title">
                <span [ngClass]="{'edit-txtcolor' : isEdit,'add-txtcolor':!isEdit}">{{title}}</span>
            </div>
            <div class="flyout-close" (click)="close()">
               
            </div>
        </div>
        <div class="flyout-content">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="modal-background" *ngIf="isShow"></div>
</div>