import { DatePipe, formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { EncounterNoteObjective, EncounterNoteProblem, EncounterNoteSubjective, EncounterNoteVitalSign } from '../../../models/encounter-notes';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { LocalDataService } from '../../../services/local-data.service';
import { SharedService } from '../../../services/sharedservice.service';
import { DashboardSummary } from '../../../models/dashboard-summary';
import jsPDF from 'jspdf';
import { LoadingService } from '../../../services/loading.service';


@Component({
  selector: 'lib-add-encounter-notes',
  templateUrl: './add-encounter-notes.component.html',
  styleUrls: ['./add-encounter-notes.component.scss']
})
export class AddEncounterNotesComponent implements OnInit, OnDestroy {

  @Output() backToList = new EventEmitter();
  @Input() encounterNoteId: number = 0;
  @Input() openUnsavedDraft: boolean = false;
  @Input() unsavedEncounterNote: any;
  @ViewChild('flyout', { static: false }) flyout: any;
  @ViewChild('encounterNoteMedication', { static: false }) encounterNoteMedication: any;
  @ViewChild('addEncounterNoteMedication', { static: false }) addEncounterNoteMedication: any;
  @ViewChild('confirmPopup', { static: false }) confirmPopup: any;
  //@ViewChild('genericJsonConfirmPopup', { static: false }) genericJsonConfirmPopup: any;
  @Input() userRoles: string[] = [];

  noteForm = new FormGroup({
    visitDate: new FormControl('', Validators.nullValidator),
    timeSpent: new FormControl('', Validators.nullValidator),
    visitTypeId: new FormControl('', Validators.nullValidator),
    encounterTypeId: new FormControl('', Validators.nullValidator),
    encounterNoteSubjective: new FormGroup({
      chiefComplaint: new FormControl('', Validators.nullValidator),
      hpi: new FormControl('', Validators.nullValidator),
      pmh: new FormControl('', Validators.nullValidator),
      socialHistory: new FormControl('', Validators.nullValidator),
      familyHistory: new FormControl('', Validators.nullValidator),
      ros: new FormControl('', Validators.nullValidator)
    }),
    encounterNoteObjective: new FormGroup({
      allergies: new FormControl('', Validators.nullValidator),
      labValues: new FormControl('', Validators.nullValidator),
      immunization: new FormControl('', Validators.nullValidator),
      systolic: new FormControl('', Validators.nullValidator),
      diastolic: new FormControl('', Validators.nullValidator),
      rr: new FormControl('', Validators.nullValidator),
      hr: new FormControl('', Validators.nullValidator),
      temp: new FormControl('', Validators.nullValidator),
      wt: new FormControl('', Validators.nullValidator),
      ht: new FormControl('', Validators.nullValidator),
      bmi: new FormControl('', Validators.nullValidator),
      o2: new FormControl('', Validators.nullValidator)
    }),
    encounterNoteVitalSigns: this.fb.array([]),
    encounterNoteProblems: this.fb.array([]),
  })
  EncounterNote: any = {};

  public widthOne = (window.innerWidth - 300) / 2;
  public widthTwo = (window.innerWidth - 300) / 2;
  public y = 100;
  public oldY = 0;
  public grabber = false;
  public assessmentAndPlan: any[] = [];
  public encounterTypes: any[] = [];
  public modalityTypes: any[] = [];
  public visitDate: string = "";
  public medications: any[] = [];
  public isMedicationVisible: boolean = true;
  public medicationListPopupHeading: string = "Medication Picker";
  public medicationListPopupWidth = ((window.innerWidth) / 1.5) - 100;
  public addMedicationPopupHeading: string = "Medications";
  public addMedicationPopupWidth = ((window.innerWidth) * 4 / 5);
  public encounterNoteSubjective = new EncounterNoteSubjective();
  public encounterNoteObjective = new EncounterNoteObjective();
  public encounterNoteProblems: EncounterNoteProblem[] = [];
  public encounterNoteVitalsigns: any[] = [];
  public selectedVitalSigns: EncounterNoteVitalSign[] = [];
  public statusTypes: any[] = [];
  public timeSpend: any;
  public selectedModalityType: any = null;
  public selectedEncounterType: any = null;
  public selectedStatusType: any;
  public enableSubmitButtom: boolean = true;
  public isOpenMedPopup = false;
  public isFinalized: boolean = true;
  public hasHealthGorillaFeatures = false;
  public showComponent: string = '';
  public displayAreaOne = false;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  public medicationList: any[] = [];
  public disableAddBtn = true;
  public expandAreaOne: boolean = true;
  public isShowMedHistoryBtn = false;
  public patientName = '';
  public finalizedBy = '';
  public providerNpi = '';
  public showPdfIcon = false;
  public initialMedicationsChanged = false;
  public initialProblemsChanged = false;
  loading$ = this.loader.loading$;
  public autoSaveJson = {
    "genericJsonId": 0,
    "type": "Encounter Note",
    "referenceId": 0,
    "json": ""
  }
  public intervel;
  public isAutoSaveEnabled = false;
  public autoSaveIntervel = 10;
  public savedEncounterNoteObj: any;
  public draftedEncounterNoteObj: any;


  constructor(private dashboardService: PatientDashboardService,
    private localService: LocalDataService,
    private notifier: NotifierService,
    public datepipe: DatePipe,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private loader: LoadingService
  ) {
    this.intervel = setInterval(() => { this.encounterNotesAutoSave() }, this.autoSaveIntervel * 1000);
  }


  ngOnDestroy(): void {
    this.sharedService.isEncounterNoteAdd = false;
    this.sharedService.selectedSubMenu = 'Encounters';
    clearInterval(this.intervel);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabber) {
      return;
    }
    if ((this.widthOne > ((window.innerWidth - 300) * 3 / 4)) && (this.widthTwo < ((window.innerWidth - 300) / 4))) {
      this.widthOne = ((window.innerWidth - 300) * 3 / 4);
      this.widthTwo = ((window.innerWidth - 300) / 4);
      return;
    }
    if ((this.widthOne < ((window.innerWidth - 300) / 4)) && (this.widthTwo > ((window.innerWidth - 300) * 3 / 4))) {
      this.widthOne = ((window.innerWidth - 300) / 4);
      this.widthTwo = ((window.innerWidth - 300) * 3 / 4);
      return;
    }
    this.resizer(event.clientX - this.oldY);
    this.oldY = event.clientX;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.grabber = false;
  }

  resizer(offsetx: number) {
    this.widthOne += offsetx;
    this.widthTwo -= offsetx;
    this.areaOneMaximizeOrMinimize();
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.clientX <= window.innerWidth - this.widthTwo && window.innerWidth - this.widthTwo <= event.clientX + 50) {
      this.grabber = true;
      this.oldY = event.clientX;
    }
  }


  ngOnInit(): void {
    this.sharedService.isEncounterNoteAdd = true;
    this.sharedService.selectedSubMenu = 'Encounters';
    this.localService.dashboardSummary.subscribe(response => {
      this.isAutoSaveEnabled = response.autosaveConfig.enabled;
      this.autoSaveIntervel = response.autosaveConfig.timeInterval;
      if (response.hasHealthGorillaEnabled && response.hasHealthGorillaConnection) {
        this.hasHealthGorillaFeatures = true;
        this.displayAreaOne = true;
      }
      else {
        this.displayAreaOne = false;
        this.widthTwo = (window.innerWidth - 270);
      }


    });
    if (this.hasHealthGorillaFeatures) {
      this.showComponent = 'Encounters';
    }
    this.localService.clickedMenu.subscribe((result: any) => {
      this.showComponent = result;
    });
    this.dashboardService.getEncounterTypes().subscribe((result: any) => {
      this.encounterTypes = result.data.encounterTypes;
      this.modalityTypes = result.data.modalityTypes;
      this.statusTypes = result.data.statusTypes;
      if (this.encounterNoteId != 0) {
        this.loadEncounterNoteDetails(this.encounterNoteId)
      }
      else if (this.encounterNoteId == 0 && (this.unsavedEncounterNote != null || this.unsavedEncounterNote != undefined)) {
        this.autoSaveJson.genericJsonId = this.unsavedEncounterNote.genericJsonId;
        this.encounterNoteObjectBinding(JSON.parse(this.unsavedEncounterNote.json));
      }
      else {
        this.isFinalized = false;
        this.visitDate = formatDate(Date.now().toString(), 'yyyy-MM-dd', 'en');
        this.addProblems(false);
      }
    });

    this.dashboardService.getCustomMedications().subscribe((res: any) => {
      let customMedications: any = [];
      let hgMedications: any = [];
      if (res !== null && res.data.medicationList.length > 0) {
        customMedications = res.data.medicationList.filter((x: any) => x.name != null && x.name != '');
      }
      if (this.hasHealthGorillaFeatures) {
        this.dashboardService.getHieMedications().subscribe((data: any) => {
          if (data !== null) {
            hgMedications = data.filter((x: any) => x.name != null && x.name != '');
            this.medicationList = hgMedications.concat(customMedications);
            this.isShowMedHistoryBtn = this.medicationList.length > 0 ? true : false;
            this.disableAddBtn = false;
          }
        });
      }
      else {
        this.medicationList = customMedications;
        this.isShowMedHistoryBtn = this.medicationList.length > 0 ? true : false;
        this.disableAddBtn = false;
      }

    });

  }

  get getProblems() {
    return this.noteForm.controls["encounterNoteProblems"] as FormArray;
  }

  addProblems(initialProblem: boolean = true) {
    if (initialProblem)
      this.initialProblemsChanged = true;
    const problemForm = this.fb.group({
      problem: ['', Validators.nullValidator],
      assessment: ['', Validators.nullValidator],
      treatmentPlan: ['', Validators.nullValidator],
      educationCounseling: ['', Validators.nullValidator],
      monitoringFollowUp: ['', Validators.nullValidator]
    });
    this.getProblems.push(problemForm);
  }

  CloseSection(index: number) {
    var c = confirm('Are you sure you want to delete this Problem ?')
    if (c) {
      this.getProblems.removeAt(index);
      this.initialProblemsChanged = true;
    }
  }


  loadEncounterNoteDetails(encounterNoteId: number) {
    this.dashboardService.getEncounterNoteDetails(encounterNoteId, this.openUnsavedDraft).subscribe((note: any) => {
      // console.log(note, "note")
      if ((note.data.encounterNoteDTO.genericJSON) != null) {
        this.autoSaveJson.genericJsonId = note.data.encounterNoteDTO.genericJSON.genericJsonId;
        this.encounterNoteObjectBinding(JSON.parse(note.data.encounterNoteDTO.genericJSON.json));
      }
      else {
        this.encounterNoteObjectBinding(note.data.encounterNoteDTO);
      }
    });
  }

  encounterNoteObjectBinding(note: any) {
    var encounterNote = note;
    this.visitDate = formatDate(encounterNote.visitDate, 'yyyy-MM-dd', 'en');;
    this.timeSpend = (Number)(encounterNote.timeSpent);
    this.selectedModalityType = encounterNote.visitTypeId != null ? this.modalityTypes.filter(m => { return m.typeMasterId == encounterNote.visitTypeId })[0] : null;
    this.selectedEncounterType = encounterNote.encounterTypeId != null ? this.encounterTypes.filter(m => { return m.typeMasterId == encounterNote.encounterTypeId })[0] : null;
    var noteStatus = this.statusTypes.filter(s => { return s.typeMasterId == encounterNote.statusTypeId })[0];
    this.isFinalized = noteStatus.typeName == 'Finalized' ? true : false;
    this.showPdfIcon = noteStatus.typeName == 'Finalized' ? true : false;;
    this.finalizedBy = encounterNote.updatedByName != null ? encounterNote.updatedByName : '';
    this.providerNpi = encounterNote.npi ? encounterNote.npi : '';
    this.encounterNoteSubjective = encounterNote.encounterNoteSubjective;
    this.encounterNoteObjective = encounterNote.encounterNoteObjective;
    this.encounterNoteProblems = encounterNote.encounterNoteProblems;
    this.medications = encounterNote.encounterNoteMedications;
    this.encounterNoteProblems.forEach(problem => {
      const problemForm = this.fb.group({
        problem: [problem.problem, Validators.nullValidator],
        assessment: [problem.assessment, Validators.nullValidator],
        treatmentPlan: [problem.treatmentPlan, Validators.nullValidator],
        educationCounseling: [problem.educationCounseling, Validators.nullValidator],
        monitoringFollowUp: [problem.monitoringFollowUp, Validators.nullValidator]
      });
      this.getProblems.push(problemForm);
    })
  }

  BackToListPage() {
    if (this.noteForm.dirty || this.initialMedicationsChanged || this.initialProblemsChanged)
      this.confirmPopup.open();
    else
      this.backToList.emit();
  }

  quickNavigation(el: HTMLElement) {
    el.scrollIntoView();
  }

  openMedicationListPopupbyEvent() {
    this.addEncounterNoteMedication.close();
    this.openMedicineListPopup();
  }

  openMedicineListPopup() {
    this.isOpenMedPopup = true;
    if (this.medicationList.length > 0) {
      this.encounterNoteMedication.open();
    } else {
      this.openMedicineAddPopup();
    }

  }

  closeMedicineListPopup() {
    this.encounterNoteMedication.close();
  }

  openMedicineAddPopup() {
    this.addEncounterNoteMedication.open();
  }

  closeMedicineAddPopup(meds: any) {
    this.addEncounterNoteMedication.close();
  }

  addNewMedicationPopupEvent(meds: any[]) {
    this.initialMedicationsChanged = true;
    this.medications = meds;
  }

  onSelectVitalSigns(id: number) {
    this.encounterNoteVitalsigns.forEach(vs => {
      if (vs.typeMasterId == id)
        vs.isSelected = !vs.isSelected;
    })
  }

  finalizeEncounterNote() {
    this.statusTypes.forEach(st => {
      if (st.typeName == "Finalized") {
        this.selectedStatusType = st;
      }
    });
    this.saveEncounterNotes();
  }

  saveEncounterNotes() {
    if (this.selectedStatusType == undefined || this.selectedStatusType.typeName != "Finalized") {
      this.statusTypes.forEach(st => {
        if (st.typeName == "Pending") {
          this.selectedStatusType = st;
        }
      });
    }

    var encounterData = this.noteForm.value;

    encounterData.statusTypeId = this.selectedStatusType.typeMasterId;
    encounterData.visitTypeId = encounterData.visitTypeId ? encounterData.visitTypeId.typeMasterId : null;
    encounterData.encounterTypeId = encounterData.encounterTypeId ? encounterData.encounterTypeId.typeMasterId : null;
    encounterData.encounterNoteMedications = this.medications;
    encounterData.genericJsonId = this.autoSaveJson.genericJsonId;
    clearInterval(this.intervel);
    this.enableSubmitButtom = false;
    if (this.encounterNoteId != 0) {
      encounterData.encounterNoteId = this.encounterNoteId;
      this.dashboardService.updateEncounterNote(encounterData).subscribe((result: any) => {
        this.notifier.notify('success', 'Encounter note updated successfully!');
        this.backToList.emit();
        this.enableSubmitButtom = true;
      },
        (err: HttpErrorResponse) => {
          err.error.message
          this.enableSubmitButtom = true;
          this.notifier.notify('warning', err.error.message);
        });
    }
    else {
      this.dashboardService.saveEncounterNote(encounterData).subscribe((result: any) => {
        this.notifier.notify('success', 'Encounter note saved successfully!');
        this.backToList.emit();
        this.enableSubmitButtom = true;
        this.sharedService.encounterNoteCountChange.next(true);
      },
        (err: HttpErrorResponse) => {
          this.enableSubmitButtom = true;
          err.error.message
          this.notifier.notify('warning', err.error.message);
        });
    }
  }


  includeMedicinePopupEvent(event: any) {
    // this.hgMedications = event;
    this.initialMedicationsChanged = true;
    this.medications = this.medications.concat(event);
    this.encounterNoteMedication.close();
  }

  onClickAreaOneExpand() {
    //this.areaMaximizeOrMinimize();
    if (!this.expandAreaOne) {
      this.widthOne = (window.innerWidth - 300) / 2;
      this.widthTwo = (window.innerWidth - 300) / 2;
      this.expandAreaOne = true;
    }
    else {
      this.widthOne = ((window.innerWidth - 300) * 3 / 4);
      this.widthTwo = ((window.innerWidth - 300) / 4);
      this.expandAreaOne = false;
    }
  }


  onResize(event: any) {
    this.widthOne = (window.innerWidth - 300) / 2;
    this.widthTwo = (window.innerWidth - 300) / 2;
    this.expandAreaOne = true;
  }

  areaOneMaximizeOrMinimize() {
    if (this.widthOne <= (window.innerWidth - 300) / 2) {
      this.expandAreaOne = true;
    }
    else {
      this.expandAreaOne = false;
    }
  }

  exportPdf() {
    var today = new Date();
    var newdate = " " + this.datepipe.transform(today, 'MM/dd/yyyy hh:mm a');
    var fileName = 'EC_' + this.datepipe.transform(new Date(), 'MM-dd-yyyy hh:mm:ss');
    var img = new Image();
    var doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [595.28, 841.89]
    })
    const div = this.getHtml();
    doc.html(div, {
      callback: function (pdf) {
        let totalPages = pdf.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor('#5d707b');
          pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 30);
        }
        img.src = '../../../../assets/images/parawell-logo-color.png';
        pdf.setFontSize(8);
        pdf.setTextColor('#5d707b');
        pdf.text(newdate, 50, pdf.internal.pageSize.getHeight() - 30);
        pdf.setFontSize(15);
        pdf.setFont('', 'italic');
        pdf.text('Powered By:', pdf.internal.pageSize.getWidth() - 190, pdf.internal.pageSize.getHeight() - 30);
        pdf.addImage(img, 'png', pdf.internal.pageSize.getWidth() - 130, pdf.internal.pageSize.getHeight() - 50, 120, 30);//left,top,width,height

        pdf.save(fileName);
      },
      margin: [40, 30, 40, 60],
      autoPaging: "text",
    });
  }

  getHtml() {
    var dashboardSummary = new DashboardSummary();
    this.localService.dashboardSummary.subscribe((response: DashboardSummary) => {
      dashboardSummary = response;
      this.patientName = response.patientFirst + ' ' + response.patientLast;
      dashboardSummary.practiceName
    })
    var selectedModalityType = this.selectedModalityType != null ? this.selectedModalityType.typeName : '';
    var selectedEncounterType = this.selectedEncounterType != null ? this.selectedEncounterType.typeName : '';
    var tab_text = `<div id="PdfDiv">
    <div style="width:500px;">
      <div style="padding-bottom:10px">
        <label style="color: #5d707b;font-size:1.5em;">`+ dashboardSummary.practiceName + `</label>
      </div>
      <div>
        <div style="display: flex;">
          <div style="flex: 1; background-color: #f0ecec; width: 50%;">
            <table style="font-size: 8px;background-color:#f0ecec;padding:5px;">
              <tr style="font-size:12px";>
                <td style="width:2%;line-height: 8px;vertical-align:top;font-weight: 600;"><span>Patient: </span></td>
                <td style="width:20%;line-height: 8px;"><label style="font-weight: 600;">` + dashboardSummary.patientFirst + ' ' + dashboardSummary.patientLast + `</label></td>
              </tr>
              <tr>
                <td style="width:2%;line-height: 8px;vertical-align:top;"><span>DOB: </span></td>
                <td style="width:20%;line-height: 8px;"><label>` + this.datepipe.transform(dashboardSummary.patientDob, 'MM/dd/yyyy') + `</label></td>      
              </tr>
              <tr>
                <td style="width:2%;line-height: 8px;vertical-align:top;"><span>Address: </span></td>
                <td style="width:20%;line-height: 8px;"><label>` + dashboardSummary.address1 + `<br>` + dashboardSummary.city + ` ` + dashboardSummary.state + ` ` + dashboardSummary.zip + `</label></td>     
              </tr>      
            </table>
          </div>
          <div style=" flex: 1; background-color: #f0ecec; margin-left: 10px;width: 50%;">
            <table style="font-size: 8px;background-color:#f0ecec;padding:5px">
              <tr style="font-size:12px";>
                <td style="width:2%;line-height: 8px;vertical-align:top;font-weight: 600;"><span>Provider: </span></td>
                <td style="width:20%;line-height: 8px;"><label style="font-weight: 600;">` + this.finalizedBy + `</label></td>
              </tr>
              <tr>
                <td style="width:2%;line-height: 8px;vertical-align:top;"><span>NPI: </span></td>
                <td style="width:20%;line-height: 8px;"><label>` + this.providerNpi + `</label></td>      
              </tr>     
            </table>
          </div>
        </div>

        <table style="font-size: 8px;padding:5px">
          <tr>
            <td style="width:6%;line-height: normal;"><span>Visit Date:</span></td>
            <td style="width:22%;line-height: normal;padding-left:1px"><label>` + this.datepipe.transform(this.visitDate, 'MM-dd-yyyy') + `</label></td>
            <td style="width:6%;line-height: normal;padding-left:10px"><label>Time Spent:</label></td>
            <td style="width:20%;line-height: normal;padding-left:1px"><label>` + this.timeSpend + ` Minutes</label></td>
          </tr>
          <tr>
            <td style="width:6%;line-height: normal;"><label>Modality:</label></td>
            <td style="width:22%;line-height: normal;padding-left:1px"><label>` + selectedModalityType + `</label></td>
            <td style="width:6%;line-height: normal;padding-left:10px"><label>Encounter Type:</label></td>
            <td style="width:20%;line-height: normal;padding-left:1px"><label>` + selectedEncounterType + `</label></td>
          </tr>              
        </table>
      </div>
        
      <div style="border-bottom: 0.5px solid grey"><span style="font-size:10px;">Subjective</span></div>
      
      <div formGroupName="encounterNoteSubjective">
        <table style="width:100%;font-size:8px">
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><span>Chief Complaint (CC) :</span></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.chiefComplaint + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><label>History of Present Illness (HPI) :</label></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.hpi + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><span>Past Medical History (PMH) : </span></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.pmh + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><label>Social History (SH) : </label></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.socialHistory + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><label>Family History (FH) :</label></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.familyHistory + `</label></td>
          </tr>
          <tr>
            <td style="width:10%;line-height: normal;vertical-align:top;"><label>Review of Systems (ROS) : </label></td>
            <td style="width:20%;line-height: normal;"><label>` + this.encounterNoteSubjective.ros + `</label></td>
          </tr>          
        </table>
      </div>
    
      <div style="border-bottom: 0.5px solid grey"><span style="font-size:10px;">Objective</span></div>
    
      <div>
        <span style="font-size: 8px;padding-left:3px">Medications :</span>
      </div>
  
      <div>
        <table style="font-size: 8px;">
          <tr>
            <th style="width:10%;text-align:left;line-height: normal">Name and Strength</th>
            <th style="width:10%;text-align:left;line-height: normal">Start Date</th>
            <th style="width:20%;text-align:left;line-height: normal">Directions</th>        
          </tr>`

    if (this.medications.length > 0) {
      for (let i = 0; i < this.medications.length; i++) {
        tab_text = tab_text + `<tr>
              <td style="width:10%;line-height: normal">` + this.medications[i].medicationName + `</td>
              <td style="width:10%;line-height: normal">` + this.medications[i].startDate + `</td>
              <td style="width:20%;line-height: normal">`+ this.medications[i].directions + `</td>
              </tr>`
      }
    }
    else {
      tab_text = tab_text + `<tr>
            <td style="width:10%"> No records found</td></tr>`
    }

    tab_text = tab_text +
      `</table>
        </div>
        <br />
        <div>
          <table style="width:100%;font-size:8px;">
            <tr>
              <td style="width: 20%;line-height: normal;vertical-align:top;"><label>Allergies :</label></td>
              <td style="line-height: normal;"><label>` + this.encounterNoteObjective.allergies + `</label></td>
            </tr>
          </table>
        </div>
  
        <div>
          <table style="width:100%;font-size:8px;">
            <tr>
              <td style="width: 20%"><label>Vital Signs :</label></td>
              <td><label>BP : `+ this.encounterNoteObjective.systolic + `/` + this.encounterNoteObjective.diastolic + ` mmHg</label></td>
              <td><label>RR : `+ this.encounterNoteObjective.rr + ` BPM</label></td>
              <td><label>HR  : `+ this.encounterNoteObjective.hr + ` BPM</label></td>
              <td><label>Temp : `+ this.encounterNoteObjective.temp + ` ºF</label></td>
            </tr>
            <tr>
              <td></td>
              <td><label>WT : `+ this.encounterNoteObjective.wt + ` lb</label></td>
              <td><label>HT : `+ this.encounterNoteObjective.ht + ` in</label></td>
              <td><label>BMI : `+ this.encounterNoteObjective.bmi + ` kg/m<sup>2</sup></label></td>  
              <td><label>SpO<sub>2</sub> : `+ this.encounterNoteObjective.o2 + ` %</label></td>
            </tr>           
          </table>
        </div>
  
      <div>
        <table style="width:100%;font-size:8px;">
          <tr>
            <td style="width: 20%;line-height: normal;vertical-align:top;"><span>Laboratory Values :</span></td>
            <td style="line-height: normal;"><label>` + this.encounterNoteObjective.labValues + `</label></td>
          </tr>
          <tr>
            <td style="width: 20%;line-height: normal;vertical-align:top;"><span>Immunization :</span></td>
            <td style="line-height: normal;"><label>` + this.encounterNoteObjective.immunization + `</label></td>
          </tr>  
        </table>
      </div>`

    for (let i = 0; i < this.encounterNoteProblems.length; i++) {
      tab_text = tab_text +
        `<div style="border-bottom: 0.5px solid grey">
              <span style="font-size:10px;">
                Problem
                <span style="font-style: italic;">(Assessment & Plan)</span>
              </span>
            </div>
          <table style="width: 100%;font-size:8px;">
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Problem :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].problem + `</label></td>
          </tr>
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Assessment :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].assessment + `</label></td>
          </tr>
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Treatment Plan :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].treatmentPlan + `</label></td>
          </tr>
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Education and Counseling :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].educationCounseling + `</label></td>
          </tr>
          <tr>
            <td style="width: 10%;line-height: normal;vertical-align:top;"><span>Monitoring and Followup :</span></td>
            <td style="width: 20%;line-height: normal;"><label>` + this.encounterNoteProblems[i].monitoringFollowUp + `</label></td>
          </tr>
        </table>`
    }
    tab_text = tab_text + `</div></div>`
    return tab_text;
  }

  encounterNotesAutoSave() {
    if ((this.noteForm.dirty || this.initialMedicationsChanged || this.initialProblemsChanged) && this.isAutoSaveEnabled) {
      if (this.selectedStatusType == undefined || this.selectedStatusType.typeName != "Finalized") {
        this.statusTypes.forEach(st => {
          if (st.typeName == "Pending") {
            this.selectedStatusType = st;
          }
        });
      }
      var en = JSON.stringify(this.noteForm.value);
      var encounterData = JSON.parse(en);

      encounterData.statusTypeId = this.selectedStatusType.typeMasterId;
      encounterData.visitTypeId = encounterData.visitTypeId ? encounterData.visitTypeId.typeMasterId : null;
      encounterData.encounterTypeId = encounterData.encounterTypeId ? encounterData.encounterTypeId.typeMasterId : null;
      encounterData.encounterNoteMedications = this.medications;

      this.autoSaveJson.json = JSON.stringify(encounterData);
      if (this.encounterNoteId != 0)
        this.autoSaveJson.referenceId = this.encounterNoteId

      if (this.autoSaveJson.genericJsonId > 0) {
        this.dashboardService.updateAutoSaveEncounterNotes(this.autoSaveJson).subscribe((res: any) => {

        })
      }
      else {
        this.dashboardService.autoSaveEncounterNotes(this.autoSaveJson).subscribe((res: any) => {
          this.autoSaveJson.genericJsonId = res.data.genericJSON.genericJsonId;
          clearInterval(this.intervel);
          this.intervel = setInterval(() => { this.encounterNotesAutoSave() }, this.autoSaveIntervel * 1000);
        })
      }
    }
  }

  encounterNoteDraftHandler(action: boolean) {
    if (action) {
      this.encounterNoteObjectBinding(this.draftedEncounterNoteObj);
    }
    else {
      this.encounterNoteObjectBinding(this.savedEncounterNoteObj);
    }
    this.dashboardService.deleteAutoSaveEncounterNote(this.savedEncounterNoteObj.genericJSON
      .genericJsonId
    )
      .subscribe((res: any) => {

      })
  }

  deleteGenericJson(genericId: number) {
    this.dashboardService.deleteAutoSaveEncounterNote(genericId
    )
      .subscribe((res: any) => {
      })
  }


}