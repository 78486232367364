<!-- 'visitDate', 'provider', 'visitType', 'status', 'billingCode', 'claimSubmitted', 'statusOfClaim', 'icon' -->

<div *ngIf="!displayAddNotes">
    <ng-container>

        <!-- <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
        </mat-form-field>-->
        <!-- <span >
            <img style="vertical-align: top!important;" matTooltip="Add File" class="upload-icon  pointer "
                src="../../../../assets/images/add-file.svg">
        </span> -->
        <div style="height:5rem;">
            <span style="vertical-align: top!important;">
                <img style="vertical-align: top!important;" matTooltip="Add Note" class="upload-icon  pointer "
                    src="../../../../assets/images/add-file.svg" (click)="CreateEncounterNotes()"
                    *ngIf="userRoles.includes('Provider') || userRoles.includes('Staff')">
            </span>
        </div>


        <mat-table matSort [dataSource]="dataSource">

            <ng-container matColumnDef="visitDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Visit Date</mat-header-cell>
                <mat-cell *matCellDef="let EncounterNotesElement">
                    <div class="wrap-text" matTooltip={{EncounterNotesElement.visitDate|date}}>
                        {{EncounterNotesElement.visitDate | date: 'MM/dd/yyyy'}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="encounterType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Encounter Type</mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <div class="wrap-text" matTooltip={{encounterNotesElement.encounterType}}>
                        {{encounterNotesElement.encounterType}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="providerName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Provider</mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <div class="wrap-text" matTooltip={{encounterNotesElement.providerName}}>
                        {{encounterNotesElement.providerName}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="statusType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status
                </mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <div class="wrap-text" matTooltip={{encounterNotesElement.statusType}}>
                        {{encounterNotesElement.statusType}}
                    </div>
                </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="billingCode">
                <mat-header-cell *matHeaderCellDef>Billing Code</mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <div class="wrap-text" matTooltip={{encounterNotesElement.billingCode}}>
                        {{encounterNotesElement.billingCode}}
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="claimSubmitted">
                <mat-header-cell *matHeaderCellDef>Claim Submitted</mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <div class="wrap-text" matTooltip={{encounterNotesElement.claimSubmitted}}>
                        {{encounterNotesElement.claimSubmitted}}
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="statusOfClaim">
                <mat-header-cell *matHeaderCellDef>Status Of Claim</mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <div class="wrap-text" matTooltip={{encounterNotesElement.statusOfClaim}}>
                        {{encounterNotesElement.statusOfClaim}}
                    </div>
                </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="jsonIcon">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <span>
                        <a
                            *ngIf="(userRoles.includes('Provider') || userRoles.includes('Staff'))&& (encounterNotesElement.genericJsonId !=0)">
                            <img (click)="loadUnsavedDrafts(encounterNotesElement)" class="pointer pdf-icon"
                                matTooltip="Edit Unsaved Changes" src="../../../../assets/images/draftIcon.svg">
                        </a>
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="editIcon">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <span>
                        <a
                            *ngIf="(userRoles.includes('Provider') || userRoles.includes('Staff'))&&encounterNotesElement.encounterNoteId && (encounterNotesElement.statusType !='Health Record Review')">
                            <img (click)="editEncounterNote(encounterNotesElement)" class="pointer" matTooltip="Edit"
                                src="../../../../assets/images/edit.svg">
                        </a>
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="pdfIcon">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let encounterNotesElement">
                    <span>
                        <a
                            *ngIf="(userRoles.includes('Provider') || userRoles.includes('Staff'))&& (encounterNotesElement.statusType=='Finalized')">
                            <img (click)="getEncounterNotePdf(encounterNotesElement.encounterNoteId)"
                                class="pointer pdf-icon" matTooltip="Download PDF"
                                src="../../../../assets/images/pdf-svgrepo-com.svg">

                        </a>
                    </span>
                </mat-cell>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="999" style="vertical-align: middle; border-bottom-width: 0px;">No data to
                    display
                </td>
            </tr>

            <mat-header-row *matHeaderRowDef="tableColumns; sticky: true;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>

        </mat-table>
    </ng-container>
</div>

<div [hidden]="displayAddNotes">
    <mat-paginator [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]" aria-label="Select page"
        (page)="trackIndex($event)">
    </mat-paginator>
</div>

<div *ngIf="displayAddNotes">
    <lib-add-encounter-notes [userRoles]="userRoles" [encounterNoteId]="selectedEnconterNoteId"
        (backToList)="CreateEncounterNotes()" [openUnsavedDraft]="openUnsavedDraft"
        [unsavedEncounterNote]="selectedUnsavedJson">
    </lib-add-encounter-notes>
</div>

<modal #confirmPopup [isHideTitle]=false [title]="'Confirm'" [width]="650">
    <div>
        <div class="p-h" style="padding: 2rem 1rem 1rem 1rem;">
            <span class="confirm-text" style="font-size: 1rem !important;">You currently have unsaved changes...</span>
        </div>
        <div class="flex-container-input p-b" style="display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 1rem;">
            <div>
                <div>
                    <button class="btn-sm  btn-confirm" style="background-color: #D64F15 !important;
                    border: 1px solid #D64F15 !important;
                    box-sizing: border-box !important;
                    border-radius: 5px !important;
                    width: 120px !important;
                    height: 30px !important;
                    color: #ffffff !important;
                    cursor: pointer !important;" (click)="confirmPopup.close()">Cancel</button>
                </div>

            </div>
            <div class="flex-container-end" style="display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;">

                <div>
                    <button class="btn-sm  btn-confirm  btn-m" style="background-color: #D64F15 !important;
                    border: 1px solid #D64F15 !important;
                    box-sizing: border-box !important;
                    border-radius: 5px !important;
                    width: 210px !important;
                    height: 30px !important;
                    color: #ffffff !important;
                    cursor: pointer !important;" (click)="encounterNoteDraftHandler(true)">Open with Unsaved
                        Changes</button>
                </div>
                <div>
                    <button class="btn-sm  btn-confirm  btn-m" style="background-color: #D64F15 !important;
                    border: 1px solid #D64F15 !important;
                    box-sizing: border-box !important;
                    border-radius: 5px !important;
                    width: 210px !important;
                    height: 30px !important;
                    color: #ffffff !important;
                    cursor: pointer !important;
                    margin-left: 15px !important;" (click)="encounterNoteDraftHandler(false)">Open Last Saved
                        Version</button>

                </div>
            </div>
        </div>
    </div>
</modal>