import { ApplicationRef, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PatientDashboardService } from '../../services/patient-dashboard.service';
import * as FileSaver from 'file-saver';
import { HttpErrorResponse } from '@angular/common/http';

import { viewClassName } from '@angular/compiler';
import { SharedService } from '../../services/sharedservice.service';
import { LocalDataService } from '../../services/local-data.service';
import { NotifierService } from 'angular-notifier';
import { LoadingService } from '../../services/loading.service';
import { Constants } from '../../shared/constants';

export interface FileUpload {
  created: Date;
  fileName: string;
  displayName: string;
  description: string;
  tags: string;
  download: string;
  isAvailableToPatient: boolean;
  tagString: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})

export class FileUploadComponent implements OnInit {
  loading$ = this.loader.loading$;
  public popupHeading: string = '';
  public patientFileId: any;
  public patientFile: any;
  public loadingComplete: boolean = false;
  public rawData: any;
  public currentPage: number = 0;
  public itemsPerPage: number = 10;
  public providerView: boolean = false;
  public isFileUpload: boolean = false;
  public downloading: boolean = false;
  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild('flyout', { static: false }) flyout: any;
  @ViewChild('fileUploadPopup', { static: false }) fileUploadPopup: any;

  tableColumns: string[] = ['date', 'displayName', 'description', 'tags', 'icon'];
  dataSource: MatTableDataSource<FileUpload> = new MatTableDataSource<FileUpload>([]);
  practiceId: number = 0;

  constructor(
    private pdService: PatientDashboardService,
    private appRef: ApplicationRef,
    private dialog: MatDialog,
    private localService: LocalDataService,
    private notifier: NotifierService,
    private sharedService: SharedService,
    private loader: LoadingService
  ) { }

  ngOnInit(): void {
    this.localService.dashboardSummary.subscribe(response => {
      this.providerView = response.providerView
    });
    if (this.providerView)
      this.isFileUpload = true;
    this.fileListing();


  }

  fileListing(): void {
    this.practiceId = Number(sessionStorage.getItem(Constants.SESSION_PRACTICE_ID));

    this.pdService.getfileList(this.providerView, this.practiceId).subscribe((response: any) => {
      if (response) {
        var data = response.data.patientFiles;
        data.forEach((element: any) => {
          let tags = element.tags;
          var tagArray = tags.map((t: { name: any; }) => t.name)
          element.tagString = tagArray.toString();
        });
        this.dataSource = new MatTableDataSource<FileUpload>(data);
        this.dataSource.filterPredicate = (data: FileUpload, filter: string) => {
          return data.displayName?.toLowerCase().includes(filter) || data.description?.toLowerCase().includes(filter) || data.tagString?.toLowerCase().includes(filter);
        };
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  trackIndex(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
  }

  addFile() {
    this.popupHeading = 'Add a File ';
    this.flyout.open();
    this.fileUploadPopup.getTagList();
  }

  closeflyout(): void {
    this.fileUploadPopup.clearForm();
    this.flyout.close();
  }

  listrefresh(): void {
    this.fileListing();
    this.fileUploadPopup.getTagList();
  }

  fileDownload(list: any) {
    list.downloading = true;
    this.patientFileId = list.patientFileId;
    this.pdService.fileDownload(this.patientFileId).subscribe((response: any) => {
      const binaryString = window.atob(response.data.fileBytes);
      const bytes = new Uint8Array(binaryString.length);
      var af = bytes.map((byte, i) => binaryString.charCodeAt(i));
      const file = new Blob([af], { type: response.data.fileType });
      list.downloading = false;
      FileSaver.saveAs(file, response.data.fileName);
    }), 
    (error: any) => console.log('Error downloading the file')
  }

  editFileUpload(list: any) {
    this.patientFileId = list.patientFileId;
    this.popupHeading = 'Edit a File ';
    this.flyout.open(this.patientFileId);
    this.fileUploadPopup.getTagList();
    this.fileUploadPopup.getFileDetails(Object.assign({},list));
  }
  
  deleteFileUpload(list: any) {
    this.patientFileId = list.patientFileId;
    this.pdService.fileDelete(this.patientFileId).subscribe((response: any) => {
      if (response) {
        if (response.success) {
          this.listrefresh();
          this.notifier.notify('success', 'Deleted successfully!');
          this.sharedService.fileCountChange.next(true);
        }
      }
    },
      (err: HttpErrorResponse) => {
        err.error.message
        this.notifier.notify('warning', err.error.message);
      });
  }
}
