<div class="container">
    <div class="sub-list-item" [ngClass]="{'select-item':selectedMenuItem=='summary'}">
        <a (click)="loadSummaryCCDA()">
            <div class="flex-container-inbetween" style="padding: 12px 0px;">
                <div>
                    <span class="text-name">Summary C-CDA</span>
                </div>
            </div>
        </a>
    </div>
    <hr>
    <hr>
    <div *ngFor="let doc of documentList">
        <a (click)="loadXmlFile(doc.url)">
            <div class="sub-list-item" [ngClass]="{'select-item':selectedMenuItem == doc.url}">
                <div class="flex-container-inbetween">
                    <div>
                        <span class="text-name">{{doc.description }}</span>
                    </div>
                    <div>
                        <span class="text-date">{{doc.date | date}}</span>
                    </div>
                </div>
                <div>
                    <span class="text-type">{{doc.type}}</span>
                </div>
            </div>
        </a>
        <hr>
    </div>
</div>