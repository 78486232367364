<div class="container">

  <form (ngSubmit)="onSubmit(newHieRequest)" id="hie-request">
    <div>
      <label for="cell">Cell Phone</label>
      <br />
      <input name="cell" [(ngModel)]="newHieRequest.cell" class="form-control" id="cell" tabindex="1" mask="000-000-0000" />
    </div>

    <div>
      <label for="cellConfirm">Confirm Cell Phone</label>
      <br />
      <input name="cell" [(ngModel)]="newHieRequest.cellConfirm" class="form-control" id="cellConfirm" 
        tabindex="2" mask="000-000-0000" (focusout)="cellConfirmCompare()" />
    </div>

    <div *ngIf="!cellConfirmMatches" class="fullGridWidth validation-error">
      <span>Cell Phone Number does not match</span>
    </div>
    
    <div>
      <div style="display: inline-block; width: 75%;">
        <label for="firstName" class="required">First Name</label>
        <br />
        <input name="firstName" [(ngModel)]="newHieRequest.firstName" #firstName="ngModel"
          class="form-control" id="firstName" tabindex="5" required />
      </div>
      <div style="display: inline-block;">
        <label for="middleInitial">M.I.</label>
        <br />
        <input name="middleInitial" [(ngModel)]="newHieRequest.middleInitial" 
          class="form-control" id="middleInitial" style="width: 10px;" tabindex="6" />
      </div>
    </div>

    <div>
      <label for="lastName" class="required">Last Name</label>
      <br />
      <input name="lastName" [(ngModel)]="newHieRequest.lastName" 
        class="form-control" id="lastName" tabindex="7" required />
    </div>

    <div>
      <label for="dob" class="required">
        DOB
        <span style="font-weight: 300; font-size: 10px">(mm/dd/yyyy)</span>
      </label>
      <br />
      <input name="dob" type="date" [max]="today" [(ngModel)]="newHieRequest.dob" 
        class="form-control" id="dob" tabindex="8" required />
    </div>

    <div>
      <label for="gender" class="required">Gender</label>
      <br />
      <select name="gender" [(ngModel)]="newHieRequest.gender" class="form-control"
        tabindex="9" required style="height: 34px !important;">
        <option value="" default disabled hidden>Select</option>
        <option value="f">Female</option>
        <option value="m">Male</option>
        <option value="o">Other</option>
      </select>
    </div>

    <div class="addressContainer" [ngClass]="{'previous-address' : !first}"
      *ngFor="let address of newHieRequest.addresses; let i = index; let last = last; let first = first">
      
      <div class="address-header fullGridWidth" *ngIf="!first" (click)="removeAdditionalAddress(i)">
        <span>Previous Address {{i}}</span>
        <img src="../../../../assets/images/close.svg" />
      </div>

      <div>
        <label for="street" class="required">{{first ? 'Primary' : ''}} Address</label>
        <br />
        <input name="street_{{i}}" [(ngModel)]="address.street" 
          class="form-control" id="street" [attr.tabindex]="10 * (i+1)" required />
      </div>

      <div>
        <label for="extension">Suite / Apt</label>
        <br />
        <input name="extension_{{i}}" [(ngModel)]="address.extension" 
          class="form-control" id="extension" [attr.tabindex]="11 * (i+1)" />
      </div>

      <div>
        <label for="city" class="required">City</label>
        <br />
        <input name="city_{{i}}" [(ngModel)]="address.city" 
          class="form-control" id="city" [attr.tabindex]="12 * (i+1)" required />
      </div>

      <div>
        <div style="display: inline-block; width: 47%; margin-right: 10px;">
          <label for="state_{{i}}" class="required">State</label>
          <br />
          <select name="state_{{i}}" id="state" [(ngModel)]="address.state" class="form-control " #state="ngModel"
              [attr.tabindex]="13 * (i+1)" style="height: 34px !important; width: 105px;">
              <option value="" default disabled hidden>Select</option>
              <option *ngFor="let obj of stateList" [value]="obj.abbreviation">{{obj.name}}</option>
          </select>
        </div>
        <div style="display: inline-block; width: 39%;">
          <label for="zip_{{i}}" class="required">Zip</label>
          <br />
          <input name="zip_{{i}}" [(ngModel)]="address.zip" 
            class="form-control" id="zip" [attr.tabindex]="14 * (i+1)" maxlength="5" minlength="5" required />
        </div>
      </div>
      
      <div *ngIf="first">
        <label for="homePhone">Home Phone</label>
        <br />
        <input name="homePhone_{{i}}" [(ngModel)]="address.homePhone" mask="000-000-0000"
          class="form-control" id="homePhone" [attr.tabindex]="15 * (i+1)" />
      </div>

      <div *ngIf="!first" style="display: inline-block; margin-top: 20px; user-select: none;">
        <span>
          <input type="radio" id="primary-addr_{{i}}" name="primary-addr" [value]="true"
            style="width: 35px;" [(ngModel)]="address.isPrimary">
          <label for="primary-addr_{{i}}" style="vertical-align: super;">Make Primary Address</label>
        </span>
      </div>
    </div>

    <div class="previousAddressAction fullGridWidth" (click)="addPreviousAddress()" (keyup.enter)="addPreviousAddress()">
      <h4>
        Why add additional addresses?
      </h4>
      <div class="add-address-btn">
        <img src="../../../../assets/images/add-address.svg" />
        <span>Add Additional Address</span>
      </div>
      <p>
        Medical Record searches are based on a geographic area based on the patients address. If the patient has
        previously moved from a different area, we want to capture their medical records from the providers in
        those areas as well to provide the most complete medical history as possible.
      </p>
    </div>

    <div class="fullGridWidth">
      <button class="btn-cancel" style="margin-right: 20px;" type="button" (click)="closeFlyout()">Cancel</button>
      <button class="btn-save" type="submit" (click)="scroll(target)" [disabled]="!loadingComplete">{{buttonText}}</button>
      <div style="display:inline-block; padding-left: 20px; vertical-align: middle;">
        <mat-spinner style="display:inline-block;" *ngIf="!loadingComplete" [diameter]="25"></mat-spinner>
      </div>
    </div>
  
    <div #target style="margin-top: 12px">
      <div class="alert msg-alert alert-danger" *ngIf="showErrorMessage">
        <div [innerHTML]="errorMessage"></div>
      </div>
      <div id="alertMessage"></div>
    </div>

  </form>

</div>
