import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataService } from '../../services/local-data.service';
import { PatientDashboardService } from '../../services/patient-dashboard.service';
import { Constants } from '../constants';
//@ts-ignore
import * as HumanConnect from 'humanapi-connect-client';
import { DashboardSummary } from '../../models/dashboard-summary';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'lib-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loading$ = this.loader.loading$;
  fileCount: any;

  constructor(
    private pdService: PatientDashboardService,
    private router: Router,
    private zone: NgZone,
    private localService: LocalDataService,
    private route: ActivatedRoute,
    private loader: LoadingService
  ) {
  }

  providerView: boolean = false;
  patientFirst = '';
  patientLast = '';
  patientDob = '';
  patientGender = '';
  showAuthenticationError: boolean = false;
  loadingComplete: boolean = false;
  showDashboard: boolean = false;
  hasActiveHeadsUp: boolean = false;
  token: string = '';
  hapiConnectionCount: number = 0;
  hidecontent: boolean = false;
  hapiDataToken: string = '';
  initialRoute: string = 'file-upload';
  hasHealthGorillaEnabled: boolean = false;
  hasHealthGorillaConnection: boolean = false;
  hasHumanApiEnabled: boolean = false;

  ngOnInit() {
    this.localService.dashboardSummary.subscribe((response: DashboardSummary) => {
      this.hasActiveHeadsUp = response.hasActiveHeadsUp;
      this.providerView = response.providerView;
      this.token = response.hapiDataToken;
      this.hapiConnectionCount = response.connectionCount;
      this.patientFirst = response.patientFirst;
      this.patientLast = response.patientLast;
      this.patientDob = response.patientDob;
      this.patientGender = response.patientGender;
      this.initialRoute = response.initialRoute;
      this.hasHealthGorillaEnabled = response.hasHealthGorillaEnabled;
      this.hasHealthGorillaConnection = response.hasHealthGorillaConnection;
      this.hasHumanApiEnabled = response.hasHumanApiEnabled;
    });

    this.router.navigate(['/dashboard', {outlets: { content: ['patient-profile']}}]);
  }
}
