<himn-patient-dashboard *ngIf="userId && authToken"
    [provider-view]="providerView"
    [auth-token]="authToken" 
    [user-id]="userId"
    [access-id]="accessId"
    [requestor-id]="requestorId"
    [initial-route]="initialRoute"
    [practice-id]="practiceId">
</himn-patient-dashboard>

<lib-logout [provider-view]="providerView" *ngIf="showError"></lib-logout>

<!--
    [provider-view] (optional): Will show the top info bar containing the patient's name, gender, dob. 
        if none provided, defaults to false
    
    [auth-token]: a valid JWT
    
    [user-id]: HIMN's public user id (guid) of the user being requested
    
    [access-id]: HIMN's guid to track the JWT
    
    [requestor-id]: HIMN's public user id (guid) of the user requesting the information
    
    [initial-route]: when none is provided, defaults to the encounters page
    
    [practice-id] (optional): used when viewing the dashboard in provider view. ignore if not provider view. 
        loads the dashboard in the context of the specified practice
-->
