import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from 'projects/components/src/lib/services/loading.service';
import { PatientDashboardService } from 'projects/components/src/lib/services/patient-dashboard.service';
import { SharedService } from 'projects/components/src/lib/services/sharedservice.service';

@Component({
  selector: 'lib-doc-slide-menu',
  templateUrl: './doc-slide-menu.component.html',
  styleUrls: ['./doc-slide-menu.component.scss']
})
export class DocSlideMenuComponent implements OnInit, AfterViewInit {
  @Output() loadXmlContent = new EventEmitter();
  @Input() documentList: any[] = [];
  public selectedMenuItem: string = 'summary';
  isFlyoutVisible = false;
  loading$ = this.loader.loading$;
  constructor(public sharedService: SharedService, private pdService: PatientDashboardService
    , private loader: LoadingService) { }
  ngAfterViewInit(): void {
    //this.getDocuments();
  }

  ngOnInit(): void {
  }

  public loadXmlFile(url: string) {
    this.selectedMenuItem = url;
    this.loadXmlContent.emit(url);
  }

  public loadSummaryCCDA() {
    this.selectedMenuItem = 'summary';
    this.loadXmlContent.emit('summary');
  }

}
