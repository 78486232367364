<!-- <himn-spinner *ngIf="loading$ | async"></himn-spinner> -->

<!-- <ng-container *ngIf="!(loading$ | async)"> -->
<div class="textarea" style="margin-right: .25rem;">
    <div class="flex-container header-area">
        <div style="margin: 1rem !important;">
            <span>
                <span class="form-header">Documents</span>
            </span>
        </div>
    </div>
    <div class="sub-container">
        <div style="padding: 1rem !important; overflow: auto;height: 76vh !important">
            <lib-documents></lib-documents>
        </div>
    </div>

</div>
<!-- </ng-container> -->