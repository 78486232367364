<div class="notification-container">
    <div style="margin: 0rem 1rem;">
        <span class="med-headder">
            The following list of medications were identified in the patients past medical history.
        </span>
        <br>
        <span class="med-headder">
            Choose the medications below that you would like to include in this Encounter Note.
        </span>
        <br>
        <span class="med-note">
            Note: Selecting medications does not affect the prior saved medical records. 
        </span>
    </div>
    <div class="table-container">
        <table  *ngIf="medicationList.length>0">
            <tr *ngFor="let med of medicationList">
                <td><input type="checkbox" [id]="med.name" [name]="med.name" [(ngModel)]="med.isSelected"></td>
                <td>
                    <div style="margin: .25rem;">
                        <label [for]="med.name"> {{med.name}}</label><br>
                    </div>
                </td>
                <td style="width: 6rem;">{{med.startDate | date:'MM-dd-yyyy'}}</td>
                <td>{{med.instructions}}</td>
            </tr>
        </table>
        <!-- <div *ngIf="!hasHealthGorillaEnabled && !hasHealthGorillaConnection && medicationList.length == 0">
            Health module is not enabled for this patient.
        </div> -->
        <div *ngIf="medicationList.length == 0" style="vertical-align: middle;">
            {{listResultLabel}}
        </div>
    </div>
    
    <div style="text-align: end;">
        <span><button class="btn-sm  btn-cancel btn-m" type="submit"
                (click)="closeMedicinePopup()">Cancel</button></span>
        <span><button class="btn-sm  btn-save " style="margin-right: 15px;" type="submit" (click)="includeMedicineList()">Include</button></span>
    </div>
</div>
