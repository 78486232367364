<himn-spinner [hidden]="!(loading$ | async)"></himn-spinner>

<div class="lab-container" [hidden]="(loading$ | async)">
  <section *ngFor="let lab of labResults;">
    <h2>{{lab.name}}</h2>
    <div class="lab-collection">Collected: <span>{{lab.collected | date}}</span></div>
    <ng-container *ngFor="let result of lab.results; odd as isOdd; even as isEven;">
      <section class="lab-result" [ngClass]="{ 'odd': isOdd, 'even': isEven }">
        <div class="lab-value">
          <span>{{result.description}}
            <span *ngIf="result.referenceRange && result.unit">({{result.referenceRange}} {{result.unit}})</span>: {{result.value}}</span>
        </div>
        <span *ngIf="result.interpretation" style="color: red;">
          &nbsp;{{result.interpretation}}
        </span>
        <div *ngFor="let note of result.notes">
          <span [innerText]="note"></span>
          <br />
        </div>
      </section>
    </ng-container>
  </section>

  <lib-slide-out #hgSlideOut>
    <lib-labs-slide-menu [labsList]="labsList" (loadLabContent)="loadLabContent($event)">
    </lib-labs-slide-menu>
  </lib-slide-out>
</div>