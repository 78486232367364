import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { NotifierService } from 'angular-notifier';
import { LoadingService } from '../../../services/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-hie-records',
  templateUrl: './hie-records.component.html',
  styleUrls: ['./hie-records.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class HieRecordsComponent implements OnInit {

  @ViewChild('flyout', { static: false }) flyout: any;
  @ViewChild('hieRequestPopup', { static: false }) hieRequestPopup: any;

  loading$ = this.loader.loading$;

  constructor(
    private localService: LocalDataService,
    private pdService: PatientDashboardService,
    private notifier: NotifierService,
    private loader: LoadingService
  ) { }

  isHealthGorillaSponsor: boolean = false;
  patientFirst: string = '';
  patientLast: string = '';
  popupHeading: string = '';
  hasHealthRecordQueryExpired: boolean = true;
  hasHealthGorillaConnection: boolean = false;
  public encounterTypes: any[] = [];
  public modalityTypes: any[] = [];
  public providerList: any[] = [];
  public selectedProvider: any = null;
  public selectedModalityType: any = null;
  public selectedEncounterType: any = null;
  public selectedStatusType: any;
  public containerWidth: any;

  ngOnInit(): void {
    this.localService.dashboardSummary.subscribe(response => {
      this.isHealthGorillaSponsor = true,
      this.patientFirst = response.patientFirst;
      this.patientLast = response.patientLast;
      this.hasHealthRecordQueryExpired = response.hasHealthRecordQueryExpired;
      this.hasHealthGorillaConnection = response.hasHealthGorillaConnection;
    });

    this.pdService.getEncounterTypes().subscribe((result: any) => {
      this.encounterTypes = result.data.encounterTypes;
      this.modalityTypes = result.data.modalityTypes;
      this.providerList = result.data.providerList;
      var status: any[] = result.data.statusTypes;
      status.forEach(st => {
        if (st.typeName == "Health Record Review") {
          this.selectedStatusType = st;
        }
      });
    });
  }

  requestHieRecords() {
    this.popupHeading = 'Request Records for Patient';
    this.flyout.open();
  } 

  closeflyout(): void {
    this.flyout.close();
  }

  listrefresh(): void {
  }

  saveEncounterQuery() {
    var encounterData: any = {};
    debugger
    if (this.selectedProvider && this.selectedEncounterType && this.selectedModalityType && this.selectedStatusType) {
      encounterData.statusTypeId = this.selectedStatusType.typeMasterId;
      encounterData.visitTypeId = this.selectedModalityType;
      encounterData.encounterTypeId = this.selectedEncounterType;
      encounterData.providerUserId = this.selectedProvider;
      this.pdService.saveEncounterNote(encounterData).subscribe((res: any) => {
        this.hasHealthRecordQueryExpired = false;
        location.reload();
      })
    }
    else {
      this.notifier.notify('error', 'The fields cannot be empty')
    }
  }
}
