import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-diagnoses-dialog',
  templateUrl: './diagnoses-dialog.component.html',
  styleUrls: ['./diagnoses-dialog.component.scss']
})
export class DiagnosesDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  tableColumns: string[] = ['name'];
  dataSource: any;

  ngOnInit() {
    this.dataSource = this.data;
  }
} 