<h1 mat-dialog-title>Similar Patient Matches</h1>
<!-- TODO: show if multple results -->
<section class="dialog-body">
  <ng-container *ngIf="dataSource">
    <!-- table goes here -->
    <mat-table matSort [dataSource]="dataSource">

      <ng-container matColumnDef="toInclude">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <mat-checkbox [(ngModel)]="dataSource.data[i].IsChecked" (change)="handleChange(dataSource.data[i])"></mat-checkbox>
        </mat-cell>            
      </ng-container> 

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let patient">{{patient.name}}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="gender">
        <mat-header-cell *matHeaderCellDef>Gender</mat-header-cell>
        <mat-cell *matCellDef="let patient">{{patient.gender}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="dob">
        <mat-header-cell *matHeaderCellDef>DOB</mat-header-cell>
        <mat-cell *matCellDef="let patient">{{patient.dob | date}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
        <mat-cell *matCellDef="let patient">{{patient.address}}</mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="tableColumns; sticky: true;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns;" (click)="selectedRow(row)" [class.active]="isSelected(row)"></mat-row>
  
    </mat-table>

    <div>
      <mat-paginator [pageSize]="5" aria-label="Select page">
      </mat-paginator>
    </div>
    <span>
      By selecting the rows above and clicking 'Include', you acknowledge that records
      found will be added to {{patientName}}'s chart.
    </span>
    <div style="margin-top: 10px;">
      <button class="cancel-btn" style="margin-right: 20px;" (click)="closeDialog()">Cancel</button>
      <button class="form-btn" type="submit" (click)="hgInclude()" [disabled]="!loadingComplete">Include</button>
      <div style="display:inline-block; padding-left: 20px; vertical-align: middle;">
        <mat-spinner style="display:inline-block;" *ngIf="!loadingComplete" [diameter]="25"></mat-spinner>
      </div>
    </div>
  </ng-container>
  <!-- TODO: show if no results -->
  <ng-container *ngIf="!dataSource">
    <p style="line-height: 1.8;">
      No Health Records were found for {{patientName}} based on the information provided.
      <br />
      Please double check correct spelling of name, address, and date of birth.
      <br />
      Adding any of the patient's previous addresses will greatly increase the chances of finding a patient match.
    </p>
    <div style="margin-top: auto;">
      <button class="cancel-btn" (click)="closeDialog()">OK</button>
    </div>
  </ng-container>
</section>
