import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'lib-hg-documents',
  templateUrl: './hg-documents.component.html',
  styleUrls: ['./hg-documents.component.scss']
})
export class HgDocumentsComponent implements OnInit {
  loading$ = this.loader.loading$;
  constructor(private loader: LoadingService) { }

  ngOnInit(): void {
  }

}
