import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Constants } from 'projects/components/src/lib/shared/constants';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private http: HttpClient) {}
  
  authToken: string = '';
  userId: string | null = '';
  requestorId: string | null = null;
  accessId: string | null = '';
  providerView: boolean = false;
  initialRoute: string = '';
  showError: boolean = false;
  errorMessage: string = '';
  practiceId: string = '';
  
  ngOnInit() {
    var queryString = window.document.location.search;
    let params = new URLSearchParams(queryString);
    this.accessId = params.get('accessId');
    this.userId = params.get('userId');
    this.requestorId = params.get('requestorId');
    this.providerView = params.get('providerView') === 'true' ? true : false;
    this.practiceId = this.providerView 
                        ? params.get('practiceId')! 
                        : '';

    let paramRoute = params.get('initialRoute');
    this.initialRoute = !paramRoute ? 'hg-encounters' : paramRoute;

    //angular CLI will automatically remove this unreachable 
    //code during the production builds
    //TODO: manually confirm this
    if (environment.production || (this.accessId && this.userId)) {
      this.getAccessToken();
    }
    else if (!environment.production && !this.accessId && !this.userId) { 
      this.loadTestData(); 
    }
  }

  getAccessToken() {
    this.http.get(`${environment.apiUrl}GetTokenWithAccessId?accessId=${this.accessId}`, {responseType: 'text'})
      .pipe(catchError((err: any) => {
        this.showError = true;
        return throwError(() => new Error(err));
      }))
      .subscribe((token: any) => {
        this.authToken = token;
    }),
    (err: HttpErrorResponse) => {
      console.log(err);
    };
  }

  loadTestData() {
    // we will get the ID and Secret from an external file that is not tracked by Git
    // we do not want to commit any secret to the repo
    // if you do not have this file, have another team member _securely_ e-mail it to you
    // do NOT commit that file
    this.requestorId = '1274c015-8d50-4b9a-704b-ad375b52bfe2'; // the user (usually provider) requesting the data 
    let clientId = environment.accesskeys.patientDashboard.clientId;
    let secret = environment.accesskeys.patientDashboard.secret;
    let getTokenUrl = `${environment.apiUrl}GetHimnAccessToken?potentialClientId=${clientId}&potentialClientSecret=${secret}&requestorId=${this.requestorId}`;
    
    this.http.get(getTokenUrl)
      .subscribe((data: any) => {
        this.accessId = data.accessId;
        
        // feel free to change this. default is the Public User ID of the Hapi Demo patient
        this.userId = 'e404032c-e4a2-74f0-5846-195ca936de6e';

        // feel free to toggle this based on your testing needs
        this.providerView = true; 

        // feel free to change. loads the dashboard in the context of the specified practice
        this.practiceId = '1';

        this.getAccessToken();
    }),
    (err: HttpErrorResponse) => {
      console.error(err);
    };
  }

  closeWindow() {
    window.close();
  }
}
