export class HieRequest {
  cell: string | undefined | null = null;
  cellConfirm: string | undefined | null = null;
  email: string | undefined | null = null;
  emailConfirm: string | undefined | null = null;
  firstName: string | undefined | null = null;
  middleInitial: string | undefined | null = null;
  lastName: string | undefined | null = null;
  dob: string | undefined | null = null;
  gender: string | undefined | null = null;
  addresses: HieRequestAddress[] = [];
}

export class HieRequestAddress {
  street: string | undefined | null = null;
  extension: string | undefined | null = null;
  city: string | undefined | null = null;
  state: string | undefined | null = null;
  zip: string | undefined | null = null;
  homePhone: string | undefined | null = null;
  isPrimary: boolean = false;
}
