import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService } from '../../../services/loading.service';
import { LocalDataService } from '../../../services/local-data.service';
import { PatientDashboardService } from '../../../services/patient-dashboard.service';
import { StringManipService } from '../../../services/string-manip.service';

@Component({
  selector: 'lib-hg-allergies',
  templateUrl: './hg-allergies.component.html',
  styleUrls: ['./hg-allergies.component.scss']
})
export class HgAllergiesComponent implements OnInit {
  loading$ = this.loader.loading$;

  constructor(
    private pdService: PatientDashboardService,
    private stringUtil: StringManipService,
    private loader: LoadingService
  ) { }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'description':
            return this.stringUtil.trimAndLower(item.description?.toString());
          case 'severity':
            return this.stringUtil.trimAndLower(item.severity?.toString());
          case 'reaction':
            return this.stringUtil.trimAndLower(item.reaction?.toString());
          default:
            return '';
        }
      };
      this.dataSource.sort = sort;
    }

    this.dataSource.sort = sort;
  }

  tableColumns: string[] = ['description', 'reaction', 'severity'];
  dataSource: MatTableDataSource<Allergy> = new MatTableDataSource<Allergy>([]);
  tableMessage: string = 'No data to display';

  ngOnInit(): void {
    this.pdService.getHieAllergies().subscribe((data: any) => {
      if (data !== null) {
        // if there are No Known Allergies, display a special view
        if (data[0].description.toLowerCase() == 'nkda') {
          this.tableMessage = 'No known allergies';
          data = null;
        }

        this.dataSource = new MatTableDataSource<Allergy>(data);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  applyFilter(event: Event) {
    this.dataSource.filter = (event as unknown as String).trim().toLowerCase();
  }
}

export interface Allergy {
  description: string,
  severity: string,
  reaction: string
}
