import { Component, OnInit } from '@angular/core';
import { LocalDataService } from '../../services/local-data.service';

@Component({
  selector: 'lib-medical-records',
  templateUrl: './medical-records.component.html',
  styleUrls: ['./medical-records.component.scss']
})
export class MedicalRecordsComponent implements OnInit {

  constructor(
    private localService: LocalDataService
  ) { }

  showNoConnectionGuide: boolean = false;
  hapiConnectionCount: number = 0;

  ngOnInit(): void {
    this.localService.dashboardSummary.subscribe(response => {
      this.hapiConnectionCount = response.connectionCount;

      if (this.hapiConnectionCount < 1) this.showNoConnectionGuide = true;
    });
  }

  launchHapiConnect() {
    var hapiBtn = document.getElementById('hapi-connect-btn');
    if (hapiBtn == null) return console.error('Cannot locate Hapi Button');
    hapiBtn.click();
  }
}
