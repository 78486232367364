import { HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, finalize, Observable, throwError } from 'rxjs';
import { PatientDashboardModule } from '../../public-api';
import { AuthorizationInfo } from '../models/authorization-info';
import { NoInterceptorService } from '../services/ignore-interceptors.service';
import { LoadingService } from '../services/loading.service';
import { LocalDataService } from '../services/local-data.service';
import { Constants } from '../shared/constants';
import { ErrorInterceptor } from './error.interceptor';
 
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  totalRequests = 0;
  requestsCompleted = 0;
   
  constructor(
    private localService: LocalDataService,
    private noInterceptorService: NoInterceptorService,
    private router: Router,
    private loader: LoadingService
  ) {}

  authToken: string | null = null;
  userId: string | null = null;
  requestorId: string | null = null;
  accessId: string | null = null;
  practiceId: string | null = null;

  intercept(req: any, next: any): Observable<HttpEvent<unknown>> {
    if (!req.params.get('isHideLoader') && !req.url.includes('GetTokenWithAccessId')) {
      this.loader.show();
      this.totalRequests++;
    }
    
    this.localService.authInfo.subscribe((response: AuthorizationInfo) => {
      this.authToken = response.authToken;
      this.userId = response.userId;
      this.requestorId = response.requestorId;
      this.accessId = response.accessId;
      this.practiceId = response.practiceId;
    });

    if (!this.authToken || !this.userId || !this.accessId) {
      this.authToken = sessionStorage.getItem(Constants.SESSION_AUTH_TOKEN);
      this.userId = sessionStorage.getItem(Constants.SESSION_USER_ID);
      this.requestorId = sessionStorage.getItem(Constants.SESSION_REQUESTOR_ID);
      this.accessId = sessionStorage.getItem(Constants.SESSION_AUTH_ACCESS);
      this.practiceId = sessionStorage.getItem(Constants.SESSION_PRACTICE_ID);
    }

    const tokenizedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authToken}`,
        PublicUserId: !this.userId ? '' : this.userId,
        RequestorId: !this.requestorId ? '' : this.requestorId,
        AccessId: !this.accessId ? '' : this.accessId,
        PracticeId: !this.practiceId ? '' : this.practiceId
      }
    });

    return next.handle(tokenizedReq).pipe(
      finalize(() => {
        this.noInterceptorService.checkBlacklist().subscribe((isBlacklisted: any) => {
          if (isBlacklisted) {
            this.loader.hide();
            this.router.navigate(['/logout']);
          }

          if (!tokenizedReq.params.get('isHideLoader') && !req.url.includes('GetTokenWithAccessId')) {
            this.requestsCompleted++;
          }

          if (this.requestsCompleted === this.totalRequests) {
            this.loader.hide();
            this.totalRequests = 0; 
            this.requestsCompleted = 0;
          }
        }
      )})
    );
  }
}
