<main id="himnpd">
  <header *ngIf="providerView">
    <li><img class="header--pd-logo" src="../../../../assets/images/parawell-logo.png" /></li>
    <li class="header--patient-information_wrapper">
      <ul class="header--patient-information">
        <li>{{patientFirst | titlecase}} {{patientLast | titlecase}}</li>
        <li class="dob">
          <strong>DOB: </strong>
          {{patientDob | date:'MM/dd/yyyy'}} 
        </li>
        <li class="sex">
          <strong>Sex: </strong>
          {{patientGender | titlecase}}
        </li>
      </ul>
    </li>
    <li class="header--app-name"><h2>Patient Dashboard</h2></li>
  </header>
  <section class="dash-body">
    <pd-nav id="pd-nav" (mobileMenuIsExpanded)="hidecontent = $event"></pd-nav>
    <div class="dash-content" [ngClass]="{'hide-content': hidecontent}">
      <div class="outlet-wrapper">
        <router-outlet name="content"></router-outlet>
      </div>
      <footer style="margin-top: auto;">
        <span>Confidential Property HIMN Holdings LLC 2939 Kenny Road, Suite 201 Columbus, Ohio. 43221</span>
      </footer>    
    </div>
  </section>
</main>
